import { EnrolmentComponent } from "./EnrolmentComponent";
import { EnrolmentMotivation } from "./EnrolmentMotivation";
import { textTag } from "../TextTag";
export class EnrolmentSoftSkills {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.template = (texts) => {
            return `
      <div class="ftcb__msg ftcb__msg--is-bot">
        <div class="bot-icon"></div>
        <span class="ftcb__msg__text">${textTag(texts, 'SoftSkills1')}</span>
      </div>
    `;
        };
        this.templateQuestion = (question, texts) => {
            return `
      <div class="ftcb__msg ftcb__msg--is-bot" >
        <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${question.question}
        </span>
      </div>
      <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" >      
        <div class="ftcb__msg--is-bot" >
          <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
              <div class="ftcb__msg__btn ftcb_enrolment_soft_skills_option">
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-circle">
                    <div class="ftcb__msg__btn-circle-child"></div>
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage" data-answer_type="a" data-payload="${question.a.payload}">${question.a.text}</div>
                </div>
              </div>   
              <div class="ftcb__msg__btn ftcb_enrolment_soft_skills_option" >
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-circle">
                    <div class="ftcb__msg__btn-circle-child"></div>
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage" data-answer_type="b" data-payload="${question.b.payload}">${question.b.text}</div>
                </div>
              </div>                   
            </div>
          </div>
        </div>
        <span class="ftcb__enrolment__edit">${textTag(texts, 'SoftSkills2')}</span>
        <div class="ftcb__msg--is-user">
          <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"  style="background-image:url('${this.enrolmentCtrl.user.avatar}');"></div>
          <span class="ftcb__msg__text"></span>
        </div>
      </div>
    `;
        };
        this.sendButtonTemplate = (texts) => {
            return `
      <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" id="endSoftSkills">
        <div class="ftcb__msg--is-bot">
          <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">              
              <div class="ftcb__msg__btn" >
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, 'SoftSkills3')}</div>
                </div>
              </div>               
            </div>
            <div class="ftcb__enrolment__warning"><span>${textTag(texts, 'SoftSkills4')}</span></div>  
            <div class="ftcb__enrolment__login-errror"><span id="softSkillsError">${textTag(texts, 'SoftSkillsError')}</span></div>  
          </div>
        </div>
      </div>
     
  `;
        };
        this.motivationSequence = [1, 3, 6, 10];
        this.motivationTextIndex = 1;
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentSoftSkills', 'ftcb__enrolment__screen');
        this.start = (callback) => {
            this.container.setContent(this.template(this.enrolmentCtrl.enrolmentTexts));
            this.container.inject(this.enrolmentCtrl.container.component);
            setTimeout(() => { questionSecuence(0); }, 2000);
            const questionSecuence = (index) => {
                const question = this.enrolmentCtrl.softSkillsQuestions[index];
                const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, question.identifier, 'ftcb__enrolment__f1Question');
                questionComponent.setContent(this.templateQuestion(question, this.enrolmentCtrl.enrolmentTexts));
                questionComponent.inject(this.container.component);
                let motivation;
                if (this.motivationSequence.indexOf(index + 1) >= 0) {
                    motivation = new EnrolmentMotivation(textTag(this.enrolmentCtrl.enrolmentTexts, `Motivation${this.motivationTextIndex}`), this.container.component, this.enrolmentCtrl);
                    setTimeout(() => { motivation.start(); }, 3500);
                    this.motivationTextIndex = this.motivationTextIndex + 1;
                }
                $(`#${question.identifier} .ftcb_enrolment_soft_skills_option`).click((event) => {
                    if (motivation) {
                        motivation.stop();
                    }
                    this.enrolmentCtrl.progress.advance();
                    const answer = $(event.currentTarget).find('.ftcb__msg__btn-titlenoimage');
                    const response = answer.data("payload");
                    const answerType = answer.data("answer_type");
                    this.enrolmentCtrl.user.softSkills.answers.push({
                        identifier: question.identifier,
                        answerType: answerType
                    });
                    $(`#${question.identifier} .ftcb__msg--has-btn`).addClass("ftcb__msg--answered");
                    $(`#${question.identifier} .ftcb__msg--is-user .ftcb__msg__text`).html(response);
                    this.enrolmentCtrl.scroll();
                    index = index + 1;
                    if (index < this.enrolmentCtrl.softSkillsQuestions.length) {
                        questionSecuence(index);
                    }
                    else {
                        this.container.component.insertAdjacentHTML('beforeend', this.sendButtonTemplate(this.enrolmentCtrl.enrolmentTexts));
                        $("#endSoftSkills .ftcb__msg__btn").click(() => {
                            $('#softSkillsError').hide();
                            const notAnsweredQ = this.enrolmentCtrl.user.softSkills.answers.find((element) => {
                                return element.answerType === null;
                            });
                            if (notAnsweredQ) {
                                $('#softSkillsError').show();
                                this.scrollToElement(notAnsweredQ.identifier);
                                return;
                            }
                            const skills = this.enrolmentCtrl.user.softSkills.answers.map((item) => item.answerType).join(',');
                            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_eleccion', 'softSkills:' + skills);
                            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_completado', 'Soft Skills');
                            $("#endSoftSkills").addClass("ftcb__msg--answered");
                            $("#enrolmentSoftSkills div").unbind();
                            $("#enrolmentSoftSkills .ftcb__enrolment__edit").fadeOut();
                            callback(null);
                        });
                    }
                });
                $(`#${question.identifier} .ftcb__enrolment__edit`).click(() => {
                    $(`#${question.identifier} .ftcb__msg--has-btn`).removeClass("ftcb__msg--answered");
                    $(`#${question.identifier} .ftcb__msg__btn`).removeClass('ftcb_enrolment_soft_skills_option');
                    $(`#${question.identifier} .ftcb__msg__btn`).unbind();
                    $(`#${question.identifier} *`).removeClass('ftcb__msg__btn-clicked');
                    $(`#${question.identifier} .ftcb__msg__btn`).addClass('ftcb__enrolment__btn__edited');
                    const skill = this.enrolmentCtrl.user.softSkills.answers.find((element) => {
                        return element.identifier === question.identifier;
                    });
                    skill.answerType = null;
                    $(`#${question.identifier} .ftcb__enrolment__btn__edited`).click((event) => {
                        const answer = $(event.currentTarget).find('.ftcb__msg__btn-titlenoimage');
                        const response = answer.data("payload");
                        const answerType = answer.data("answer_type");
                        skill.answerType = answerType;
                        $(`#${question.identifier} .ftcb__msg--has-btn`).addClass("ftcb__msg--answered");
                        $(`#${question.identifier} .ftcb__msg--is-user .ftcb__msg__text`).html(response);
                    });
                });
            };
        };
    }
    ;
    scrollToElement(targetId) {
        var $container = $(this.enrolmentCtrl.container.component), $scrollTo = $(`#${targetId}`);
        $container.scrollTop($scrollTo.offset().top - $container.offset().top + $container.scrollTop());
    }
}
