import { PopUp } from "../PopUpScreen";
import { ProfilePDForm } from "./ProfilePDForm";
import { ProfilePDConfig } from "./ProfilePDConfig";
import { StaticsControl } from "../staticsControl";
import { ProfileSwitch } from "./ProfileSwitch";
import { textTag } from "../TextTag";
export class ProfilePersonalData {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.icons = StaticsControl.statics;
        this.currentSwitchOption = "form";
        this.skillSitchOptions = [
            {
                option: 'form',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'Data1')
            },
            {
                option: 'config',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'Data2')
            }
        ];
        this.profileServices = this.chatCtrl.profileServices;
        this.profileServices.getUser((err, response) => {
            if (err)
                return this.chatCtrl.errorModal();
            this.userInfo = response;
            this.profilePDForm = new ProfilePDForm(this);
            this.ProfilePDConfig = new ProfilePDConfig(this);
            this.profileSwitch = new ProfileSwitch(this, 'profileSkillsSwitch', this.skillSitchOptions, null);
            this.template = `
        <div class="ftcb__profile__popUp__header">
          <div class="ftcb__profile__popUp__header-image">  
            <img src="${this.icons.profileOptionIcon1}">
          </div>
          <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Data')}</div>
        </div>
        <div class="ftcb__profile__switch-container">
          ${this.profileSwitch.template}
        </div>
        <div class="ftcb_profile-switchPage_form ftcb_profile-switchPage">
          ${this.profilePDForm.template}
        </div>
        <div class="ftcb_profile-switchPage_config ftcb_profile-switchPage">
          ${this.ProfilePDConfig.template}
        </div>
      `;
            this.popUp = new PopUp('profilePersonalData', this.template);
            this.bind();
        });
    }
    bind() {
        this.profileSwitch.bind();
        this.profilePDForm.bind();
        this.ProfilePDConfig.bind();
    }
}
