import { textTag } from "../TextTag";
export class ProfilePDConfig {
    constructor(profilePersonalData) {
        this.profilePersonalData = profilePersonalData;
        this.template = `
    <div class="ftcb__profile__config">
      <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
        <div class="ftcb__msg__btn-content ftcb__profile__btn-title">
          <div class="ftcb__msg__btn-titlenoimage">${textTag(this.profilePersonalData.chatCtrl.dictionary.texts.profile, 'Data12')}</div>
        </div>
      </div>
    </div>
  `;
    }
    bind() {
        $('.ftcb__profile__config .ftcb__msg__btn-content').click(() => {
            this.profilePersonalData.chatCtrl.sendUnsuscribeUser();
            this.profilePersonalData.popUp.close();
        });
    }
}
