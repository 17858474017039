const Config = require('./../../config.js');
export class StaticsControl {
    static get statics() {
        const imagesUrlBase = HOST_URL + '/webchat/images/';
        const images = {
            orientadorLogo: imagesUrlBase + 'logo_orientador.png',
            submitIcon: imagesUrlBase + 'send-icon.png',
            closeIcon: imagesUrlBase + 'close-icon.png',
            openLinkIcon: imagesUrlBase + 'open-link-icon.png',
            optionsIcon: imagesUrlBase + 'options-icon.png',
            arrow: imagesUrlBase + 'arrow-icon.png',
            avatar1: imagesUrlBase + 'personaje-1.png',
            avatar2: imagesUrlBase + 'personaje-2.png',
            avatar3: imagesUrlBase + 'personaje-3.png',
            avatar4: imagesUrlBase + 'personaje-4.png',
            enrolmentOption1: imagesUrlBase + 'icono-enroll-1.png',
            enrolmentOption2: imagesUrlBase + 'icono-enroll-2.png',
            avatarhead2x: imagesUrlBase + 'avatar-head@2x.png',
            keys2x: imagesUrlBase + 'keys@2x.png',
            logo2x: imagesUrlBase + 'logo@2x.png',
            ligth_green2x: imagesUrlBase + 'light_green@2x.png',
            telescope2x: imagesUrlBase + 'telescope@2x.png',
            profileIcon: imagesUrlBase + 'profile-icon.png',
            profileIconEn: imagesUrlBase + 'profile-icon.png',
            profileIconPlus: imagesUrlBase + 'plus.png',
            profileOptionIcon1: imagesUrlBase + 'profile_icon_1.png',
            profileOptionIcon2: imagesUrlBase + 'profile_icon_2.png',
            profileOptionIcon3: imagesUrlBase + 'profile_icon_3.png',
            nave: imagesUrlBase + 'nave.png',
            profileOptionIcon4: imagesUrlBase + 'profile_icon_4.png',
            profileOptionIcon5: imagesUrlBase + 'profile_icon_5.png',
            profileOptionIcon6: imagesUrlBase + 'profile_icon_6.png',
            profileSearchIcon: imagesUrlBase + 'search.png',
            avatarhead2xoutlook: imagesUrlBase + 'avatar-head@2xoutlook.png',
            logo2xoutlook: imagesUrlBase + 'logo@2xutlook.png',
            ligth_green2xoutlook: imagesUrlBase + 'light_green@2xoutlook.png',
            telescope2xoutlook: imagesUrlBase + 'telescope@2xoutlook.png',
            keys2xoutlook: imagesUrlBase + 'keys@2xoutlook.png'
        };
        return images;
    }
}
