import { textTag } from "./TextTag";
export class Rating {
    constructor(chatControl, ratingMsg) {
        this.chatControl = chatControl;
        this.ratingMsg = ratingMsg;
        this.template = `
  <div id="stars" class ="ftcb__msg ftcb__msg--has-btn ftcb__rating__user-bot">
    <div class="ftcb__msg--is-bot">
      <div class="ftcb__msg__text">
        <div id="rating">
          <div class="ftcb__rating" >
            <div class="ftcb__rating__star ftcb__rating__star--empty" id="rating5" data-rating="5" data-payload="5 estrellas"></div>
            <div class="ftcb__rating__star ftcb__rating__star--empty" id="rating4" data-rating="4" data-payload="4 estrellas"></div>
            <div class="ftcb__rating__star ftcb__rating__star--empty" id="rating3" data-rating="3" data-payload="3 estrellas"></div>
            <div class="ftcb__rating__star ftcb__rating__star--empty" id="rating2" data-rating="2" data-payload="2 estrellas"></div>
            <div class="ftcb__rating__star ftcb__rating__star--empty" id="rating1" data-rating="1" data-payload="1 estrella"></div>
          </div>
        </div>

      </div>
    </div>
    <div class="ftcb__msg--is-user">
      <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
      <div class="ftcb__msg__text"></div>
    </div>


  </div>

<div class="ftcb__msg ftcb__msg--has-btn container-rating" style="display: none;">
          <div class="ftcb__msg--is-bot">
            <div class="ftcb__msg__cards">
              <div class="ftcb__msg__buttoncontainer">
                
          <div class="ftcb__msg__btn">
            <div class="ftcb__msg__btn-content">
              <div id="confirm-rating" class="ftcb__msg__btn-titlenoimage">${textTag(this.chatControl.dictionary.texts.bot, 'ConfirmRating')}</div>
            </div>
          </div>
        
          <div class="ftcb__msg__btn">
            <div class="ftcb__msg__btn-content">
              <div id="cancel-rating" class="ftcb__msg__btn-titlenoimage">${textTag(this.chatControl.dictionary.texts.bot, 'CancelRating')}</div>
            </div>
          </div>
        
              </div>
            </div>
          </div>
        </div>

  `;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', this.template);
        this.bind();
    }
    bind() {
        $("#rating .ftcb__rating__star").click((event) => {
            const rating = $(event.currentTarget);
            const star = Number(rating.data('rating'));
            switch (star) {
                case 5:
                    $('#rating5').removeClass('ftcb__rating__star--empty').addClass('ftcb__rating__star--filled');
                case 4:
                    $('#rating4').removeClass('ftcb__rating__star--empty').addClass('ftcb__rating__star--filled');
                case 3:
                    $('#rating3').removeClass('ftcb__rating__star--empty').addClass('ftcb__rating__star--filled');
                case 2:
                    $('#rating2').removeClass('ftcb__rating__star--empty').addClass('ftcb__rating__star--filled');
                case 1:
                    $('#rating1').removeClass('ftcb__rating__star--empty').addClass('ftcb__rating__star--filled');
                    break;
            }
            $('#stars .ftcb__msg--is-user .ftcb__msg__text').html($('#rating').html());
            $('#stars').addClass("ftcb__msg--answered");
            $(".container-rating").css("display", "flex");
            $('#confirm-rating').on('click', event => {
                this.chatControl.sendRatingUser(rating.data('payload'));
            });
            $('#cancel-rating').on('click', event => {
                let text = $('#cancel-rating').text();
                $('#stars').unbind();
                $('#stars').remove();
                $('.container-rating').unbind();
                $('.container-rating').remove();
                this.chatControl.sendUserMessage(text);
            });
        });
    }
}
