import { ModalScreen } from "../ModalScreen";
import { EnrolmentController } from "./EnrolmentController";
import { textTag } from "../TextTag";
export class SkillsTestController extends EnrolmentController {
    constructor() {
        super(...arguments);
        this.endTest = () => {
            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'test habilidades fin');
            const skills = {
                hard_skills: this.user.hardSkills,
                soft_skills: this.user.softSkills.skills,
                field_skills: this.user.fieldSkills
            };
            this.chatControl.profileServices.updateUserSkills(skills, (err) => {
                setTimeout(() => {
                    if (err) {
                        this.chatControl.errorModal(() => this.end());
                    }
                    else {
                        new ModalScreen({
                            title: textTag(this.chatControl.dictionary.texts.modals, 'SkillsTestEnd_title'),
                            subtitle: textTag(this.chatControl.dictionary.texts.modals, 'SkillsTestEnd_subtitle'),
                            primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => { this.end(); } },
                        });
                    }
                }, 2000);
            });
        };
        this.sendInitAnalytics = (callback) => {
            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'test habilidades inicio');
            callback();
        };
        this.noFieldsEnd = () => {
            this.chatControl.sendNoFieldsEnrolment();
            this.popUp.close();
        };
        this.functionWaterfall = [
            this.sendInitAnalytics,
            this.progress.start,
            this.softSkills.start,
            this.services.getSoftSkills,
            this.fieldSkills.start,
            this.services.getHardSkills,
            this.hardSkills.start,
            this.endTest
        ];
    }
}
