import { EnrolmentTagCloud } from "./EnrolmentTagCloud";
import { textTag } from "../TextTag";
export class EnrolmentHardSkills extends EnrolmentTagCloud {
    constructor(enrolmentCtrl) {
        super(enrolmentCtrl, "enrolmentHardSkills", (skills) => {
            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_eleccion', 'Habilidades:' + skills);
            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_completado', 'Habilidades');
        });
        this.enrolmentCtrl = enrolmentCtrl;
        this.start = (callback) => {
            this.skillsArray = this.enrolmentCtrl.user.hardSkills;
            this.optionTags = this.enrolmentCtrl.hardSkillsOptions;
            this.questionTxt = this.enrolmentCtrl.hardSkillsQuestion[0].question;
            this.buttonTxt = textTag(this.enrolmentCtrl.enrolmentTexts, 'SoftSkills3');
            this.motivationTxt = textTag(this.enrolmentCtrl.enrolmentTexts, 'Motivation6');
            this.create(callback);
        };
    }
}
