export class ProfileSwitch {
    constructor(parentClass, id, switchOptions, callback) {
        this.parentClass = parentClass;
        this.id = id;
        this.switchOptions = switchOptions;
        this.callback = callback;
        this.templateOption = (option) => `
      <div class="ftcb__profile__switch-button" data-option="${option.option}">
        ${option.text}
      </div>
  `;
        this.templateElement = (id, options) => `
    <div id=${id} class="ftcb__profile__switch">
      ${options}
    </div>
  `;
        const optionsString = switchOptions.map((option) => this.templateOption(option));
        this.template = this.templateElement(this.id, optionsString.join(''));
    }
    bind() {
        $(`#${this.id}`).find(`[data-option='${this.parentClass.currentSwitchOption}']`).addClass("ftcb__profile__switch--selected");
        $(`.ftcb_profile-switchPage_${this.parentClass.currentSwitchOption}`).addClass("ftcb_profile-switchPage--selected");
        $(`#${this.id} .ftcb__profile__switch-button`).click((event) => {
            if (this.id === 'profileCoursesSwitch') {
                $(`#${this.id} .ftcb__profile__switch-button`).removeClass("ftcb__profile__switch--selected");
                $(event.currentTarget).addClass("ftcb__profile__switch--selected");
                $(`.ftcb_profile-switchPage`).removeClass('ftcb_profile-switchPage--selected');
                let option = $(`#${this.id} .ftcb__profile__switch--selected`).data("option");
                $(`.ftcb_profile-switchPage_${option}`).addClass('ftcb_profile-switchPage--selected');
                return;
            }
            $(`#${this.id} .ftcb__profile__switch-button`).removeClass("ftcb__profile__switch--selected");
            $(event.currentTarget).addClass("ftcb__profile__switch--selected");
            let lastCurrentSwitchOption = this.parentClass.currentSwitchOption;
            this.parentClass.currentSwitchOption = $(`#${this.id} .ftcb__profile__switch--selected`).data("option");
            if (this.parentClass.popUp.popUpId === "profileSkills") {
                if (this.parentClass.userInfo[this.parentClass.currentSwitchOption] &&
                    this.parentClass.userInfo[this.parentClass.currentSwitchOption].length > 0) {
                    $(".ftcb__profile__tmpskills-container").show();
                    $(".ftcb__profile__test-container").hide();
                }
                else {
                    $(".ftcb__profile__tmpskills-container").hide();
                    if (this.parentClass.currentSwitchOption === "digital_skills") {
                        $(".ftcb__profile__test-container #doSkillsTest").hide();
                        $(".ftcb__profile__test-container #doDigitalTest").show();
                    }
                    else {
                        $(".ftcb__profile__test-container #doSkillsTest").show();
                        $(".ftcb__profile__test-container #doDigitalTest").hide();
                    }
                    $(".ftcb__profile__test-container").show();
                }
                if (this.parentClass.currentSwitchOption !== "digital_skills" &&
                    this.parentClass.userInfo[this.parentClass.currentSwitchOption] &&
                    this.parentClass.userInfo[this.parentClass.currentSwitchOption].length > 0) {
                    $(".ftcb__msg__buttoncontainersave").show();
                    $(".ftcb__msg__buttoncontainerfieldskills").show();
                    $(".ftcb__msg__buttoncontainerdigital").hide();
                }
                if (this.parentClass.currentSwitchOption === "digital_skills" &&
                    this.parentClass.userInfo[this.parentClass.currentSwitchOption].length > 0) {
                    $(".ftcb__profile__search-container").hide();
                    $(".ftcb__msg__buttoncontainersave").hide();
                    $(".ftcb__msg__buttoncontainerfieldskills").hide();
                    $(".ftcb__msg__buttoncontainerdigital").show();
                    $("#profileSkills div[class*=' ftcb_graphic-']").each((i, skill) => {
                        if ($(skill).data('score') == '0') {
                            $(skill).find(".ftcb_graphic_color-barr").css('background-color', 'transparent');
                            $(skill).find(".ftcb_graphic_color-barr_number-barr").css({ "background-color": "transparent", "color": "#0e0e0e" });
                        }
                        setTimeout(() => $(skill).find(".ftcb_graphic_color-barr").css("width", `${$(skill).data('score')}%`));
                    });
                }
                if (lastCurrentSwitchOption === "digital_skills" &&
                    this.parentClass.currentSwitchOption !== "digital_skills" &&
                    this.parentClass.userInfo["digital_skills"].length > 0) {
                    $("#profileSkills div[class*=' ftcb_graphic-']").each((i, skill) => {
                        $(skill).find(".ftcb_graphic_color-barr").css("width", "30px");
                    });
                }
            }
            if (this.parentClass.currentSwitchOption !== "digital_skills" &&
                this.parentClass.userInfo[this.parentClass.currentSwitchOption] &&
                this.parentClass.userInfo[this.parentClass.currentSwitchOption].length > 0) {
                $(".ftcb__profile__search-container").show();
            }
            $(".ftcb_profile-switchPage").removeClass("ftcb_profile-switchPage--selected");
            $(`.ftcb_profile-switchPage_${this.parentClass.currentSwitchOption}`).addClass("ftcb_profile-switchPage--selected");
            if (this.parentClass.currentSwitchOption !== 'digital_skills' && $(".ftcb__profile__skills").children(".ftcb_profile-switchPage--selected").children().children().hasClass("ftcb__profile__skill--unselected")) {
                $(".ftcb__msg__buttoncontainersave").children().removeClass("disabled");
            }
            else {
                $(".ftcb__msg__buttoncontainersave").children().addClass("disabled");
            }
            if (this.callback)
                this.callback();
        });
    }
}
