import { textTag } from "../TextTag";
import { ModalScreen } from "../ModalScreen";
export class ProfileDigitalButton {
    constructor(parentClass, testCallback) {
        this.parentClass = parentClass;
        this.testCallback = testCallback;
        this.bind = () => {
            $("#Test").click(() => {
                new ModalScreen({
                    title: textTag(this.dictionary.modals, 'SkillsTestInit_title'),
                    subtitle: textTag(this.dictionary.modals, 'SkillsTestInit_subtitle'),
                    secondaryButton: { text: textTag(this.dictionary.modals, 'Cancelar') },
                    primaryButton: { text: textTag(this.dictionary.modals, 'Aceptar'), callback: () => {
                            this.parentClass.popUp.close();
                            this.parentClass.chatCtrl.startSkillsTest(this.testCallback);
                        }
                    },
                });
            });
            $("#Digital").click(() => {
                this.parentClass.chatCtrl.sendUserMessage('Ser más digital');
                this.parentClass.popUp.close();
            });
        };
        this.dictionary = this.parentClass.chatCtrl.dictionary.texts;
        this.template = `
    <div class="ftcb__profile__test">
      <div class="ftcb__profile__test-text">
        <p>${textTag(this.dictionary.profile, 'SinHards')}</p>
      </div>
      <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer" >
        <div class="ftcb__msg__btn-content ftcb__profile__btn-title" id="Test">
          <div class="ftcb__msg__btn-titlenoimage">${textTag(this.dictionary.profile, 'BotonTest')}</div>
        </div>
      </div>
      <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer" >
        <div class="ftcb__msg__btn-content ftcb__profile__btn-title" id="Digital">
          <div class="ftcb__msg__btn-titlenoimage">${textTag(this.dictionary.profile, 'BotonDigital')}</div>
        </div>
      </div>
    </div>
  `;
    }
}
