import { textTag } from "../TextTag";
import { ModalScreen } from "../ModalScreen";
export class ProfileTestButton {
    constructor(parentClass, testCallback) {
        this.parentClass = parentClass;
        this.testCallback = testCallback;
        this.bind = () => {
            $(".ftcb__profile__test .ftcb__msg__btn-content").click(() => {
                this.parentClass.chatCtrl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'test habilidades recordatorio');
                new ModalScreen({
                    title: textTag(this.dictionary.modals, 'SkillsTestInit_title'),
                    subtitle: textTag(this.dictionary.modals, 'SkillsTestInit_subtitle'),
                    secondaryButton: { text: textTag(this.dictionary.modals, 'Cancelar') },
                    primaryButton: {
                        text: textTag(this.dictionary.modals, 'Aceptar'), callback: () => {
                            this.parentClass.popUp.close();
                            if (this.parentClass.currentSwitchOption === 'digital_skills') {
                                this.parentClass.chatCtrl.startDigitalSkillsTest();
                            }
                            else {
                                this.parentClass.chatCtrl.startSkillsTest(this.testCallback);
                            }
                        }
                    },
                });
            });
        };
        this.dictionary = this.parentClass.chatCtrl.dictionary.texts;
        this.template = `
    <div class="ftcb__profile__test">
      <div class="ftcb__profile__test-text">
        <p>${textTag(this.dictionary.profile, 'Test')}</p>
      </div>
      <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
        <div class="ftcb__msg__btn-content ftcb__profile__btn-title">
          <div class="ftcb__msg__btn-titlenoimage" id="doSkillsTest">${textTag(this.dictionary.profile, 'FieldSkillsTest')}</div>
          <div class="ftcb__msg__btn-titlenoimage" id="doDigitalTest" style="display:none !important">${textTag(this.dictionary.profile, 'DigitalSkillTest')}</div>
        </div>
      </div>
    </div>
  `;
    }
}
