import { EnrolmentComponent } from "./EnrolmentComponent";
import { CountrySelector } from "../CountrySelector";
import { textTag } from "../TextTag";
export class EnrolmentIntro {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.countrySelector = new CountrySelector(this.enrolmentCtrl.chatControl);
        this.templateQ1 = () => {
            const texts = this.enrolmentCtrl.enrolmentTexts;
            const icons = this.enrolmentCtrl.icons;
            return `
    <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${textTag(texts, 'Intro1')}
        </span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" id="formQ1">
      <div class="ftcb__msg--is-bot">
        <div class="ftcb__msg__cards">
          <div class="ftcb__msg__buttoncontainer">            
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__custom">
              <input type="text" class="ftcb__inputcontainer__textfield__input" maxlength="20" placeholder="${textTag(texts, 'Intro2')}" onclick="$(this).focus();">
              <button class="ftcb__inputcontainer__button ftcb__inputcontainer__button--send">
                <img src="${icons.submitIcon}" alt="submit" class="ftcb__inputcontainer__submit-icon">
              </button>
            </div>     
            <div class="ftcb__profile__form-errTxt class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__custom" style="margin-left: 20px; text-align: left;height: inherit;"><span>${textTag(texts, 'ErrorCharacters')}</span></div>     
          </div>
        </div>
      </div> 
      <div class="ftcb__msg--is-user">
        <!-- <div class="ftcb__msg--is-user ftcb__avatar-icon custom"></div> -->
        <span class="ftcb__msg__text"></span>
      </div>
    </div>
    `;
        };
        this.templateQ2 = () => {
            const texts = this.enrolmentCtrl.enrolmentTexts;
            const icons = this.enrolmentCtrl.icons;
            const name = textTag(texts, 'Intro3').replace("[name]", this.enrolmentCtrl.user.username);
            return `
     <div class="ftcb__msg ftcb__msg--is-bot">
       <div class="bot-icon"></div>
         <span class="ftcb__msg__text">
           ${name}
         </span>
       </div>
     </div>
     <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" id="formQ2">
       <div class="ftcb__msg--is-bot">
         <div class="ftcb__msg__cards">
           <div class="ftcb__msg__buttoncontainer">            
             <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__custom">
               <input type="number" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Intro4')}"  onclick="$(this).focus();">
               <button class="ftcb__inputcontainer__button ftcb__inputcontainer__button--send">
                 <img src="${icons.submitIcon}" alt="submit" class="ftcb__inputcontainer__submit-icon">
               </button>
             </div>            
           </div>
         </div>
       </div>      
       <div class="ftcb__msg--is-user">
        <!-- <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom" ></div> -->
        <span class="ftcb__msg__text"></span>
       </div>
     </div> 
     <div class="ftcb__enrolment__login-errror">
         <span id="span_error_age"></span>
     </div>
     `;
        };
        this.templateQSituation = () => {
            const texts = this.enrolmentCtrl.enrolmentTexts;
            const icons = this.enrolmentCtrl.icons;
            return `
     <div class="ftcb__msg ftcb__msg--is-bot">
       <div class="bot-icon"></div>
         <span class="ftcb__msg__text">
          ${textTag(texts, 'IntroSituation')}
         </span>
       </div>
     </div>
     <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" id="formQSituation">
       <div class="ftcb__msg--is-bot">
         <div class="ftcb__msg__cards">
           <div class="ftcb__msg__buttoncontainer">             
             <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__custom">
               
              <div class="ftcb__profile__form_select">
                <select id="situation" name="situation" class="ftcb__profile__form-select">
                  <option id="situation_1" value="1">${textTag(texts, 'Situation1')}</option>
                  <option id="situation_2" value="2">${textTag(texts, 'Situation2')}</option>
                  <option id="situation_3" value="3">${textTag(texts, 'Situation3')}</option>
                  <option id="situation_4" value="4">${textTag(texts, 'Situation4')}</option>
                </select>
                <button class="ftcb__inputcontainer__button ftcb__inputcontainer__button--send">
                 <img src="${icons.submitIcon}" alt="submit" class="ftcb__inputcontainer__submit-icon">
                </button>
              </div>

             </div>           
           </div>
         </div>
       </div>
       <div class="ftcb__msg--is-user">
        <!-- <div class="ftcb__msg--is-user ftcb__avatar-icon custom"></div> -->
        <span class="ftcb__msg__text"></span>
       </div>
     </div>
     `;
        };
        this.templateQ3 = () => {
            const texts = this.enrolmentCtrl.enrolmentTexts;
            const icons = this.enrolmentCtrl.icons;
            return `
     <div class="ftcb__msg ftcb__msg--is-bot">
       <div class="bot-icon"></div>
         <span class="ftcb__msg__text">
          ${textTag(texts, 'Intro5')}
         </span>
       </div>
     </div>
     <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" id="formQ3">
       <div class="ftcb__msg--is-bot">
         <div class="ftcb__msg__cards">
           <div class="ftcb__msg__buttoncontainer">             
             <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__custom">
               ${this.countrySelector.template}
               <button class="ftcb__inputcontainer__button ftcb__inputcontainer__button--send">
                 <img src="${icons.submitIcon}" alt="submit" class="ftcb__inputcontainer__submit-icon">
               </button>
             </div>           
           </div>
         </div>
       </div>
       <div class="ftcb__msg--is-user">
        <!-- <div class="ftcb__msg--is-user ftcb__avatar-icon custom"></div> -->
        <span class="ftcb__msg__text"></span>
       </div>
     </div>
     `;
        };
        this.templateQ4 = () => {
            const texts = this.enrolmentCtrl.enrolmentTexts;
            const icons = this.enrolmentCtrl.icons;
            return `
     <div class="ftcb__msg ftcb__msg--is-bot">
       <div class="bot-icon"></div>
         <span class="ftcb__msg__text">
            ${textTag(texts, 'Intro7')}
         </span>
       </div>
     </div>
     <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" id="formQ4">
       <div class="ftcb__msg--is-bot">
         <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer avatar-custom">             
              <div class="ftcb__msg__btn" data-gender="1">
                <div class="ftcb__msg__btn-content custom">
                  <div class="ftcb__msg__btn-avatar">
                    <img src="${icons.avatar1}">
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage ftcb__enrolment__titleavatar">${textTag(texts, 'Intro8')}</div>
                </div>
              </div>
              <div class="ftcb__msg__btn" data-gender="2">
                <div class="ftcb__msg__btn-content custom">
                  <div class="ftcb__msg__btn-avatar">
                    <img src="${icons.avatar2}">
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage ftcb__enrolment__titleavatar">${textTag(texts, 'Intro9')}</div>
                </div>
              </div>   
              <div class="ftcb__msg__btn" data-gender="3">
                <div class="ftcb__msg__btn-content custom">
                  <div class="ftcb__msg__btn-avatar">
                    <img src="${icons.avatar3}">
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage ftcb__enrolment__titleavatar">${textTag(texts, 'Intro10')}</div>
                </div>
              </div>   
              <div class="ftcb__msg__btn" data-gender="4">
                <div class="ftcb__msg__btn-content custom">
                  <div class="ftcb__msg__btn-avatar">
                    <img src="${icons.avatar4}">
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage ftcb__enrolment__titleavatar">${textTag(texts, 'Intro11')}</div>
                </div>
              </div>   
            </div>
         </div>
       </div>
       <div class="ftcb__msg--is-user">
        <!-- <div class="ftcb__msg--is-user ftcb__avatar-icon custom"></div> -->
        <div class="ftcb__msg__text"></div>
       </div>
     </div>
     `;
        };
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentIntro', 'ftcb__enrolment__screen');
        this.printQ1 = (callback) => {
            const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, 'introQ1', 'ftcb__enrolment__Intro_element');
            if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_enrolment') {
                this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_inicio', 'iniciado');
            }
            questionComponent.setContent(this.templateQ1());
            questionComponent.inject(this.container.component);
            $("#formQ1 button").click(() => {
                const name = $("#formQ1 input").val() + '';
                if (name && this.enrolmentCtrl.chatControl.validator.isValid(name, 'name')) {
                    $('#formQ1 span').hide();
                    this.enrolmentCtrl.user.username = name;
                    $(`#formQ1`).addClass("ftcb__msg--answered");
                    $(`#formQ1 .ftcb__msg--is-user .ftcb__msg__text`).html(name);
                    $(`#formQ1 .ftcb__msg--is-user .ftcb__msg__text`).show();
                    this.enrolmentCtrl.scroll();
                    this.printQ2(callback);
                }
                else {
                    $("#formQ1 .ftcb__inputcontainer__textfield__input").addClass("ftcb__enrolment__btn_err");
                    $('#formQ1 span').show();
                }
            });
        };
        this.printQ2 = (callback) => {
            const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, 'introQ2', 'ftcb__enrolment__Intro_element');
            questionComponent.setContent(this.templateQ2());
            questionComponent.inject(this.container.component);
            this.enrolmentCtrl.chatControl.validator.setNumberDimension("#formQ2 input", 4);
            $("#formQ2 button").click(() => {
                const age = $("#formQ2 input").val() + '';
                if (age && this.enrolmentCtrl.chatControl.validator.setMinYearValidate(Number(age)) && this.enrolmentCtrl.chatControl.validator.setMaxYearValidate(Number(age))) {
                    $("#span_error_age").hide();
                    this.enrolmentCtrl.user.age = Number(age);
                    var gaCategory = 'enrolment';
                    if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment') {
                        gaCategory = 'digitalizacion';
                    }
                    this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent(gaCategory, 'ev_inicio', 'año:' + this.enrolmentCtrl.user.age);
                    $(`#formQ2`).addClass("ftcb__msg--answered");
                    $(`#formQ2 .ftcb__msg--is-user .ftcb__msg__text`).html(age);
                    this.enrolmentCtrl.scroll();
                    this.printQ3(callback);
                }
                else {
                    $("#formQ2 .ftcb__inputcontainer__textfield__input").addClass("ftcb__enrolment__btn_err");
                    !this.enrolmentCtrl.chatControl.validator.setMinYearValidate(Number(age)) ? $("#span_error_age").html(textTag(this.enrolmentCtrl.enrolmentTexts, 'Registry18')).show() :
                        $("#span_error_age").html(textTag(this.enrolmentCtrl.enrolmentTexts, 'Registry19')).show();
                }
            });
        };
        this.printQ3 = (callback) => {
            const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, 'introQ3', 'ftcb__enrolment__Intro_element');
            questionComponent.setContent(this.templateQ3());
            questionComponent.inject(this.container.component);
            $("#formQ3 button").click(() => {
                const country = Number($("#formQ3 select").val());
                if (country || !this.enrolmentCtrl.chatControl.validator.isValid(country, 'country')) {
                    this.enrolmentCtrl.user.country = country;
                    var gaCategory = 'enrolment';
                    if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment') {
                        gaCategory = 'digitalizacion';
                    }
                    this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent(gaCategory, 'ev_inicio', 'pais:' + this.enrolmentCtrl.user.country);
                    $(`#formQ3`).addClass("ftcb__msg--answered");
                    $(`#formQ3 .ftcb__msg--is-user .ftcb__msg__text`).html(textTag(this.enrolmentCtrl.chatControl.dictionary.texts.countries, `Country${Object.keys(COUNTRIES_ID)[Object.values(COUNTRIES_ID).indexOf(country)]}`));
                    this.enrolmentCtrl.scroll();
                    this.printQSituation(callback);
                }
                else {
                    $("#formQ3 .ftcb__inputcontainer__textfield__input").addClass("ftcb__enrolment__btn_err");
                }
            });
        };
        this.start = (callback) => {
            this.container.inject(this.enrolmentCtrl.container.component);
            this.printQ1(callback);
        };
    }
    ;
    printQSituation(callback) {
        const texts = this.enrolmentCtrl.enrolmentTexts;
        const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, 'introQSituation', 'ftcb__enrolment__Intro_element');
        questionComponent.setContent(this.templateQSituation());
        questionComponent.inject(this.container.component);
        $("#formQSituation .ftcb__inputcontainer__button--send").click((event) => {
            const situation = Number($('select[name="situation"]').val());
            this.enrolmentCtrl.user.situation = situation;
            const situation_text = textTag(texts, `Situation${situation}`);
            var gaCategory = 'enrolment';
            if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment') {
                gaCategory = 'digitalizacion';
            }
            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent(gaCategory, 'ev_inicio', 'situation:' + this.enrolmentCtrl.user.situation + '');
            $(`#formQSituation`).addClass("ftcb__msg--answered");
            $(`#formQSituation .ftcb__msg--is-user .ftcb__msg__text`).html(situation_text);
            this.enrolmentCtrl.scroll();
            this.printQ4(callback);
        });
    }
    printQ4(callback) {
        const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, 'introQ4', 'ftcb__enrolment__Intro_element');
        questionComponent.setContent(this.templateQ4());
        questionComponent.inject(this.container.component);
        $("#formQ4 .ftcb__msg__btn").click((event) => {
            const answer = $(event.currentTarget);
            const gender = Number(answer.data("gender"));
            const avatar = `avatar${gender}`;
            this.enrolmentCtrl.user.gender = gender;
            var gaCategory = 'enrolment';
            if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment') {
                gaCategory = 'digitalizacion';
            }
            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent(gaCategory, 'ev_inicio', 'genero:' + this.enrolmentCtrl.user.gender + '');
            this.enrolmentCtrl.user.avatar = this.enrolmentCtrl.icons[avatar];
            $(`#formQ4`).addClass("ftcb__msg--answered");
            $(`#formQ4 .ftcb__msg--is-user .ftcb__msg__text`).html(answer.children().html());
            this.enrolmentCtrl.scroll();
            callback(null);
        });
    }
}
