import { PopUp } from "../PopUpScreen";
import { StaticsControl } from "../staticsControl";
import { textTag } from "../TextTag";
import { CaptchaComponent } from "../enrolment/CaptchaComponent";
export class ProfileContact {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.icons = StaticsControl.statics;
        this.profileServices = this.chatCtrl.profileServices;
        this.template = `
        <div class="ftcb__profile__popUp__header">
          <div class="ftcb__profile__popUp__header-image">  
            <img src="${this.icons.profileOptionIcon6}">
          </div>
          <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Contact')}</div>
        </div>
        <div class="ftcb__profile__form">
          <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="name">
            <div class="ftcb__profile__form-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Contact1')}</div>
            <div class="ftcb__profile__form-input">
              <input type="name" maxlength="20" size="3" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">
            </div>
            <div class="ftcb__profile__form-errTxt"><span>${textTag(this.chatCtrl.dictionary.texts.profile, 'Error')}</span></div>
          </div>
          <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="email">
            <div class="ftcb__profile__form-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Contact2')}</div>
            <div class="ftcb__profile__form-input" id="emaildiv">
              <input type="email" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();" required>
            </div>
            <div class="ftcb__profile__form-errTxt"><span>${textTag(this.chatCtrl.dictionary.texts.profile, 'Error')}</span></div>
          </div>
          <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="subject">
            <div class="ftcb__profile__form-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Contact3')}</div>
            <div class="ftcb__profile__form-input">
              <input type="subject" maxlength="40" size="3" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">
            </div>
            <div class="ftcb__profile__form-errTxt"><span>${textTag(this.chatCtrl.dictionary.texts.profile, 'Error')}</span></div>
          </div>
          <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="body">
            <div class="ftcb__profile__form-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Contact4')}</div>
            <div class="ftcb__profile__form-input">
              <textarea type="body" onclick="$(this).focus();"></textarea>
            </div>
            <div class="ftcb__profile__form-errTxt"><span>${textTag(this.chatCtrl.dictionary.texts.profile, 'Error')}</span></div>
          </div>
          <div id="captchaContainer" style="margin-bottom: 2rem"></div>
          <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer ftcb__contact__button" id="sendMail">
            <div class="ftcb__msg__btn-content ftcb__profile__btn-title">
              <div class="ftcb__msg__btn-titlenoimage">${textTag(this.chatCtrl.dictionary.texts.profile, 'Contact5')}</div>
            </div>
          </div>
        </div>
    `;
        this.popUp = new PopUp('profileContact', this.template);
        if (this.chatCtrl.iduser) {
            this.profileServices.getUser((err, response) => {
                if (err)
                    return this.chatCtrl.errorModal();
                this.userInfo = response;
                if (this.userInfo.username) {
                    $("#name input").attr("readonly", "readonly");
                    $("#name input").val(this.userInfo.username);
                    $("#name").addClass("ftcb__inputcontainer__textfield__readonly");
                }
                if (this.userInfo.email) {
                    $("#email input").val(this.userInfo.email);
                    $("#email input").attr("readonly", "readonly");
                    $("#email").addClass("ftcb__inputcontainer__textfield__readonly");
                }
                this.bind();
            });
        }
        else {
            const userdata = {
                email: '',
                username: ''
            };
            this.userInfo = userdata;
            this.bind();
        }
    }
    bind() {
        const recaptcha = new CaptchaComponent();
        recaptcha.render('#captchaContainer');
        $("#sendMail .ftcb__msg__btn-content").click((event) => {
            $(".ftcb__profile__form-field").removeClass('ftcb__profile__form-field-err');
            this.userInfo.username = $("#name input").val() + '';
            this.userInfo.email = $("#email input").val() + '';
            const body = $("#body textarea").val() + '';
            const subject = $("#subject input").val() + '';
            let invalid = false;
            if (!body) {
                $("#body").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!subject || !this.chatCtrl.validator.isValid(subject, 'subject')) {
                $("#subject").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!this.userInfo.username || !this.chatCtrl.validator.isValid(this.userInfo.username, 'name')) {
                $("#name").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!this.userInfo.email || !this.chatCtrl.validator.isValid(this.userInfo.email, 'email')) {
                $("#email").addClass('ftcb__profile__form-field-err');
                invalid = true;
            }
            if (!recaptcha.isValid())
                return recaptcha.warning(true);
            recaptcha.warning(false);
            if (!invalid && subject && body) {
                const mail = {
                    subject: subject,
                    body: body,
                    captcha: recaptcha.getToken()
                };
                this.profileServices.sendContact(this.userInfo, mail, (err, response) => {
                    if (err)
                        return console.log(err);
                });
                this.popUp.close();
            }
        });
    }
}
