import { StaticsControl } from "./staticsControl";
export class PopUp {
    constructor(popUpId, html) {
        this.popUpId = popUpId;
        this.html = html;
        this.parentContainer = document.getElementById("chatbotContainer");
        this.setExtraCloseBehaviour = (toDoAtClosing) => {
            this.extraCloseBehaviour = toDoAtClosing;
        };
        this.stop = () => {
            if (this.extraCloseBehaviour)
                this.extraCloseBehaviour();
            this.close();
        };
        this.close = () => {
            this.component.classList.remove("ftcb__enrolment__slide-up");
            this.component.classList.add("ftcb__enrolment__slide-down");
            this.component.addEventListener("animationend", () => {
                this.component.remove();
            });
        };
        this.component = document.createElement("div");
        this.component.id = this.popUpId;
        this.component.className = "ftcb__popup";
        this.component.classList.add('ftcb__enrolment__container');
        this.component.classList.add('ftcb__msgwrapper--menu-close');
        this.component.classList.add('ftcb__msgwrapper');
        this.component.classList.add('ftcb__enrolment__slide-up');
        this.component.innerHTML = this.html;
        this.closeButton = document.createElement("div");
        this.closeButton.id = 'popupCloseButton';
        this.closeButton.className = "ftcb__enrolment_close";
        this.closeButton.innerHTML = `<img src="${StaticsControl.statics.closeIcon}">`;
        this.component.appendChild(this.closeButton);
        this.parentContainer.appendChild(this.component);
        $(this.component).innerHeight($("#chatBotMessageWrapper").innerHeight() + $(".ftcb__inputcontainer").innerHeight());
        this.closeButton.onclick = this.stop;
    }
}
