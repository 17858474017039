import { EnrolmentComponent } from "./EnrolmentComponent";
import { EnrolmentController } from "./EnrolmentController";
import { LoginForm } from "./LoginForm";
import { LoginRecover } from "./LoginRecover";
import { LoginChangePass } from "./LoginChangePass";
export class LoginController extends EnrolmentController {
    constructor() {
        super(...arguments);
        this.container = new EnrolmentComponent(this, 'loginContainer', 'ftcb__enrolment__container');
        this.loginForm = new LoginForm(this);
        this.loginRecover = new LoginRecover(this);
        this.loginChangePass = new LoginChangePass(this);
        this.startRecover = (callback) => {
            if (this.loginChangePass.container)
                this.loginChangePass.container.destroy();
            $(this.loginForm.container.component).hide();
            this.loginRecover.start(callback);
        };
        this.stopRecover = () => {
            this.loginRecover.container.destroy();
            $(this.loginForm.container.component).show();
        };
        this.startChPassword = (callback) => {
            this.loginRecover.container.destroy();
            this.loginChangePass.start(callback);
        };
        this.functionWaterfall = [
            this.loginForm.start,
            this.setUserData,
            this.end
        ];
    }
}
