export class AvatarControl {
    constructor(chatControl) {
        this.chatControl = chatControl;
        this.clicked = false;
        this.sentences = [
            'Soy OPV, ¿necesitas ayuda?',
            'Soy OPV, ¿puedo ayudarte?',
            'Soy OPV, ¿tienes alguna consulta?',
            'Soy OPV, ¿puedo ayudarte en algo?'
        ];
        this.timesShownMessage = 0;
        this.avatarDiv = document.querySelector("div#chatAvatar");
        this.openChat = document.querySelectorAll(".openChat");
        this.avatarMsgDiv = document.querySelector("div#chatAvatarMsg");
        this.body = document.getElementsByTagName("body")[0];
        setTimeout(() => {
            this.showMsg();
        }, 5000);
    }
    show() {
        this.avatarDiv.style.display = "block";
    }
    hide() {
        this.avatarDiv.style.display = "none";
        this.hideMsg();
    }
    set clickCallback(callback) {
        this.bind(this.openChat, callback);
        const avatarList = document.querySelectorAll("div#chatAvatar");
        this.bind(avatarList, callback);
    }
    bind(nodelist, callback) {
        Array.from(nodelist).forEach((item) => {
            item.addEventListener("click", (event) => {
                this.clicked = true;
                if (this.avatarDiv.classList.contains('ftcb__avatar__container--open')) {
                    this.avatarDiv.classList.add('ftcb__avatar__container--closed');
                    this.avatarDiv.classList.remove('ftcb__avatar__container--open');
                    setTimeout(() => { callback(event); }, 1000);
                }
                else {
                    callback(event);
                }
            });
        });
    }
    showMsg() {
    }
    hideMsg() {
    }
}
