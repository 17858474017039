module.exports = {
  "middleware": process.env.middleware_host || "http://localhost:3001",
  "serverPath": process.env.server_host || "http://localhost:3000",
  "port": process.env.port || 3000,
  "codigoUA": process.env.codigo_analytics || 'UA-41579532-4',
  "measurement_id": process.env.ga4_measurement_id || `G-K9LBC921C3`,
  "api_secret": process.env.ga4_api_secret || `BWf6a2inTIuXtdDioQyudw`,
  "fundacion_measurement_id": process.env.ga4_fundacion_measurement_id || `G-K9LBC921C3`,
  "fundacion_api_secret": process.env.ga4_fundacion_api_secret || `BWf6a2inTIuXtdDioQyudw`,
  "fundacion_sites": [
      "www.fundaciontelefonica.com",
      "conectaempleo-formacion.fundaciontelefonica.com",
      "orientador.fundaciontelefonica.com",
      "mapadelempleo.fundaciontelefonica.com",
      "www.fundaciontelefonica.com.ar",
      "fundaciontelefonica.com.ec",
      "www.fundaciontelefonica.uy",
      "www.fundaciontelefonica.co",
      "www.fundaciontelefonica.com.pe",
      "www.fundaciontelefonica.com.mx",
      "www.fundaciontelefonica.cl",
      "www.fundaciontelefonica.com.ve"
  ]  ,
  "ga_session_id": {
      "www.fundaciontelefonica.com": "_ga_PNZMSJH10D",
      "conectaempleo-formacion.fundaciontelefonica.com": "_ga_WGWPX3K2WQ",
      "orientador.fundaciontelefonica.com": "_ga_XF54HXFPLN",
      "mapadelempleo.fundaciontelefonica.com": "_ga_F61RFCSLY8",
      "www.fundaciontelefonica.com.ar": "_ga_VVG5MJ6MMK",
      "fundaciontelefonica.com.ec": "_ga_HQVE4XGT9Z",
      "www.fundaciontelefonica.uy": "_ga_Z0HT4MJV07",
      "www.fundaciontelefonica.co": "_ga_7K6HRRVNH1",
      "www.fundaciontelefonica.com.pe": "_ga_TETVKJHWRW",
      "www.fundaciontelefonica.com.mx": "_ga_CJ91SKJH82",
      "www.fundaciontelefonica.cl": "_ga_G2VJT789K7",
      "www.fundaciontelefonica.com.ve": "_ga_FKLH8FSDRS"  
  },
  "restDfPath": "/df",
  "restMwPath": "/mw",
  "restGaPath": "/ga",
  "publicPath": "/webchat/",
  "dfUri": process.env.dialogflow_uri,
  "dfCredentials": {
    "private_key_id": process.env.dialogflow_private_key_id,
    "client_email": process.env.dialogflow_client_email,
    "private_key": process.env.dialogflow_private_key,
  },
  "recaptchaPublicKey": process.env.recaptcha_public,
  "logger": {
    "levels": {
      "default": "DEBUG",
    },
    "appenders": [
      {
        "category": "[all]",
        "type": "console",
        "layout": {
          "type": "pattern",
          "pattern": "%d{yyyy-MM-ddThh:mm:ssO}|%[%p%]|%m",
        },
      },
    ],
    "replaceConsole": false,
  },
  "defaultCountry": process.env.default_country || "",
  "cookie_name": process.env.cookie_name || "cookie_rgpd",
  "webapp_host": process.env.webapp_host || "localhost",
  "countries": {
    '1': 68,
    '2': 10,
    '3': 46,
    '4': 49,
    '5': 63,
    '6': 156,
    '7': 173,
    '8': 234,
    '9': 238,
    '10': 252,
  }
}