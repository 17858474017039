export class CountrySelector {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.countryOptions = (texts, selectedCountry) => texts.sort(compare).map((country) => {
            const index = country.identifier.split('Country')[1];
            const selected = (selectedCountry === country.identifier) ? 'selected="selected"' : '';
            return `
        <option id='${country.identifier}' value=${COUNTRIES_ID[index]} ${selected}>${country.text}</option>
      `;
        }).join('');
        const selectedCountry = (DEFAULT_COUNTRY == 'es') ? 'Country1' : 'none';
        this.texts = this.chatCtrl.dictionary.texts.countries;
        this.template = `
        <div class="ftcb__profile__form_select">
          <select id="country" name="country" class="ftcb__profile__form-select">
            ${this.countryOptions(this.texts, selectedCountry)}
          </select>
        </div>
      `;
    }
    ;
}
;
function compare(a, b) {
    if (a.text < b.text) {
        return -1;
    }
    if (a.text > b.text) {
        return 1;
    }
    return 0;
}
