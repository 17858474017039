import { EnrolmentComponent } from "./EnrolmentComponent";
import { textTag } from "../TextTag";
import { CaptchaComponent } from "./CaptchaComponent";
export class EnrolmentRegistry {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.template = (registryCompletedText) => {
            const texts = this.enrolmentCtrl.enrolmentTexts;
            const icons = this.enrolmentCtrl.icons;
            return `
    <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${textTag(texts, registryCompletedText)}
        </span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn" id="formRegistry">
      <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="2000">
        <div class="ftcb__msg__cards">
          <div class="ftcb__msg__buttoncontainer">
            
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <input type="email" name="email"  class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry2')}" onclick="$(this).focus();">             
            </div>   
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
            <input type="email" name="repeatEmail"  class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry11')}" onclick="$(this).focus();">             
          </div>  
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom" id="password">
              <input type="password" name="password" minlength= "3" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry3')}" onclick="$(this).focus();">   
              <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'Registry24')}</span></div>          
            </div> 
            <!--<div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <input type="tel" name="phone" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry4')}" onclick="$(this).focus();"  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}">             
            </div> -->
            
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom__check">
              <div class="ftcb__msg__btn ftcb__enrolment__input__class_check">
                <input type="checkbox" name="legal" class="ftcb__inputcontainer__textfield__input ftcb__inputcontainer__textfield__input__custom"> 
                <p>
                ${textTag(texts, 'Registry5')}<a href=${textTag(texts, 'LegalUri')} target="_blank" rel="noopener noreferrer"> ${textTag(texts, 'Registry6')}</a>
                </p>
              </div>
            </div>
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom__check">
              <div class="ftcb__msg__btn ftcb__enrolment__input__class_check">
                <input type="checkbox" name="legal-country" class="ftcb__inputcontainer__textfield__input ftcb__inputcontainer__textfield__input__custom"> 
                <p>
                  ${textTag(texts, 'Registry5')}<a href=${textTag(texts, 'LegalCountryUri')} target="_blank" rel="noopener noreferrer"> ${textTag(texts, 'Registry16')}</a>
                </p>
              </div>
            </div>
            <div class="ftcb__enrolment__login-errror">
              <span id="span1">${textTag(texts, 'Registry13')}</span>
              <span id="span2">${textTag(texts, 'Registry12')}</span>
              <div><span id="span3">${textTag(texts, 'Registry14')}</span></div>
              <span id="span4">${textTag(texts, 'Registry15')}</span>
            </div>
            <div id="captchaContainer" style="margin-bottom: 2rem"></div>
          </div>
        </div>

      </div>
      <div class="ftcb__msg--is-user">
        <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"  style="background-image:url('${this.enrolmentCtrl.user.avatar}');"></div>
        <span class="ftcb__msg__text">${textTag(texts, 'Registry8')}</span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" id="endRegistry">
    <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="4000">
      <div class="ftcb__msg__cards">
        <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
          
          <div class="ftcb__msg__btn">
            <div class="ftcb__msg__btn-content">
              <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, 'Registry7')}</div>
              <!-- <img src="${icons.submitIcon}" alt="submit" class="ftcb__inputcontainer__submit-icon__custom"> -->
            </div>
          </div>   
              
        </div>
      </div>
    </div>
    `;
        };
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentRegistry', 'ftcb__enrolment__screen');
        this.start = (callback) => {
            var registryCompletedText = 'Registry1';
            if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment') {
                registryCompletedText = 'Registry21';
            }
            this.container.setContent(this.template(registryCompletedText));
            this.container.inject(this.enrolmentCtrl.container.component);
            $('input[name ="repeatEmail"]').bind("paste", function (e) {
                e.preventDefault();
            });
            let recaptcha = new CaptchaComponent();
            recaptcha.render('#captchaContainer');
            $("#endRegistry").click(() => {
                $('input, #formRegistry div').removeClass('ftcb__enrolment__btn_err');
                $('#formRegistry .ftcb__enrolment__login-errror span').hide();
                this.enrolmentCtrl.user.mail = $('input[name ="email"]').val() + '';
                this.enrolmentCtrl.user.password = $('input[name ="password"]').val() + '';
                this.enrolmentCtrl.user.legal = $('input[name ="legal"]').is(":checked") &&
                    $('input[name ="legal-country"]').is(":checked");
                const repeatEmail = $('input[name ="repeatEmail"]').val() + '';
                let invalid = false;
                if (!this.enrolmentCtrl.user.mail || !this.enrolmentCtrl.chatControl.validator.isValid(this.enrolmentCtrl.user.mail, 'email')) {
                    $('input[name ="email"]').addClass('ftcb__enrolment__btn_err');
                    $('#span4').show();
                    invalid = true;
                }
                if (!this.enrolmentCtrl.user.password || !this.enrolmentCtrl.chatControl.validator.isValid(this.enrolmentCtrl.user.password, 'password')) {
                    $('input[name ="password"]').addClass('ftcb__enrolment__btn_err');
                    $('#password .ftcb__expres__form-errTxt span').show();
                    invalid = true;
                }
                if (!repeatEmail || this.enrolmentCtrl.user.mail != repeatEmail) {
                    $('input[name ="repeatEmail"]').addClass('ftcb__enrolment__btn_err');
                    $('#span2').show();
                    invalid = true;
                }
                if (!this.enrolmentCtrl.user.legal) {
                    $('#span3').show();
                    invalid = true;
                }
                if (!recaptcha.isValid())
                    return recaptcha.warning(true);
                recaptcha.warning(false);
                this.enrolmentCtrl.user.captcha = recaptcha.getToken();
                if (!invalid) {
                    this.enrolmentCtrl.services.registry((err, user) => {
                        if (err === 409) {
                            $('#span1').show();
                            $('input[name ="email"]').addClass('ftcb__enrolment__btn_err');
                            $('input[name ="repeatEmail"]').addClass('ftcb__enrolment__btn_err');
                            recaptcha = new CaptchaComponent();
                            recaptcha.render('#captchaContainer');
                        }
                        else if (err === 500) {
                            callback(err);
                        }
                        else {
                            this.enrolmentCtrl.user.iduser = user.id;
                            this.enrolmentCtrl.user.avatar = this.enrolmentCtrl.icons[`avatar${this.enrolmentCtrl.user.gender}`];
                            $(`#formRegistry, #endRegistry`).addClass("ftcb__msg--answered");
                            $("#enrolmentRegistry div").unbind();
                            var gaCategory = 'enrolment';
                            if (this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment') {
                                gaCategory = 'digitalizacion';
                            }
                            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent(gaCategory, 'ev_completado', 'Final');
                            callback(null);
                        }
                    });
                }
            });
        };
    }
    ;
}
