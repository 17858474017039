import { PopUp } from "../PopUpScreen";
import { StaticsControl } from "../staticsControl";
import { ProfileSwitch } from "./ProfileSwitch";
import { ProfileSkillsContainer } from "./ProfileSkillsContainer";
import { ProfileSkillsSearch } from "./ProfileSkillsSearch";
import { ModalScreen } from "../ModalScreen";
import { ProfileTestButton } from "./ProfileTestButton";
import { textTag } from "../TextTag";
export class ProfileSkills {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.userInfo = {
            hard_skills: [],
            soft_skills: [],
            field_skills: [],
            digital_skills: [],
            type: String
        };
        this.icons = StaticsControl.statics;
        this.profileServices = this.chatCtrl.profileServices;
        this.currentSwitchOption = "hard_skills";
        this.hardSwitchOption = "hard_skills";
        this.softSwitchOption = "soft_skills";
        this.digitalSwitchOption = "digital_skills";
        this.hard_changed = false;
        this.soft_changed = false;
        this.skillSwitchOptions = [
            {
                option: 'hard_skills',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'SkillsSwitchOpt1')
            },
            {
                option: 'soft_skills',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'SkillsSwitchOpt2')
            },
            {
                option: 'digital_skills',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'SkillsSwitchOpt3')
            }
        ];
        this.testCallback = () => {
            this.chatCtrl.sendSkillsTestEnd(this.chatCtrl.iduser);
            new ProfileSkills(this.chatCtrl);
        };
        this.profileServices.getAllSkills("hard_skills", (err, resp) => {
            this.chatCtrl.allHardSkills = resp;
        });
        this.profileServices.getAllSkills("soft_skills", (err, resp) => {
            this.chatCtrl.allSoftSkillsSkills = resp;
        });
        this.profileServices.getAllSkills("digital_skills", (err, resp) => {
            this.chatCtrl.allDigitalSkillsSkills = resp;
        });
        this.profileServices.getUserSkills((err, response) => {
            if (err)
                return this.chatCtrl.errorModal();
            this.userInfo = response;
            this.profileSkillsContainer = new ProfileSkillsContainer(this);
            this.profileSkillsSearch = new ProfileSkillsSearch(this);
            this.profileTestButton = new ProfileTestButton(this, this.testCallback);
            this.profileSwitch = new ProfileSwitch(this, 'profileSkillsSwitch', this.skillSwitchOptions, null);
            this.template = `
        <div class="ftcb__profile__popUp__header">
            <div class="ftcb__profile__popUp__header-image">  
              <img src="${this.icons.profileOptionIcon2}">
            </div>
          <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Skills')}</div>
        </div>
        <div class="ftcb__profile__switch-container">
          ${this.profileSwitch.template}
        </div>
      `;
            this.templateContainer = `
        <div class="ftcb__profile__tmpskills-container" style="display:none">
          <div class="ftcb__profile__search-container">
          ${this.profileSkillsSearch.template}
          </div>
          <div class="ftcb__profile__skills-container">
            ${this.profileSkillsContainer.template}
            <div>
              <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer ftcb__msg__buttoncontainersave">
                <div class="ftcb__msg__btn-content ftcb__profile__btn-title ftcb__profile__skills-button disabled">
                  <div class="">${textTag(this.chatCtrl.dictionary.texts.bot, 'Save')}</div>
                </div>
              </div>
              <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer ftcb__msg__buttoncontainerfieldskills">
                <div class="ftcb__msg__btn-content ftcb__profile__btn-title ftcb__profile__skills-button_skills">
                <div class="ftcb__msg__btn-titlenoimage">${textTag(this.chatCtrl.dictionary.texts.profile, 'FieldSkillsTestRepeat')}</div>
                </div>
              </div>
            </div>
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer ftcb__msg__buttoncontainerdigital" style="display: none">
              <div class="ftcb__msg__btn-content ftcb__profile__btn-title ftcb__profile__skills-button_skills">
              <div class="ftcb__msg__btn-titlenoimage">${textTag(this.chatCtrl.dictionary.texts.profile, 'DigitalSkillsTestRepeat')}</div>
              </div>
            </div>
          </div>
        </div>`;
            this.templateTest = `
        <div class="ftcb__profile__test-container" style="display: none">
          ${this.profileTestButton.template}
        </div>`;
            if (this.userInfo.field_skills.length === 0) {
                this.template += this.templateContainer;
                this.template += ($(this.templateTest).removeAttr("style")).prop('outerHTML');
            }
            else {
                this.template += ($(this.templateContainer).removeAttr("style")).prop('outerHTML');
                this.template += this.templateTest;
            }
            this.popUp = new PopUp('profileSkills', this.template);
            this.bind();
        });
    }
    addSkillToList(skillId) {
        let targetSkill;
        if (this.currentSwitchOption === this.hardSwitchOption) {
            this.hard_changed = true;
            targetSkill = this.chatCtrl.allHardSkills.find((item) => {
                return item.identifier == skillId;
            });
            this.userInfo.hard_skills.push(targetSkill);
        }
        else {
            this.soft_changed = true;
            targetSkill = this.chatCtrl.allSoftSkillsSkills.find((item) => {
                return item.identifier == skillId;
            });
            this.userInfo.soft_skills.push(targetSkill);
        }
        return targetSkill;
    }
    removeSkillFromList(skillId) {
        let filteredList;
        if (this.currentSwitchOption === this.hardSwitchOption) {
            this.hard_changed = true;
            filteredList = this.userInfo.hard_skills.filter((item) => {
                return item.identifier != skillId;
            });
            this.userInfo.hard_skills = filteredList;
        }
        else {
            this.soft_changed = true;
            filteredList = this.userInfo.soft_skills.filter((item) => {
                return item.identifier != skillId;
            });
            this.userInfo.soft_skills = filteredList;
        }
    }
    bind() {
        this.profileSwitch.bind();
        this.profileSkillsContainer.bind();
        this.profileSkillsSearch.bind();
        this.profileTestButton.bind();
        $(".ftcb__profile__skills-button").click(() => {
            this.userInfo.type = $('.ftcb__profile__switch--selected').data('option');
            this.profileServices.updateUserSkills(this.userInfo, (err) => {
                if (err) {
                    this.chatCtrl.errorModal(() => { this.popUp.close(); });
                }
                else {
                    if (this.hard_changed)
                        this.chatCtrl.gaConnection.sendGAEvent('perfil', 'ev_perfil', 'habilidades_tecnicas');
                    if (this.soft_changed)
                        this.chatCtrl.gaConnection.sendGAEvent('perfil', 'ev_perfil', 'habilidades_transversales');
                    new ModalScreen({
                        title: textTag(this.chatCtrl.dictionary.texts.modals, 'SkillsSaved_title'),
                        subtitle: textTag(this.chatCtrl.dictionary.texts.modals, 'SkillsSaved_subtitle'),
                        primaryButton: { text: textTag(this.chatCtrl.dictionary.texts.modals, 'Aceptar'), callback: () => { this.popUp.close(); } },
                    });
                }
            });
        });
        $(".ftcb__profile__skills-button_skills").click(() => {
            new ModalScreen({
                title: textTag(this.chatCtrl.dictionary.texts.modals, 'SkillsTestInit_title'),
                subtitle: textTag(this.chatCtrl.dictionary.texts.modals, 'SkillsTestInit_subtitle'),
                secondaryButton: { text: textTag(this.chatCtrl.dictionary.texts.modals, 'Cancelar') },
                primaryButton: {
                    text: textTag(this.chatCtrl.dictionary.texts.modals, 'Aceptar'), callback: () => {
                        this.popUp.close();
                        this.currentSwitchOption !== this.digitalSwitchOption ? this.chatCtrl.startSkillsTest(this.testCallback)
                            : this.chatCtrl.startDigitalSkillsTest();
                    }
                }
            });
        });
    }
}
