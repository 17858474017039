import { PopUp } from "../PopUpScreen";
import { StaticsControl } from "../staticsControl";
import { textTag } from "../TextTag";
export class ProfileWorkInProgress {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.icons = StaticsControl.statics;
        this.template = `
    <div class="ftcb__profile__popUp__header">
      <div class="ftcb__profile__popUp__header-image">  
       <img src="${this.icons.profileOptionIcon5}">
      </div>
      <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Content')}</div>
    </div>
    <div class="ftcb__profile__popUp__header-imgCohete">
      
    </div>
    <div class="ftcb__profile-text-construction">
      <div class="ftcb__profile-text-construction-text">${textTag(this.chatCtrl.dictionary.texts.profile, 'WorkInProgressText')}</div>
    </div>
  `;
        this.popUp = new PopUp('profileContents', this.template);
    }
}
