import { StaticsControl } from "../staticsControl";
import { ProfileSkills } from "./ProfileSkills";
import { ProfilePersonalData } from "./ProfilePersonalData";
import { ProfileCourses } from "./ProfileCourses";
import { ProfileContact } from "./ProfileContact";
import { ProfileContents } from "./ProfileContents";
import { ProfileCareers } from "./ProfileCareers";
import { ProfileWorkInProgress } from "./ProfileWorkInProgress";
import { textTag } from "../TextTag";
import { ModalScreen } from "../ModalScreen";
export class ProfileMenuControl {
    constructor(chatControl) {
        this.chatControl = chatControl;
        this.icons = StaticsControl.statics;
        this.chatBotProfileMenu = document.querySelector('div#chatBotProfileMenu');
        this.chatBotProfileButton = document.querySelector('div#chatBotProfileButton');
        this.profileServices = this.chatControl.profileServices;
        this.template = `
      <div class="ftcb__profile-item" data-action="personal">
        <div>
          <div class="ftcb__profile-img">
            <img src="${this.icons.profileOptionIcon1}" alt="personal" class="">
          </div>
          <div class="ftcb__profile-text">${textTag(this.chatControl.dictionary.texts.profile, 'Header Data')}</div>
        </div>
      </div>
    
      <div class="ftcb__profile-item" data-action="skills">
        <div>
          <div class="ftcb__profile-img">
            <img src="${this.icons.profileOptionIcon2}" alt="skills" class="">
          </div>
          <div class="ftcb__profile-text">${textTag(this.chatControl.dictionary.texts.profile, 'Header Skills')}</div>
        </div>
      </div>
    
      <div class="ftcb__profile-item" data-action="careers">
        <div>
          <div class="ftcb__profile-img">
            <img src="${this.icons.profileOptionIcon3}" alt="careers" class="">
          </div>
          <div class="ftcb__profile-text">${textTag(this.chatControl.dictionary.texts.profile, 'Header Careers')}</div>
        </div>
      </div>
    
      <div class="ftcb__profile-item" data-action="courses">
        <div>
          <div class="ftcb__profile-img">
            <img src="${this.icons.profileOptionIcon4}" alt="courses" class="">
          </div>
          <div class="ftcb__profile-text">${textTag(this.chatControl.dictionary.texts.profile, 'Header Courses')}</div>
        </div>
      </div>
    
      <div class="ftcb__profile-item" data-action="content">
        <div>
          <div class="ftcb__profile-img">
            <img src="${this.icons.profileOptionIcon5}" alt="content" class="">
          </div>
          <div class="ftcb__profile-text">${textTag(this.chatControl.dictionary.texts.profile, 'Header Content')}</div>
        </div>
      </div>
    
      <div class="ftcb__profile-item" data-action="contact">
        <div>
          <div class="ftcb__profile-img">
            <img src="${this.icons.profileOptionIcon6}" alt="contact" class="">
          </div>
          <div class="ftcb__profile-text">${textTag(this.chatControl.dictionary.texts.profile, 'Header Contact')}</div>
        </div>
      </div>`;
        this.chatBotProfileMenu.insertAdjacentHTML('beforeend', this.template);
        this.chatBotProfileOptions = document.querySelectorAll('.ftcb__profile-item');
        setTimeout(() => this.bindProfileMenu());
    }
    bindProfileMenu() {
        this.chatBotProfileButton.addEventListener("click", () => {
            this.chatControl.brConnection.setSessionId();
            this.chatControl.profileMenuControl.toggleProfileMenu();
        });
        this.chatBotProfileOptions.forEach((item) => item.addEventListener("click", () => {
            const action = $(item).data('action');
            this.chatControl.gaConnection.sendGAEvent('perfil_menu', 'ev_perfil_menu', action);
            if (!this.chatControl.iduser && action !== 'contact') {
                new ModalScreen({
                    buttonType: 1,
                    title: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_title_2'),
                    subtitle: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_subtitle'),
                    secondaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_secondary'), callback: () => {
                            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', action);
                            this.chatControl.startEnrolmentExpres((err) => {
                                if (err)
                                    return;
                                this.chatControl.sendEndEnrolment(this.chatControl.iduser, true);
                                new ModalScreen({
                                    title: textTag(this.chatControl.dictionary.texts.modals, 'CreatedExpress_title'),
                                    subtitle: textTag(this.chatControl.dictionary.texts.modals, 'CreatedExpress_subtitle'),
                                    primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => this.openProfileSection(action) }
                                });
                            });
                        } },
                    primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_primary'), callback: () => {
                            this.chatControl.startLoginExpres((err) => {
                                if (err)
                                    return;
                                this.chatControl.sendEndLogin(this.chatControl.iduser, true);
                                new ModalScreen({
                                    title: textTag(this.chatControl.dictionary.texts.modals, 'LoggedExpress_title'),
                                    subtitle: textTag(this.chatControl.dictionary.texts.modals, 'LoggedExpress_subtitle'),
                                    primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => this.openProfileSection(action) }
                                });
                            });
                        }
                    },
                });
            }
            else {
                this.openProfileSection(action);
            }
            this.closeProfileMenu();
        }));
    }
    openProfileSection(action) {
        switch (action) {
            case 'skills':
                this.profileSkills = new ProfileSkills(this.chatControl);
                break;
            case 'courses':
                this.profileCourses = new ProfileCourses(this.chatControl);
                break;
            case 'careers':
                this.profileCareers = new ProfileCareers(this.chatControl);
                break;
            case 'personal':
                this.profilePersonalData = new ProfilePersonalData(this.chatControl);
                break;
            case 'content':
                if (this.chatControl.isSafari) {
                    this.profileWorkInProgress = new ProfileWorkInProgress(this.chatControl);
                }
                else {
                    this.profileContents = new ProfileContents(this.chatControl);
                }
                break;
            case 'contact':
                this.profileContact = new ProfileContact(this.chatControl);
                break;
        }
    }
    closeProfileMenu() {
        $(this.chatBotProfileMenu).addClass('ftcb__profile--close');
        $(this.chatBotProfileMenu).removeClass('ftcb__profile--open');
    }
    toggleProfileMenu() {
        if ($(this.chatBotProfileMenu).hasClass('ftcb__profile--close')) {
            this.chatControl.gaConnection.sendGAEvent('perfil', 'ev_perfil', 'perfil');
            $(this.chatBotProfileMenu).removeClass('ftcb__profile--close');
            $(this.chatBotProfileMenu).addClass('ftcb__profile--open');
        }
        else {
            $(this.chatBotProfileMenu).addClass('ftcb__profile--close');
            $(this.chatBotProfileMenu).removeClass('ftcb__profile--open');
        }
    }
}
