import { EnrolmentComponent } from "./EnrolmentComponent";
import { EnrolmentMotivation } from "./EnrolmentMotivation";
import { textTag } from "../TextTag";
export class EnrolmentDigitalSkills {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.template = (texts) => {
            return `
      <div class="ftcb__msg ftcb__msg--is-bot">
        <div class="bot-icon"></div>
        <span class="ftcb__msg__text">${textTag(texts, 'DigitalSkills1')}</span>
      </div>
    `;
        };
        this.templateQuestion = (question, options, texts) => {
            return `
      <div class="ftcb__msg ftcb__msg--is-bot" >
        <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${question.question}
        </span>
      </div>
      <div class="ftcb__msg ftcb__msg--has-btn ftcb__msg--delay" data-delay="2000" >      
        <div class="ftcb__msg--is-bot" >
          <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
              <div class="ftcb__msg__btn ftcb__enrolment__btn__edited">
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-circle">
                    <div class="ftcb__msg__btn-circle-child"></div>
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage" data-answer_type="a" data-payload="${options.a.payload}">${options.a.text}</div>
                </div>
              </div>   
              <div class="ftcb__msg__btn ftcb__enrolment__btn__edited" >
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-circle">
                    <div class="ftcb__msg__btn-circle-child"></div>
                  </div>
                  <div class="ftcb__msg__btn-titlenoimage" data-answer_type="b" data-payload="${options.b.payload}">${options.b.text}</div>
                </div>
              </div>                   
            </div>
          </div>
        </div>
        <span class="ftcb__enrolment__edit">${textTag(texts, 'DigitalSkills2')}</span>
        <div class="ftcb__msg--is-user">
          <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"  style="background-image:url('${this.enrolmentCtrl.user.avatar}');"></div>
          <span class="ftcb__msg__text"></span>
        </div>
      </div>
    `;
        };
        this.sendButtonTemplate = (texts, indexError, indexTotal) => {
            var textoBotton = (indexTotal == 5) ? textTag(texts, 'DigitalSkills6') : textTag(texts, 'DigitalSkills5').replace('X', indexTotal + 1).toString();
            return `
      <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" id="endDigitalSkills${indexError}">
        <div class="ftcb__msg--is-bot">
          <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">              
              <div class="ftcb__msg__btn" >
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-titlenoimage">${textoBotton}</div>
                </div>
              </div>               
            </div>
            <div class="ftcb__enrolment__warning"><span>${textTag(texts, 'DigitalSkills4')}</span></div>  
            <div class="ftcb__enrolment__login-errror"><span id="digitalSkillsError${indexError}">${textTag(texts, 'DigitalSkillsError')}</span></div>  
          </div>
        </div>
      </div>
  `;
        };
        this.motivationSequence = [1, 14, 28];
        this.motivationTextIndex = 1;
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentDigitalSkills', 'ftcb__enrolment__screen');
        this.start = (callback) => {
            var startTime = performance.now();
            var gaIndex = 1;
            var gaSkills = ['Búsqueda y gestión de información', 'Comunicación y colaboración', 'Creación de contenido digital', 'Seguridad en la red', 'Búsqueda de soluciones innovadoras'];
            this.container.setContent(this.template(this.enrolmentCtrl.enrolmentTexts));
            this.container.inject(this.enrolmentCtrl.container.component);
            setTimeout(() => { questionSecuence(0); }, 2000);
            const questionSecuence = (index) => {
                const question = this.enrolmentCtrl.digitalSkillsQuestions[index];
                const options = this.enrolmentCtrl.digitalSkillsOptions[0];
                const questionComponent = new EnrolmentComponent(this.enrolmentCtrl, question.identifier, 'ftcb__enrolment__f1Question');
                questionComponent.setContent(this.templateQuestion(question, options, this.enrolmentCtrl.enrolmentTexts));
                questionComponent.inject(this.container.component);
                if (index === 0) {
                    this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('digitalizacion', 'ev_inicio', 'iniciado');
                }
                let motivation;
                if (this.motivationSequence.indexOf(index + 1) >= 0) {
                    motivation = new EnrolmentMotivation(textTag(this.enrolmentCtrl.enrolmentTexts, `Motivation${this.motivationTextIndex}`), this.container.component, this.enrolmentCtrl);
                    setTimeout(() => { motivation.start(); }, 3500);
                    this.motivationTextIndex = this.motivationTextIndex + 1;
                }
                $(`#${question.identifier} .ftcb__enrolment__btn__edited`).click((event) => {
                    if (motivation) {
                        motivation.stop();
                    }
                    this.enrolmentCtrl.progress.advance();
                    const answer = $(event.currentTarget).find('.ftcb__msg__btn-titlenoimage');
                    const response = answer.data("payload");
                    const answerType = answer.data("answer_type");
                    this.enrolmentCtrl.user.digitalSkills.answers.push({
                        identifier: question.identifier,
                        answerType: answerType
                    });
                    $(`#${question.identifier} .ftcb__msg--has-btn`).addClass("ftcb__msg--answered");
                    $(`#${question.identifier} .ftcb__msg--is-user .ftcb__msg__text`).html(response);
                    this.enrolmentCtrl.scroll();
                    index = index + 1;
                    if ((index < this.enrolmentCtrl.digitalSkillsQuestions.length) && ((index % 8 != 0) || (index === 0))) {
                        questionSecuence(index);
                    }
                    else if ((index < this.enrolmentCtrl.digitalSkillsQuestions.length) && (index % 8 === 0) && (index != 0)) {
                        this.container.component.insertAdjacentHTML('beforeend', this.sendButtonTemplate(this.enrolmentCtrl.enrolmentTexts, index, gaIndex));
                        $(`#endDigitalSkills${index} .ftcb__msg__btn`).click(() => {
                            $('#digitalSkillsError' + index).css('display', 'none');
                            const notAnsweredQ = this.enrolmentCtrl.user.digitalSkills.answers.find((element) => {
                                return element.answerType === null;
                            });
                            if (notAnsweredQ) {
                                $('#digitalSkillsError' + index).css('display', 'block');
                                this.scrollToElement(notAnsweredQ.identifier);
                                return;
                            }
                            var ga_label = gaIndex + '-' + gaSkills[gaIndex - 1];
                            gaIndex = gaIndex + 1;
                            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('digitalizacion', 'ev_completado', ga_label);
                            $('#endDigitalSkills' + index).css('display', 'none');
                            $(`#enrolmentDigitalSkills div`).unbind();
                            $(`#enrolmentDigitalSkills .ftcb__enrolment__edit`).fadeOut();
                            questionSecuence(index);
                        });
                    }
                    else {
                        this.container.component.insertAdjacentHTML('beforeend', this.sendButtonTemplate(this.enrolmentCtrl.enrolmentTexts, index, gaIndex));
                        $(`#endDigitalSkills${index} .ftcb__msg__btn`).click(() => {
                            $('#digitalSkillsError' + index).css('display', 'none');
                            const notAnsweredQ = this.enrolmentCtrl.user.digitalSkills.answers.find((element) => {
                                return element.answerType === null;
                            });
                            if (notAnsweredQ) {
                                $('#digitalSkillsError' + index).css('display', 'block');
                                this.scrollToElement(notAnsweredQ.identifier);
                                return;
                            }
                            var ga_label = gaIndex + '-' + gaSkills[gaIndex - 1];
                            gaIndex = gaIndex + 1;
                            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('digitalizacion', 'ev_completado', ga_label);
                            if (index === this.enrolmentCtrl.digitalSkillsQuestions.length) {
                                var endTime = performance.now();
                                this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('digitalizacion', 'ev_timer', (Math.round((endTime - startTime) / 1000)).toString());
                            }
                            $("#endDigitalSkills" + index).addClass("ftcb__msg--answered");
                            $("#enrolmentDigitalSkills div").unbind();
                            $("#enrolmentDigitalSkills .ftcb__enrolment__edit").fadeOut();
                            callback(null);
                        });
                    }
                });
                $(`#${question.identifier} .ftcb__enrolment__edit`).click(() => {
                    $(`#${question.identifier} .ftcb__msg--has-btn`).removeClass("ftcb__msg--answered");
                    $(`#${question.identifier} .ftcb__msg__btn`).removeClass('ftcb__enrolment__btn__edited');
                    $(`#${question.identifier} .ftcb__msg__btn`).unbind();
                    $(`#${question.identifier} *`).removeClass('ftcb__msg__btn-clicked');
                    $(`#${question.identifier} .ftcb__msg__btn`).addClass('ftcb__enrolment__btn__edited');
                    const skill = this.enrolmentCtrl.user.digitalSkills.answers.find((element) => {
                        return element.identifier === question.identifier;
                    });
                    skill.answerType = null;
                    $(`#${question.identifier} .ftcb__enrolment__btn__edited`).click((event) => {
                        const answer = $(event.currentTarget).find('.ftcb__msg__btn-titlenoimage');
                        const response = answer.data("payload");
                        const answerType = answer.data("answer_type");
                        skill.answerType = answerType;
                        $(`#${question.identifier} .ftcb__msg--has-btn`).addClass("ftcb__msg--answered");
                        $(`#${question.identifier} .ftcb__msg--is-user .ftcb__msg__text`).html(response);
                    });
                });
            };
        };
    }
    ;
    scrollToElement(targetId) {
        var $container = $(this.enrolmentCtrl.container.component), $scrollTo = $(`#${targetId}`);
        $container.scrollTop($scrollTo.offset().top - $container.offset().top + $container.scrollTop());
    }
}
