export class EnrolmentComponent {
    constructor(enrolmentCtrl, id, className) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.setDelay = () => {
            const delayedElement = $(this.component).find('.ftcb__msg--delay');
            for (let i = 0; i < delayedElement.length; i++) {
                const time = $(delayedElement[i]).data('delay');
                setTimeout(() => {
                    $(delayedElement[i]).removeClass('ftcb__msg--delay');
                    this.enrolmentCtrl.scroll();
                }, Number(time));
            }
        };
        this.component = document.createElement("div");
        this.component.id = id;
        this.component.className = className;
    }
    setContent(template) {
        this.component.innerHTML = template;
        this.setDelay();
    }
    inject(parent, scroll) {
        parent.appendChild(this.component);
        if (scroll !== false)
            this.enrolmentCtrl.scroll();
    }
    destroy() {
        this.component.remove();
    }
}
