import { StaticsControl } from "./staticsControl";
import { CourseCard } from "./CourseCard";
import { Rating } from "./Rating";
import { textTag } from "./TextTag";
const styles = require('./../styles/style.scss').toString();
export class Template {
    constructor(chatControl) {
        this.chatControl = chatControl;
        this.icons = {};
        this.language = "";
        this.profileIconLanguage = "";
        this.bodyElement = document.getElementsByTagName("body")[0];
        this.texts = this.chatControl.dictionary.texts.bot;
        const headerDiv = document.getElementById('headerPage');
        if (headerDiv) {
            this.bodyElement = headerDiv;
        }
        this.headerElement = document.getElementsByTagName("head")[0];
        this.injectFontFace();
        this.icons = StaticsControl.statics;
        this.injectStyle();
        this.injectChatWindow();
        if (!document.getElementById("chatAvatar")) {
            this.injectChatAvatar();
        }
    }
    injectFontFace() {
    }
    injectStyle() {
        const st = styles;
        let el = document.createElement('style');
        el.innerHTML = st;
        this.headerElement.appendChild(el);
    }
    injectChatAvatar() {
        let divLottie = document.createElement("div");
        divLottie.id = "chatAvatarLottie";
        divLottie.classList.add('ftcb__avatar__img');
        divLottie.classList.add('ftcb__avatar__img--closed');
        let div = document.createElement("div");
        div.id = "chatAvatar";
        div.classList.add('ftcb__avatar__container');
        div.appendChild(divLottie);
        this.bodyElement.appendChild(div);
    }
    injectChatWindow() {
        this.language = this.chatControl.language;
        if (this.language === 'en-GB') {
            this.profileIconLanguage = this.icons.profileIconEn;
        }
        else {
            this.profileIconLanguage = this.icons.profileIcon;
        }
        const template = `
          <div id="ftcb__container" class="ftcb__container">
            <header class="ftcb__header">
              <div id="headerLottie" class="ftcb__header__icon"></div>
              <img class="ftcb__header__logo" src="${this.icons.orientadorLogo}" alt="header-logo"> 
              <h2>${textTag(this.texts, 'title')}</h2>
              <button id="closeChatWindowButton"
              
                class="ftcb__header__close">
                <img class="ftcb__header__close" src="${this.icons.closeIcon}" alt="close">
              </button>
            </header>
            <div id="chatBotMessageWrapper" class="ftcb__msgwrapper ftcb__msgwrapper--menu-close ftcb__msgwrapper--no-animation">
              <div id="chatBotMsgContainer" class="ftcb__msgcontainer">
                <!-- ####################### Messages ######################### -->
                <!-- ==================== ie11 ========================== -->
                <!-- <div id="ftcb_ie11_push_msg_down" class="ftcb_ie11_push_msg_down"></div> -->
                <!-- ==================== Splash ========================== 
                <div class="ftcb__msg ftcb__msg--is-loading">
                  <div class="ftcb__msg__loading-container">
                    <div class="ftcb__msg__loading-avatar">
                      <img src="" alt="">
                    </div>
                    <div class="ftcb__msg__loading-title"></div>
                    <div class="ftcb__msg__loading-subtitle"></div>
                  </div>
                </div>-->
              </div>
              <div class="ftcb__msgbackdrop"></div>
            </div>
            <div id="chatBotProfileMenu" class="ftcb__profile ftcb__profile--close">
            </div>
            <div class="ftcb__inputcontainer">
                
              <div id="chatBotProfileButton" class="ftcb__inputcontainer__options">
                <div class="ftcb__inputcontainer__image-div">
                  <button class="ftcb__inputcontainer__options__button" id="ftcb__avatar__menu-background" >
                  <img src="${this.profileIconLanguage}" alt="options" id="ftcb__avatar__menu" class="ftcb__inputcontainer__options-icon">
                  <div class="privateArea">${textTag(this.texts, 'privateArea')}</div>
                </button>
                <div style="background: #06f;color: white;height: 10px;width: 10px;border-radius: 100%;position: absolute;top: 5px;right: 10px;padding: 3px;padding-bottom:4px;display: flex;flex-direction: column;justify-content: center;align-items: center;">+</div>
                <!--<img src="${this.icons.profileIconPlus}" alt="plus" class="ftcb__inputcontainer__options-plus">-->
                   <!-- <div style="border-radius:100%; background: white;padding: 5px;height: 50px;display: flex;align-items: center;font-size: .75rem;font-weight: 600;color: #2B3447;">
                      Área privada
                    </div>
                  </button>                  <img src="${this.icons.profileIconPlus}" alt="plus" class="ftcb__inputcontainer__options-plus">
-->
                </div>
			  </div>
              <div class="ftcb__inputcontainer__textfield ftcb__inputcontainer__oldInput">
               
                <input style="display: none"  type="text" class="ftcb__inputcontainer__textfield__input">

                <div id="chatBotInputTextBox" placeholder="${textTag(this.texts, 'placeholder')}" class="ftcb__inputcontainer__textfield__input"  contenteditable="true" onclick='$(this).focus();'></div>
                <div id="chatBotSendContainer" class="ftcb__inputcontainer__send">
                  <button id="chatBotSubmitButton" class="ftcb__inputcontainer__button ftcb__inputcontainer__button--send">
                    <img src="${this.icons.submitIcon}" alt="submit" class="ftcb__inputcontainer__submit-icon">
                  </button>
                </div>
              </div>
              </div>
              <div id="containerChatBotProfileCookies">
                <div id="chatBotProfileCookies" class="">
                  <span class="">
                    ${textTag(this.texts, this.chatControl.isOneTrust ? 'Cookies_text_1' : 'Cookies_text_2')}
                  </span>
                  <div class="">    
                    <div class="ftcb__msg__btn">
                      <div class="ftcb__msg__btn-content btn-cookies_accept">
                        <div class="ftcb__msg__btn-titlenoimage">${textTag(this.texts, 'Cookies_button_accept')}</div>
                      </div>
                    </div>
                    <div class="ftcb__msg__btn">
                      <div class="ftcb__msg__btn-content btn-cookies_decline">
                        <div class="ftcb__msg__btn-titlenoimage">${textTag(this.texts, 'Cookies_button_reject')}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <button id="btn-cookies" class="hide ot-floating-button ot-floating-button__open " style="    border: none!important;
              background: none!important;
              color: #06f!important;
              font-size: 12px!important;
              cursor: pointer!important;">Administrar cookies</button>
          </div>

 `;
        let container = document.createElement("div");
        container.style.display = "none";
        container.id = "chatbotContainer";
        container.className = "ftcb__box";
        container.innerHTML = template;
        this.bodyElement.appendChild(container);
    }
    printMessage(message, animate) {
        let messageHtml = '';
        if (message.type == "USER_MSG") {
            this.removeButtonEvents();
            messageHtml = `
        <div class="ftcb__msg ftcb__msg--is-user">
          <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
          <span class="ftcb__msg__text">${message.text}</span>
        </div>`;
        }
        else if (message.type == "BOT_MSG") {
            let classes = 'ftcb__msg ftcb__msg--is-bot';
            const botMessage = message;
            if (botMessage.order) {
                classes += ' ftcb__msg--is-bot--' + botMessage.order;
            }
            messageHtml = `
        <div class="${classes}">
          <div class="bot-icon"></div>
            <span class="ftcb__msg__text">${message.text}</span>
        </div>`;
        }
        else if (message.type == "BUTTONS_MSG") {
            let buttonsHtml = message.optionButtons.map(btn => `
          <div class="ftcb__msg__btn">
            <div class="ftcb__msg__btn-content">
              <div class="ftcb__msg__btn-titlenoimage">${btn.title}</div>
            </div>
          </div>
        `).join('');
            let answClass = message.answered ? "ftcb__msg--answered" : "";
            let txt = message.answered && message.response ? message.response : "";
            messageHtml = `
        <div id="${message.id}" class="ftcb__msg ftcb__msg--has-btn ${answClass}">
          <div class="ftcb__msg--is-bot">
            <div class="ftcb__msg__cards">
              <div class="ftcb__msg__buttoncontainer">
                ${buttonsHtml}
              </div>
            </div>
          </div>
          <div class="ftcb__msg--is-user">
            <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
            <span class="ftcb__msg__text">${txt}</span>
          </div>
        </div>`;
        }
        else if (message.type == "BUTTONS_IMG_MSG") {
            let buttonsHtml = message.optionButtons.map(btn => `
            <div class="ftcb__msg__btn">
              <div class="ftcb__msg__btn-content">
                <img class="ftcb__msg__btn-icon" src="${btn.image_url}" alt="">
                <div class="ftcb__msg__btn-title">${btn.title}</div>
              </div>
            </div>
          `).join('');
            let answClass = message.answered ? "ftcb__msg--answered" : "";
            let txt = message.answered && message.response ? message.response : "";
            messageHtml = `
          <div id="${message.id}" class="ftcb__msg ftcb__msg--has-btn ${answClass}">
            <div class="ftcb__msg--is-bot">
              <div class="ftcb__msg__cards">
                <div class="ftcb__msg__cardcontainer">
                  ${buttonsHtml}
                </div>
              </div>
            </div>
            <div class="ftcb__msg--is-user">
              <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
              <span class="ftcb__msg__text">${txt}</span>
            </div>
          </div>`;
        }
        else if (message.type == "BUTTONS_LARGE_MSG") {
            let buttonsHtml = message.optionButtons.map(btn => {
                let fontSize = '';
                if (btn.title.length >= 10) {
                    fontSize = `style="font-size:10px; margin-top:2px"`;
                }
                ;
                return `
            <div class="ftcb__msg__btn-large">
              <div class="ftcb__msg__btn-large-content">
                <img class="ftcb__msg__btn-large-icon" src="${btn.image_url}" alt="">
                <div class="ftcb__msg__btn-large-title" ${fontSize} >${btn.title}</div>
              </div>
            </div>
          `;
            }).join('');
            let answClass = message.answered ? "ftcb__msg--answered" : "";
            let txt = message.answered && message.response ? message.response : "";
            messageHtml = `
            <div id="${message.id}" class="ftcb__msg ftcb__msg--has-btn-large ${answClass}">
              <div class="ftcb__msg--is-bot">
                <div class="ftcb__msg__cards">
                  <div class="ftcb__msg__cardcontainer">
                    ${buttonsHtml}
                  </div>
                </div>
              </div>
              <div class="ftcb__msg--is-user">
                <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
                <span class="ftcb__msg__text">${txt}</span>
              </div>
            </div>`;
        }
        else if (message.type == "CALL_TO_ACTION_MSG") {
            let buttonsHtml = message.optionButtons.map(btn => `
              <div class="ftcb__msg__btn-action">
                <div class="ftcb__msg__btn-action-content ftcb__msg__btn-action-content-login">
                  <div class="ftcb__msg__btn-action-title">${btn.title}</div>
                </div>
              </div>
            `).join('');
            messageHtml = `
            <div class="ftcb__msg ftcb__msg--has-btn">
              <div class="ftcb__msg--is-bot">
                <div class="ftcb__msg__cards">
                  <div class="ftcb__msg__cardcontainer">
                    ${buttonsHtml}
                  </div>
                </div>
              </div>
            </div>`;
        }
        else if (message.type == "CARDS_MSG") {
            this.processCardsMsg(message);
        }
        else if (message.type == "VIDEO_MSG") {
            this.processVideoMsg(message);
        }
        else if (message.type == "IMG_MSG") {
            this.processImgMsg(message);
        }
        else if (message.type == "INTRO_ENROLMENT") {
            this.processIntroEnrolment(message);
        }
        else if (message.type == "GRAPH_MSG") {
            this.processftcb_graphic(message);
        }
        else if (message.type == "GRAPH_DIGITAL_SKILLS_MSG") {
            this.processDigitalSkills_graphic(message);
        }
        else if (message.type == "GRAPH_SOFT_SKILLS_MSG") {
            this.processSoftSkills_graphic(message);
        }
        else if (message.type == "CARD_COURSE_MSG") {
            new CourseCard(this.chatControl, message);
        }
        else if (message.type == "RATING_MSG") {
            new Rating(this.chatControl, message);
        }
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
        let buttonGroup = container.querySelector("div.ftcb__msg:last-child");
        if (message.type == "BUTTONS_MSG" || message.type == "BUTTONS_IMG_MSG") {
            this.activeButtons = container.querySelectorAll(".ftcb__msg--has-btn:last-child .ftcb__msg__btn");
            this.activeButtonEvent = this.addEventsToDivs(message, this.activeButtons, buttonGroup);
        }
        else if (message.type == "BUTTONS_LARGE_MSG") {
            this.activeButtons = container.querySelectorAll(".ftcb__msg--has-btn-large:last-child .ftcb__msg__btn-large");
            this.activeButtonEvent = this.addEventsToDivs(message, this.activeButtons, buttonGroup);
        }
        else if (message.type == "CALL_TO_ACTION_MSG") {
            this.activeButtons = container.querySelectorAll(".ftcb__msg--has-btn:last-child .ftcb__msg__btn-action");
            this.addEventsToCallToActonDivs(message, this.activeButtons);
        }
        else if (message.type == "CARDS_MSG") {
        }
        else if (message.type == "IMG_MSG") {
            let img = container.querySelector(".ftcb_msg--is-image:last-child img");
            img.addEventListener("click", (event) => {
                event.stopPropagation();
                event.preventDefault();
                let divFull = document.createElement("div");
                divFull.className = "ftcb__fullscreen__container";
                let imgClose = document.createElement("img");
                imgClose.className = "ftcb_fullscreen_close_icon";
                imgClose.src = this.icons.closeIcon;
                divFull.appendChild(imgClose);
                let divImgFondo = document.createElement("div");
                divImgFondo.className = "ftcb__background__image";
                divImgFondo.style.backgroundImage = `url("${message.image_url}")`;
                divFull.appendChild(divImgFondo);
                document.querySelector("body").appendChild(divFull);
                divFull.addEventListener("click", (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    document.querySelector("body").removeChild(divFull);
                });
            });
        }
        let aes = container.querySelectorAll("div.ftcb__msg:last-child a");
        for (let i = 0; i < aes.length; i++) {
            let href = aes[i].href;
            aes[i].target = "_blank";
            aes[i].className = "ftcb__msg__link";
            aes[i].addEventListener("click", (event) => {
            }, false);
        }
        if (message.type == "BOT_MSG") {
            let imgs = container.querySelectorAll("div.ftcb__msg:last-child span.ftcb__msg__text img");
            for (let i = 0; i < imgs.length; i++) {
                if (!imgs[i].classList.contains('ftcb__msg__emoji')) {
                    imgs[i].classList.add('ftcb__msg__emoji');
                }
            }
        }
        this.scroll();
    }
    addEventsToDivs(message, activeButtons, buttonGroup) {
        for (let i = 0; i < message.optionButtons.length; i++) {
            let button = activeButtons[i];
            button.botMsg = message;
            button.payload = message.optionButtons[i].payload;
            button.response = message.optionButtons[i].response ? message.optionButtons[i].response : message.optionButtons[i].payload;
            button.buttonGroup = buttonGroup;
            button.addEventListener("click", (event) => this.buttonAction(event));
        }
        return (event) => this.buttonAction(event);
    }
    buttonAction(event) {
        let button = event.currentTarget;
        let userMsg = button.buttonGroup.querySelector("div.ftcb__msg--is-user span.ftcb__msg__text");
        let userBlock = button.buttonGroup.querySelector("div.ftcb__msg--is-user");
        userMsg.innerHTML = button.response;
        button.buttonGroup.classList.add("ftcb__msg--answered");
        button.botMsg.answered = true;
        button.botMsg.response = button.response;
        this.chatControl.sendUserButtonMessage(button.payload);
    }
    removeButtonEvents() {
        if (this.activeButtons) {
            for (let i = 0; i < this.activeButtons.length; i++) {
                this.activeButtons[i].removeEventListener("click", this.activeButtonEvent);
            }
            this.activeButtons = undefined;
        }
    }
    addEventsToCallToActonDivs(message, activeButtons) {
        for (let i = 0; i < message.optionButtons.length; i++) {
            let button = activeButtons[i];
            button.url = message.optionButtons[i].url;
            button.addEventListener("click", (event) => {
                window.open(event.currentTarget.url, '_blank');
            });
        }
        return (event) => this.buttonAction(event);
    }
    scroll() {
        const msgWrapper = document.querySelector('div#chatBotMessageWrapper');
        msgWrapper.scroll({ top: msgWrapper.scrollHeight, left: 0, behavior: 'smooth' });
        setTimeout(function () {
            msgWrapper.scroll({ top: msgWrapper.scrollHeight, left: 0, behavior: 'smooth' });
        }, 1700);
    }
    processUrl(url) {
        const urlArray = url.split('/');
        const code = urlArray[urlArray.length - 1];
        const youtubeUrl = `https://www.youtube.com/embed/${code}`;
        return youtubeUrl;
    }
    ;
    processVideoMsg(botMsg) {
        const url = this.processUrl(botMsg.video_url);
        let messageHtml = `
    <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <div class="ftcb__msg__videocontainer">
          <iframe class="ftcb__msg__videoplayer" src="${url}"></iframe>
        </div>
    </div>`;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
    }
    processCardButtons(buttons) {
        return buttons.map((button) => {
            if (button.type == 'call_to_action') {
                return `
        <div class="ftcb__msg__btn-action" data-url="${button.url}">
          <div class="ftcb__msg__btn-action-content">
            <div class="ftcb__msg__btn-action-title">${button.title}</div>
          </div>
        </div>
        `;
            }
            else {
                return `
        <div class="ftcb__msg__btn" data-payload="${button.payload}">
          <div class="ftcb__msg__btn-content">
            <div class="ftcb__msg__btn-titlenoimage" >${button.title}</div>
          </div>
        </div>
      `;
            }
        }).join('');
    }
    processCardsMsg(botMsg) {
        let cardsHtml = "";
        for (let i = 0; i < botMsg.carousel.length; i++) {
            const card = botMsg.carousel[i];
            const buttons = this.processCardButtons(card.buttons);
            let d = i * 0.5;
            cardsHtml += `
        <div class="ftcb__msg__card" style="transition-delay: ${d}s;-webkit-transition-delay: ${d}s;">
          <div class="ftcb__msg__card__seemore-title ${card.image_url ? 'ftcb__empty' : ''}">${card.title}</div>
          <img class="ftcb__msg__card-image ${!card.image_url ? 'ftcb__empty' : ''}" src="${card.image_url || ''}">
          <div class="ftcb__msg__card-content">
            <div class="ftcb__msg__card-title" title="${card.title}">${card.image_url ? card.title : ''}</div>
            <div class="ftcb__msg__card-text">${card.description}</div>
            <div class="ftcb__msg__card-buttons">${buttons}</div>
          </div>
        </div>`;
        }
        let answClass = botMsg.answered ? "ftcb__msg--answered" : "";
        let txt = botMsg.answered && botMsg.response ? botMsg.response : "";
        const scrollButtons = (botMsg.carousel.length <= 2) ? '' :
            `<div class="ftcb__carousel__control ftcb__carousel__control--right" data-direction="right">
        <img class="ftcb__carousel__arrow" src="${this.icons.arrow}" alt="">
      </div>
      <div class="ftcb__carousel__control ftcb__carousel__control--left" data-direction="left">
      <img class="ftcb__carousel__arrow" src="${this.icons.arrow}" alt="">
      </div>`;
        let messageHtml = `
      <div id="${botMsg.id}" class="ftcb__msg ftcb__msg--has-cards ${answClass}">
        <div class="ftcb__msg--is-bot">
          <div class="ftcb__msg__cards">
              ${scrollButtons}
            <div class="ftcb__msg__cardcontainer">
              ${cardsHtml}
            </div>
          </div>
        </div>
        <div class="ftcb__msg--is-user">
          <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
          <span class="ftcb__msg__text">${txt}</span>
        </div>
      </div>`;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
        if ((botMsg.carousel.length * 200) < $('#chatBotMsgContainer').width()) {
            $(`#${botMsg.id} .ftcb__carousel__control--right`).hide();
        }
        $(`#${botMsg.id}`).width($('#chatBotMsgContainer').width() - 20);
        this.bindCardButtons();
        if (botMsg.carousel.length > 2) {
            this.bindScrollButtons();
        }
    }
    bindCardButtons() {
        this.unbindCardButtons();
        $('.ftcb__msg__card-buttons .ftcb__msg__btn-action').click((evt) => {
            window.open($(evt.currentTarget).data("url"), '_blank');
        });
        $('.ftcb__msg__card-buttons .ftcb__msg__btn').click((evt) => {
            this.chatControl.sendUserButtonMessage($(evt.currentTarget).data("payload"));
        });
    }
    unbindCardButtons() {
        $('.ftcb__msg__card-buttons > div').unbind();
    }
    bindScrollButtons() {
        this.unbindScrollButtons();
        $('.ftcb__carousel__control').click((evt) => {
            const direction = $(evt.currentTarget).data("direction");
            const animationTime = 500;
            const animationLength = 220;
            const animationDirection = (direction == 'right') ? '+' : '-';
            const $parent = $(evt.currentTarget).parent();
            const $element = $parent.children(".ftcb__msg__cardcontainer");
            const elemWidth = $element[0].scrollWidth;
            $element.stop().animate({
                scrollLeft: `${animationDirection}=${animationLength}`
            }, animationTime);
            event.preventDefault();
            setTimeout(function () {
                if ($element.scrollLeft()) {
                    $parent.find('.ftcb__carousel__control--left').show();
                }
                else {
                    $parent.find('.ftcb__carousel__control--left').hide();
                }
                if ($element.scrollLeft() > elemWidth - $('#chatBotMsgContainer').width()) {
                    $parent.find('.ftcb__carousel__control--right').hide();
                }
                else {
                    $parent.find('.ftcb__carousel__control--right').show();
                }
            }, 520);
        });
    }
    unbindScrollButtons() {
        $('.ftcb__carousel__control').unbind();
    }
    processImgMsg(botMsg) {
        let messageHtml = `
    <div class="ftcb__msg ftcb__msg--is-bot ftcb_msg--is-image">
      
      <div class="ftcb__msg__imagecontainer">` +
            `
        <img class="ftcb__msg__image" src="${botMsg.image_url}"/>
      </div>
    </div>`;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
    }
    processIntroEnrolment(botMsg) {
        let classes = 'ftcb__msg ftcb__msg--is-bot';
        if (botMsg.order) {
            classes += ' ftcb__msg--is-bot--' + botMsg.order;
        }
        let messageHtml = `
        <div class="${classes}">
            <div class="bot-icon"></div>
            <div class="ftcb__msg__text">
              ${botMsg.text}
              <div class="ftcb__msg__enrolment_option">
                <div class="ftcb__msg__enrolment_option-img"> <img src="${this.icons.enrolmentOption1}" alt="enrolment-option"></div>
                <div class="ftcb__msg__enrolment_option-txt">${botMsg.enrolmentOptions[0]}</div>
              </div>
              <div class="ftcb__msg__enrolment_option">
                <div class="ftcb__msg__enrolment_option-img"><img src="${this.icons.enrolmentOption2}" alt="enrolment-option"></div>
                <div class="ftcb__msg__enrolment_option-txt">${botMsg.enrolmentOptions[1]}</div>
              </div>
            </div>
        </div>`;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
    }
    processftcb_graphic(graphMsg) {
        let classes = 'ftcb__msg ftcb__msg--is-bot';
        if (graphMsg.order) {
            classes += ' ftcb__msg--is-bot--' + graphMsg.order;
        }
        let barSkill = "";
        let index = 0;
        for (index; index < graphMsg.skills.length; index++) {
            barSkill +=
                `<div class="ftcb_graphic ftcb_graphic-${index + 1}" data-payload="${graphMsg.skills[index].nombre_skill}">
                <p>${graphMsg.skills[index].nombre_skill}</p>
                <div class="ftcb_graphic_empty-barr">
                  <div class="ftcb_graphic_color-barr">
                      <div class="ftcb_graphic_color-barr_number-barr">
                          <span>${graphMsg.skills[index].num_ocurrencias}</span>
                      </div>
                  </div>
                </div>
              </div>`;
        }
        let messageHtml = `
        <div class="${classes}">
          <!-- <div class="bot-icon"></div>
          <div class="ftcb__msg__text ftcb__msg__graphyc"> -->
          <div class="main">
            <div id="ftcb_graphic">
              ${barSkill}
            </div>
          </main>
        <!-- </div> -->
      </div>
    </div>`;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
        for (let i = 0; i < graphMsg.skills.length; i++) {
            let porcentaje = 100;
            let num = parseInt(graphMsg.skills[0].num_ocurrencias, 10);
            let pos = parseInt(graphMsg.skills[i].num_ocurrencias, 10);
            porcentaje = (100 * pos) / num;
            setTimeout(() => $(`.ftcb_graphic-${i + 1}`).find(".ftcb_graphic_color-barr").css("width", `${porcentaje}%`));
        }
        $(".ftcb_graphic").click((evt) => {
            this.chatControl.sendUserMessage($(evt.currentTarget).data("payload"));
        });
    }
    processDigitalSkills_graphic(graphMsg) {
        let classes = 'ftcb__msg ftcb__msg--is-bot';
        if (graphMsg.order) {
            classes += ' ftcb__msg--is-bot--' + graphMsg.order;
        }
        let barSkill = "";
        let index = 0;
        let uid = Date.now();
        for (index; index < graphMsg.skills.length; index++) {
            barSkill +=
                `<div class="ftcb_graphic ftcb_graphic-${index + 1}" data-payload="${graphMsg.skills[index].nombre_skill}"
            data-score="${graphMsg.skills[index].num_ocurrencias}">
                <p>${graphMsg.skills[index].nombre_skill}</p>
                <div class="ftcb_graphic_empty-barr">
                  <div class="ftcb_graphic_color-barr">
                      <div class="ftcb_graphic_color-barr_number-barr">
                          <span>${graphMsg.skills[index].num_ocurrencias}%</span>
                      </div>
                  </div>
                </div>
              </div>`;
        }
        let messageHtml = `
        <div class="${classes}">
          <!-- <div class="bot-icon"></div>
          <div class="ftcb__msg__text ftcb__msg__graphyc"> -->
          <div id="${uid}" class="main">
            <div id="ftcb_graphic">
              ${barSkill}
            </div>
          </main>
        <!-- </div> -->
      </div>
    </div>`;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
        graphMsg.skills.forEach((skill, i) => {
            let $actual = $(`#${uid} .ftcb_graphic-${i + 1}`);
            if (skill.num_ocurrencias === '0') {
                $actual.last().find(`#${uid} .ftcb_graphic_color-barr`).css('background-color', 'transparent');
                $actual.last().find(`#${uid} .ftcb_graphic_color-barr_number-barr`).css({ "background-color": "transparent", "color": "#0e0e0e" });
            }
            setTimeout(() => $actual.find(`.ftcb_graphic_color-barr`).css("width", `${skill.num_ocurrencias}%`));
        });
        $(".ftcb_graphic").click((evt) => {
            this.chatControl.sendUserMessage($(evt.currentTarget).data("payload"));
        });
    }
    processSoftSkills_graphic(graphMsg) {
        let classes = 'ftcb__msg ftcb__msg--is-bot';
        if (graphMsg.order) {
            classes += ' ftcb__msg--is-bot--' + graphMsg.order;
        }
        const max = Math.max(...graphMsg.skills.map(o => Number(o.num_ocurrencias)));
        const min = Math.min(...graphMsg.skills.map(o => Number(o.num_ocurrencias)));
        const getPercent = (min, max) => (value) => 100 * (value - min) / (max - min);
        let barSkill = "";
        let index = 0;
        let uid = Date.now();
        for (index; index < graphMsg.skills.length; index++) {
            if (graphMsg.skills[index].num_ocurrencias == '0')
                continue;
            barSkill +=
                `<div class="ftcb_graphic ftcb_graphic-${index + 1}" data-payload="${graphMsg.skills[index].nombre_skill}"
            data-score="${Math.round(getPercent(min, max)(Number(graphMsg.skills[index].num_ocurrencias)))}">
                <p>${graphMsg.skills[index].nombre_skill}</p>
                <div class="ftcb_graphic_empty-barr">
                  <div class="ftcb_graphic_color-barr">
                      <div class="ftcb_graphic_color-barr_number-barr">
                          <span>${graphMsg.skills[index].num_ocurrencias}</span>
                      </div>
                  </div>
                </div>
              </div>`;
        }
        let messageHtml = `
        <div class="${classes}">
          <!-- <div class="bot-icon"></div>
          <div class="ftcb__msg__text ftcb__msg__graphyc"> -->
          <div id="${uid}" class="main">
            <div id="ftcb_graphic">
              ${barSkill}
            </div>
          </main>
        <!-- </div> -->
      </div>
    </div>`;
        let msg = `
    <div class="ftcb__msg ftcb__msg--is-bot">
    <div class="bot-icon"></div>
      <span class="ftcb__msg__text">${graphMsg.skills.map(s => s.nombre_skill + '<br>').toString().split(',').join('')}</span>
  </div>
    `;
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.insertAdjacentHTML('beforeend', messageHtml);
        graphMsg.skills.forEach((skill, i) => {
            let $actual = $(`#${uid} .ftcb_graphic-${i + 1}`);
            if (skill.num_ocurrencias === '0') {
                $actual.last().find(`#${uid} .ftcb_graphic_color-barr`).css('background-color', 'transparent');
                $actual.last().find(`#${uid} .ftcb_graphic_color-barr_number-barr`).css({ "background-color": "transparent", "color": "#0e0e0e" });
            }
            setTimeout(() => $actual.find(`.ftcb_graphic_color-barr`).css("width", `${$actual.data("score")}%`));
        });
        $(".ftcb_graphic").click((evt) => {
            this.chatControl.sendUserMessage($(evt.currentTarget).data("payload"));
        });
    }
    clearMessagesArea() {
        let container = document.querySelector("div#chatbotContainer div.ftcb__msgcontainer");
        container.innerHTML = "";
    }
}
