import { EnrolmentComponent } from "./EnrolmentComponent";
import { textTag } from "../TextTag";
import { CaptchaComponent } from "./CaptchaComponent";
export class LoginRecover {
    constructor(loginCtrl) {
        this.loginCtrl = loginCtrl;
        this.template = () => {
            const texts = this.loginCtrl.enrolmentTexts;
            return `
    <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${textTag(texts, 'ChangePass1')}
        </span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn" id="formRecover">
      <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="2000">
        <div class="ftcb__msg__cards">
          <div class="ftcb__msg__buttoncontainer">
            
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <input type="email" name="email"  class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry2')}" onclick="$(this).focus();">             
            </div>   
            <div class="ftcb__enrolment__login-errror"><span>${textTag(texts, 'ChangePass2')}</span></div>
            <div id="${this.captchaID}" style="margin-bottom: 2rem"></div>
          </div>
        </div>

      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" >
    <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="4000">
      <div class="ftcb__msg__cards">
        <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
          
          <div class="ftcb__msg__btn" id="getCode">
            <div class="ftcb__msg__btn-content">
              <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, 'SoftSkills3')}</div>
            </div>
          </div>   
          <div class="ftcb__msg__btn" id="cancelCode">
          <div class="ftcb__msg__btn-content">
            <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, 'ChangePass11')}</div>
          </div>
        </div>
        </div>
      </div>
    </div>
    `;
        };
        this.container = new EnrolmentComponent(this.loginCtrl, 'enrolmentRecover', 'ftcb__enrolment__screen');
        this.start = (callback) => {
            this.loginCtrl.chatControl.gaConnection.sendGAEvent('inicio', 'ev_recuperar_contraseña', 'iniciado');
            this.container.setContent(this.template());
            this.container.inject(this.loginCtrl.container.component);
            const recaptcha = new CaptchaComponent();
            recaptcha.render('#' + this.captchaID);
            $("#getCode").click(() => {
                $('input, #formRecover div').removeClass('ftcb__enrolment__btn_err');
                $('.ftcb__enrolment__login-errror span').hide();
                this.loginCtrl.user.mail = $('#formRecover input[name ="email"]').val() + '';
                if (!recaptcha.isValid())
                    return recaptcha.warning(true);
                recaptcha.warning(false);
                this.loginCtrl.user.captcha = recaptcha.getToken();
                if (!this.loginCtrl.user.mail) {
                    $('#formRecover input[name ="email"]').addClass('ftcb__enrolment__btn_err');
                    return;
                }
                else {
                    this.loginCtrl.services.getcode((err, resp) => {
                        this.loginCtrl.startChPassword(callback);
                    });
                }
            });
            $("#cancelCode").click(() => {
                this.loginCtrl.stopRecover();
            });
        };
        this.captchaID = Date.now();
    }
    ;
}
