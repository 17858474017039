import { PopUp } from "../PopUpScreen";
import { StaticsControl } from "../staticsControl";
import { textTag } from "../TextTag";
import { ProfileContentsContainer } from "./ProfileContentsContainer";
export class ProfileContents {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.icons = StaticsControl.statics;
        this.template = `
    <div class="ftcb__profile__popUp__header">
        <div class="ftcb__profile__popUp__header-image">  
          <img src="${this.icons.profileOptionIcon5}">
        </div>
      <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Content')}</div>
    </div>
  `;
        this.profileServices = this.chatCtrl.profileServices;
        this.profileServices.getContents((err, response) => {
            this.contents = response;
            this.contentsContainer = new ProfileContentsContainer(this);
            this.template = this.template + this.contentsContainer.template;
            this.popUp = new PopUp('profileContents', this.template);
            this.bind();
        });
    }
    ;
    bind() {
        this.contentsContainer.bind();
    }
}
