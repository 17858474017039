export class ProfileSkillsContainer {
    constructor(profileSkills) {
        this.profileSkills = profileSkills;
        this.optionTemplate = (option) => {
            return `
    <div class="ftcb__msg__btn ftcb__profile__skill ftcb__profile__skill--selected" data-identifier="${option.identifier}">
      <div class="ftcb__msg__btn-content">
        <div class="">${option.name}</div>
      </div>
    </div>  
    `;
        };
        this.templateGenerator = () => `
    <div class="ftcb__profile__skills">
      <div class="ftcb_profile-switchPage_hard_skills ftcb__profile__skills-cloud ftcb_profile-switchPage">
        <div>${this.hardSkillsTemplate}</div>
      </div>
      <div class="ftcb_profile-switchPage_soft_skills ftcb__profile__skills-cloud ftcb__profile__skills-cloud-soft_skills ftcb_profile-switchPage">
        <div>${this.softSkillsTemplate}</div>
      </div>
      <div class="ftcb_profile-switchPage_digital_skills  ftcb_profile-switchPage">
        <div class="main"> 
          <div id="ftcb_graphic">${this.digitalSkillsTemplate}</div>
        </div>
      </div>
    </div>
  `;
        this.graphicTemplate = (digitalSkills) => {
            var barSkill;
            digitalSkills.sort((a, b) => a.skill_sort - b.skill_sort);
            digitalSkills.forEach((skill, i) => {
                let id_skill, score_skill;
                if (skill.identifier.includes('|')) {
                    id_skill = skill.identifier.split('|')[0];
                    score_skill = skill.identifier.split('|')[1] === "" ? '0' : skill.identifier.split('|')[1];
                }
                else {
                    id_skill = skill.identifier;
                    score_skill = '0';
                }
                barSkill +=
                    `<div class="ftcb_graphic ftcb_graphic-${i + 1}" data-payload="${skill.name}"
            data-score="${score_skill}">
                <p>${skill.name}</p>
                <div class="ftcb_graphic_empty-barr">
                  <div class="ftcb_graphic_color-barr">
                      <div class="ftcb_graphic_color-barr_number-barr">
                          <span>${score_skill}%</span>
                      </div>
                  </div>
                </div>
              </div>`;
            });
            return barSkill;
        };
        this.hardSkillsTemplate = '';
        this.softSkillsTemplate = '';
        this.digitalSkillsTemplate = '';
        this.profileSkills.userInfo.hard_skills.forEach((item) => {
            this.hardSkillsTemplate += this.optionTemplate(item);
        });
        this.profileSkills.userInfo.soft_skills.forEach((item) => {
            this.softSkillsTemplate += this.optionTemplate(item);
        });
        this.digitalSkillsTemplate = this.graphicTemplate(this.profileSkills.userInfo.digital_skills);
        this.template = this.templateGenerator();
    }
    appendSkill(skill) {
        const container = (skill.type) ?
            $(".ftcb_profile-switchPage_hard_skills > div") :
            $(".ftcb_profile-switchPage_soft_skills > div");
        const template = this.optionTemplate(skill);
        container.append(template);
        $(".ftcb__msg__buttoncontainersave").children().removeClass("disabled");
        this.bind();
    }
    bind() {
        $(".ftcb__profile__skills .ftcb__profile__skill").unbind();
        $(".ftcb__profile__skills .ftcb__profile__skill--selected").click((event) => {
            $(event.currentTarget).removeClass("ftcb__profile__skill--selected");
            $(event.currentTarget).addClass("ftcb__profile__skill--unselected");
            const targetSkillId = $(event.currentTarget).data('identifier');
            $(".ftcb__msg__buttoncontainersave").children().removeClass("disabled");
            this.profileSkills.removeSkillFromList(targetSkillId);
            this.bind();
        });
        $(".ftcb__profile__skills .ftcb__profile__skill--unselected").click((event) => {
            $(event.currentTarget).addClass("ftcb__profile__skill--selected");
            $(event.currentTarget).removeClass("ftcb__profile__skill--unselected");
            if (!$(event.currentTarget).parent().children().hasClass("ftcb__profile__skill--unselected"))
                $(".ftcb__msg__buttoncontainersave").children().addClass("disabled");
            const targetSkillId = $(event.currentTarget).data('identifier');
            this.profileSkills.addSkillToList(targetSkillId);
            this.bind();
        });
    }
}
