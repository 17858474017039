import { EnrolmentComponent } from "./EnrolmentComponent";
export class EnrolmentProgress {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.steps = 12;
        this.partial = 100 / this.steps;
        this.stepsDigital = 40;
        this.partialDigital = 100 / this.stepsDigital;
        this.percentage = 0;
        this.template = () => {
            return `
    <div class="ftcb__enrolment_progress_bar">
      <div class="ftcb__enrolment_progress_bar_fill"></div>
    </div>
   `;
        };
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentProgress', 'ftcb__enrolment_progress');
        this.start = (callback) => {
            this.container.setContent(this.template());
            this.container.inject(this.enrolmentCtrl.enrolmentParent);
            callback(null);
        };
        this.forcedFilling = (callback) => {
            this.percentage = 100;
            $('.ftcb__enrolment_progress_bar_fill').css('height', `${this.percentage}%`);
            callback(null);
        };
    }
    ;
    advance() {
        let isDigitalTest = this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test_with_enrolment' || this.enrolmentCtrl.chatControl.currentPayloadResponse === 'start_digital_skills_test' ? true : false;
        this.percentage = this.percentage + (isDigitalTest ? this.partialDigital : this.partial);
        $('.ftcb__enrolment_progress_bar_fill').css('height', `${this.percentage}%`);
    }
}
