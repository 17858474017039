import { EnrolmentComponent } from "./EnrolmentComponent";
import { textTag } from "../TextTag";
export class EnrolmentError {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.template = () => {
            return `
    <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${this.enrolmentCtrl.enrolmentTexts.length ? textTag(this.enrolmentCtrl.enrolmentTexts, 'Error') : 'Vaya, algún cable se ha desconectado y no puedo recuperar los datos que me pides. Prueba de nuevo en unos minutos 👩🏽‍💻'}
        </span>
      </div>
    </div>
    `;
        };
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentIntro', 'ftcb__enrolment__screen');
        this.start = () => {
            this.container.inject(this.enrolmentCtrl.container.component);
            this.container.setContent(this.template());
        };
    }
    ;
}
