import { ErrorConnectionMsg } from "./ErrorMessages";
export class Text {
}
export class QuestionOption {
}
export class Question {
}
export class Skills {
}
export class DictionaryObj {
}
;
export class Dictionary {
    constructor(chatCtrl, callback) {
        this.chatCtrl = chatCtrl;
        this.getDictionary = (callback) => {
            if (!this.chatCtrl.token)
                return this.chatCtrl.brConnection.getToken(() => { this.getDictionary(callback); });
            const uri = `${HOST_URL}/mw/api/cms/dictionary?locale=${this.chatCtrl.language}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getDictionary(callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send();
        };
        this.getDictionary((err, resp) => {
            if (err)
                return new ErrorConnectionMsg();
            this.texts = resp;
            callback();
        });
    }
    ;
}
