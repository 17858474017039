import { textTag } from "../TextTag";
export class ProfileCareersContainer {
    constructor(profileCareers) {
        this.profileCareers = profileCareers;
        this.skillTemplate = (skill) => {
            const skillIsKnown = this.profileCareers.userSkillsList.includes(skill.id_skill);
            return `
    <div class="ftcb__msg__btn ftcb__profile__skill ${skillIsKnown ? 'ftcb__profile__skill--selected' : ''}" data-payload="Quiero ver cursos de ${skill.nombre_skill}">
      <div class="ftcb__msg__btn-content">
        <div class="">${skill.nombre_skill}</div>
      </div>
    </div>  
    `;
        };
        this.careerTemplate = (profile) => {
            const hardSkills = profile.hard_skills.map((skill) => this.skillTemplate(skill)).join('');
            const softSkills = profile.soft_skills.map((skill) => this.skillTemplate(skill)).join('');
            return `
    <div class="ftcb__profile__course ftcb__cardcourse__container ftcb__profile__course--closed">
      <div class="ftcb__cardcourse__header" data-profilename="${profile.nombre_perfil}"> 
        <div class="ftcb__cardcourse__header__title-image"> 
          <div class="ftcb__cardcourse__image__img"  style="background-image:url(${profile.info.image})"></div>
          <div class="ftcb__cardcourse__tittle">
            ${profile.nombre_career}
          </div>
        </div>
        <div class="ftcb__profile__careers__percentage-container">
          <div class="ftcb__profile__careers__percentage-container-bar">
            <div class="ftcb__profile__careers__percentage-container-bar ftcb__profile__careers__percentage-container-greenbar" style="width:${profile.percentage}%">
              ${profile.percentage}%
            </div>
          </div>
        </div>
      </div>
      <div class="ftcb__cardcourse__body">
        <div class="ftcb__cardcourse__description">
          <p>${textTag(this.texts, 'CareersDescription')}</p>
        </div>
        <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud">
          <div>
            ${hardSkills}
          </div>     
        </div>
        <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud ftcb__profile__skills-cloud-soft_skills">
          <div>
            ${softSkills}
          </div>     
        </div>
        <div class="ftcb__cardcourse__description">
          <p>${textTag(this.texts, 'CareersFooter')}</p>
        </div>
      </div>    
    </div>  
    `;
        };
        this.templateGenerator = (careers) => {
            const careersTemplates = careers.map((career) => this.careerTemplate(career));
            return `
      <div class="ftcb__profile__careers">
        <div class="ftcb__profile__careers-headertext">
          ${textTag(this.texts, 'CareersHeader')}
        </div>
        <div class="ftcb__profile__careers-container">     
          ${careersTemplates.join('')} 
        </div>
      </div>
    `;
        };
        this.texts = this.profileCareers.chatCtrl.dictionary.texts.profile;
        this.template = this.templateGenerator(this.profileCareers.userCareers);
    }
    bind() {
        $(".ftcb__profile__careers .ftcb__profile__skill").click((evt) => {
            this.profileCareers.chatCtrl.sendUserMessage($(evt.currentTarget).data("payload"));
            this.profileCareers.popUp.close();
        });
    }
}
