const config = require("./../../../config.js");
const jsrsasign = require('jsrsasign');
export class EnrolmentServices {
    constructor(enrolmentCtrl) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.getHardSkillsService = (params, callback) => {
            const query = `?soft_skills=${params.softSkills}&field_skills=${params.hardSkills}&locale=${this.enrolmentCtrl.chatControl.language}`;
            const uri = `${HOST_URL}/mw/api/enrolment/hard_skills${query}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.enrolmentCtrl.chatControl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 401) {
                        this.enrolmentCtrl.chatControl.brConnection.getToken(() => { this.getHardSkillsService(params, callback); });
                    }
                    else {
                        callback('error');
                    }
                }
            };
            req.send();
        };
        this.getHardSkills = (callback) => {
            const params = {
                softSkills: this.enrolmentCtrl.user.softSkills.skills.map((item) => item.identifier).join(','),
                hardSkills: this.enrolmentCtrl.user.fieldSkills.map((item) => item.identifier).join(',')
            };
            this.getHardSkillsService(params, (err, response) => {
                if (err)
                    callback(err);
                this.enrolmentCtrl.hardSkillsOptions = response;
                callback(null);
            });
        };
        this.getSoftSkills = (callback) => {
            const answers = this.enrolmentCtrl.user.softSkills.answers.map((item) => item.answerType).join(',');
            const uri = `${HOST_URL}/mw/api/enrolment/soft_skills_by_answers?answers=${answers}&locale=${this.enrolmentCtrl.chatControl.language}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.enrolmentCtrl.chatControl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        this.enrolmentCtrl.user.softSkills.skills = JSON.parse(req.response).data;
                        callback(null);
                    }
                    else if (req.status == 401) {
                        this.enrolmentCtrl.chatControl.brConnection.getToken(() => { this.getSoftSkills(callback); });
                    }
                    else {
                        callback('error');
                    }
                }
            };
            req.send();
        };
        this.registry = (callback) => {
            const user = this.enrolmentCtrl.user;
            const uri = `${HOST_URL}/mw/signup`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        this.enrolmentCtrl.chatControl.token = JSON.parse(req.response).data.token;
                        const dataUser = jsrsasign.KJUR.jws.JWS.readSafeJSONString(jsrsasign.b64utoutf8(this.enrolmentCtrl.chatControl.token.split('.')[1]));
                        callback(null, dataUser);
                    }
                    else if (req.status == 409) {
                        callback(409);
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send(JSON.stringify(user));
        };
        this.login = (callback) => {
            const user = this.enrolmentCtrl.user;
            const uri = `${HOST_URL}/mw/signin`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        this.enrolmentCtrl.chatControl.token = JSON.parse(req.response).data.token;
                        const dataUser = jsrsasign.KJUR.jws.JWS.readSafeJSONString(jsrsasign.b64utoutf8(this.enrolmentCtrl.chatControl.token.split('.')[1]));
                        callback(null, dataUser);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send(JSON.stringify(user));
        };
        this.getcode = (callback) => {
            const data = { mail: this.enrolmentCtrl.user.mail, captcha: this.enrolmentCtrl.user.captcha };
            const uri = `${HOST_URL}/mw/getcode?locale=${this.enrolmentCtrl.chatControl.language}`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
        this.chpass = (data, callback) => {
            const uri = `${HOST_URL}/mw/chpass`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.enrolmentCtrl.executeError();
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
    }
    ;
}
