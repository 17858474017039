import { EnrolmentComponent } from "./EnrolmentComponent";
import { textTag } from "../TextTag";
import { CaptchaComponent } from "./CaptchaComponent";
export class LoginChangePass {
    constructor(loginCtrl) {
        this.loginCtrl = loginCtrl;
        this.template = () => {
            const texts = this.loginCtrl.enrolmentTexts;
            return `
   <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
          ${textTag(texts, 'ChangePass3')}
        </span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn" >
      <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="2000">
        <div class="ftcb__msg__cards">
          <div class="ftcb__msg__buttoncontainer">
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <input type="text" class="ftcb__inputcontainer__textfield__input"  autocomplete="off" placeholder="${textTag(texts, 'ChangePass4')}" onclick="$(this).focus();">             
            </div>   
            <div id="chPassError1" class="ftcb__enrolment__login-errror"><span>${textTag(texts, 'ChangePass12')}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--is-bot ftcb__msg--delay" data-delay="4000">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
        ${textTag(texts, 'ChangePass7')}
        </span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn" >
      <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="6000">
        <div class="ftcb__msg__cards">
          <div class="ftcb__msg__buttoncontainer">
            
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <input type="password" name="password" class="ftcb__inputcontainer__textfield__input"  autocomplete="off" placeholder="${textTag(texts, 'ChangePass8')}" onclick="$(this).focus();">             
            </div>   
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <input type="password" name="repeatPassword" class="ftcb__inputcontainer__textfield__input"  autocomplete="off" placeholder="${textTag(texts, 'ChangePass9')}" onclick="$(this).focus();">             
            </div> 
            <div id="chPassError2" class="ftcb__enrolment__login-errror"><span>${textTag(texts, 'ChangePass10')}</span></div>
            <div id="chPassError3" class="ftcb__enrolment__login-errror"><span>${textTag(texts, 'ChangePass13')}</span></div>
            <div id="${this.captchaID}"></div>
            </div>
        </div>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" id="endChPass">
    <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="8000">
      <div class="ftcb__msg__cards">
        <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
          
          <div class="ftcb__msg__btn">
            <div class="ftcb__msg__btn-content">
              <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, 'SoftSkills3')}</div>
            </div>
          </div>   
          <div class="ftcb__enrolment__warning ftcb__enrolment__warning-clickable"><span>${textTag(texts, 'ChangePass5')}</span></div>  
        </div>
      </div>
    </div>
    `;
        };
        this.container = new EnrolmentComponent(this.loginCtrl, 'enrolmentChPass', 'ftcb__enrolment__screen');
        this.start = (callback) => {
            this.container.setContent(this.template());
            this.container.inject(this.loginCtrl.container.component);
            let recaptcha = new CaptchaComponent();
            recaptcha.render('#' + this.captchaID);
            $("#endChPass .ftcb__msg__btn").click(() => {
                if (!recaptcha.isValid())
                    return recaptcha.warning(true);
                recaptcha.warning(false);
                this.loginCtrl.user.captcha = recaptcha.getToken();
                $('#enrolmentChPass input, #enrolmentChPass div').removeClass('ftcb__enrolment__btn_err');
                $('.ftcb__enrolment__login-errror span').hide();
                const code = $('#enrolmentChPass input[type ="text"]').val() + '';
                this.loginCtrl.user.password = $('#enrolmentChPass input[name ="password"]').val() + '';
                const repeatPassword = $('#enrolmentChPass input[name ="repeatPassword"]').val() + '';
                if (!code) {
                    $('#enrolmentChPass input[type ="text"]').addClass('ftcb__enrolment__btn_err');
                    return;
                }
                else if (!this.loginCtrl.user.password) {
                    $('#enrolmentChPass input[name ="password"]').addClass('ftcb__enrolment__btn_err');
                    return;
                }
                else if (!repeatPassword) {
                    $('#enrolmentChPass input[name ="repeatPassword"]').addClass('ftcb__enrolment__btn_err');
                    return;
                }
                else if (repeatPassword != this.loginCtrl.user.password) {
                    $('#enrolmentChPass input[name ="repeatPassword"]').addClass('ftcb__enrolment__btn_err');
                    $('#enrolmentChPass input[name ="password"]').addClass('ftcb__enrolment__btn_err');
                    $('#chPassError2 span').show();
                    return;
                }
                else {
                    const data = {
                        mail: this.loginCtrl.user.mail,
                        captcha: this.loginCtrl.user.captcha,
                        password: code,
                        newPassword: this.loginCtrl.user.password
                    };
                    this.loginCtrl.services.chpass(data, (err, user) => {
                        if (err == 400) {
                            $('input[type ="text"]').addClass('ftcb__enrolment__btn_err');
                            $('#chPassError1 span').show();
                            recaptcha = new CaptchaComponent();
                            recaptcha.render('#' + this.captchaID);
                        }
                        else if (err == 409) {
                            $('#chPassError3 span').show();
                            recaptcha = new CaptchaComponent();
                            recaptcha.render('#' + this.captchaID);
                        }
                        else {
                            this.loginCtrl.chatControl.gaConnection.sendGAEvent('inicio', 'ev_recuperar_contraseña', 'completado');
                            this.loginCtrl.stopRecover();
                            this.loginCtrl.loginForm.start(callback);
                        }
                    });
                }
            });
            $("#endChPass .ftcb__enrolment__warning-clickable").click(() => {
                this.loginCtrl.startRecover(callback);
            });
        };
        this.captchaID = Date.now();
    }
    ;
}
