import { EnrolmentController } from "./EnrolmentController";
export class DigitalSkillsTestWithEnrolmentController extends EnrolmentController {
    constructor() {
        super(...arguments);
        this.profileServices = this.chatControl.profileServices;
        this.setPayloadResponse = (callback) => {
            this.chatControl.currentPayloadResponse = 'start_digital_skills_test_with_enrolment';
            callback(null);
        };
        this.calculateResults = (callback) => {
            const answers = this.user.digitalSkills.answers;
            new Promise((resolve, reject) => {
                this.profileServices.getAllSkills("digital_skills", (err, resp) => {
                    this.user.digitalSkills.skills = resp;
                    resolve('OK');
                });
            }).then(() => {
                var score = 0;
                var index = 0;
                for (let i = 0; i < answers.length; i++) {
                    if (answers[i].answerType && answers[i].answerType == "a") {
                        score += 1.25;
                    }
                    if ((i + 1) % 8 === 0) {
                        this.user.digitalSkills.skills[index].identifier = this.user.digitalSkills.skills[index].identifier + '|' + (score * 10).toString();
                        index += 1;
                        score = 0;
                    }
                }
                callback(null);
            });
        };
        this.setUserData = (callback) => {
            if (this.user.avatar)
                this.chatControl.userAvatar = this.user.avatar;
            if (this.user.iduser)
                this.chatControl.iduser = this.user.iduser;
            if (this.user.mail)
                this.chatControl.userEmail = this.user.mail;
            this.chatControl.isExpressUser = this.user.express;
            this.chatControl.menuUserAvatar();
            callback(null);
        };
        this.end = () => {
            this.stop();
        };
        this.functionWaterfall = [
            this.setPayloadResponse,
            this.progress.start,
            this.intro.start,
            this.digitalSkills.start,
            this.calculateResults,
            this.registry.start,
            this.setUserData,
            this.end
        ];
    }
}
