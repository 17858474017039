import { BotMsg, GraphMsg, CardCuourseMsg, RatingMsg } from "./Messages";
import { GraphSkill } from "./JSonResponse";
import { OptionButton, TypeCard } from "./OptionButton";
export class Responses {
    constructor(chatControl) {
        this.chatControl = chatControl;
    }
    setResponse(response, callback) {
        let payloads = response.payloads;
        let messages = [];
        for (let i = 0; i < payloads.length; i++) {
            if (payloads[i].responses[0].channel === 'web' || payloads[i].responses[0].channel === 'default') {
                this.chatControl.currentIntent = payloads[i].intent;
                const msg = this.getMsgFromPayload(payloads[i]);
                if (msg) {
                    msg.delay = payloads[i].delay;
                    messages.push(msg);
                }
            }
        }
        if (messages.length)
            callback(messages);
    }
    getMsgFromPayload(payload) {
        let msg;
        this.chatControl.currentPayloadResponse = payload.type;
        switch (payload.type) {
            case "video":
                msg = new BotMsg("VIDEO_MSG");
                msg.video_url = payload.responses[0].video_url;
                msg.description = payload.responses[0].description;
                break;
            case "quick_replies":
                if (payload.responses[0].elements[0].image_url) {
                    msg = new BotMsg("BUTTONS_IMG_MSG");
                    msg.optionButtons = [];
                    payload.responses[0].elements.forEach((elem) => {
                        let opt = new OptionButton();
                        opt.image_url = elem.image_url;
                        opt.payload = elem.payload;
                        opt.title = elem.title;
                        opt.response = elem.response;
                        msg.optionButtons.push(opt);
                    });
                }
                else {
                    msg = new BotMsg("BUTTONS_MSG");
                    msg.optionButtons = [];
                    payload.responses[0].elements.forEach((elem) => {
                        let opt = new OptionButton();
                        opt.payload = elem.payload;
                        opt.title = elem.title;
                        opt.response = elem.response;
                        msg.optionButtons.push(opt);
                    });
                }
                break;
            case "button":
                msg = new BotMsg("BUTTONS_LARGE_MSG");
                msg.optionButtons = [];
                payload.responses[0].elements.forEach((elem) => {
                    let opt = new OptionButton();
                    opt.image_url = elem.image_url ? elem.image_url : "";
                    opt.payload = elem.payload;
                    opt.title = elem.title;
                    opt.response = elem.response;
                    msg.optionButtons.push(opt);
                });
                break;
            case "call_to_action":
                msg = new BotMsg("CALL_TO_ACTION_MSG");
                msg.optionButtons = [];
                payload.responses[0].elements.forEach((elem) => {
                    let opt = new OptionButton();
                    opt.url = elem.url ? elem.url : "";
                    opt.title = elem.title;
                    msg.optionButtons.push(opt);
                });
                break;
            case "carousel":
                msg = new BotMsg("CARDS_MSG");
                msg.carousel = [];
                for (let i = 0; i < payload.responses[0].elements.length; i++) {
                    const elem = payload.responses[0].elements[i];
                    let card = new TypeCard();
                    card.image_url = elem.image_url;
                    card.description = elem.description;
                    card.title = elem.title;
                    card.buttons = elem.buttons;
                    msg.carousel.push(card);
                }
                break;
            case "image":
                msg = new BotMsg("IMG_MSG");
                msg.image_url = payload.responses[0].image_url;
                break;
            case "intro_enrolment":
                msg = new BotMsg("INTRO_ENROLMENT");
                msg.text = payload.responses[0].elements[0].text;
                msg.enrolmentOptions = [
                    payload.responses[0].elements[0].option_one,
                    payload.responses[0].elements[0].option_two
                ];
                break;
            case "start_enrolment":
                msg = null;
                this.chatControl.startEnrolment();
                break;
            case "start_login":
                msg = null;
                this.chatControl.startLogin();
                break;
            case "close_session":
                msg = new BotMsg("BOT_MSG");
                msg.text = payload.responses[0].text;
                this.chatControl.closeSession();
                break;
            case "open_profile_menu":
                msg = null;
                this.chatControl.profileMenuControl.toggleProfileMenu();
                break;
            case "recover_password":
                msg = null;
                this.chatControl.recoverPassword();
                break;
            case "start_skills_tests":
                msg = null;
                this.chatControl.startSkillsTest((err) => {
                    if (err)
                        return this.chatControl.sendCloseLogin();
                    this.chatControl.sendSkillsTestEnd(this.chatControl.iduser);
                });
                break;
            case "start_digital_skills_test_with_enrolment":
                msg = null;
                this.chatControl.startDigitalSkillsTestWithEnrolment();
                break;
            case "grafica_digital_skills":
                const digitalMsg = new GraphMsg("GRAPH_DIGITAL_SKILLS_MSG");
                digitalMsg.skills = [];
                payload.responses[0].elements.forEach((skill) => {
                    let graphSkill = new GraphSkill();
                    if (skill.includes('|')) {
                        graphSkill.id_skill = skill.split('|')[0];
                        graphSkill.num_ocurrencias = skill.split('|')[1] === "" ? '0' : skill.split('|')[1];
                    }
                    else {
                        graphSkill.id_skill = skill;
                        graphSkill.num_ocurrencias = '0';
                    }
                    this.chatControl.allDigitalSkillsSkills.forEach((digitalSkill) => {
                        if (digitalSkill.identifier == graphSkill.id_skill) {
                            graphSkill.nombre_skill = digitalSkill.name;
                            graphSkill.skill_sort = digitalSkill.skill_sort;
                        }
                    });
                    digitalMsg.skills.push(graphSkill);
                });
                digitalMsg.skills.sort((a, b) => a.skill_sort - b.skill_sort);
                msg = digitalMsg;
                break;
            case "grafica_soft_skills":
                const softMsg = new GraphMsg("GRAPH_SOFT_SKILLS_MSG");
                softMsg.skills = [];
                payload.responses[0].elements.forEach((skill) => {
                    let graphSkill = new GraphSkill();
                    if (skill.includes('|')) {
                        graphSkill.id_skill = skill.split('|')[0];
                        graphSkill.num_ocurrencias = skill.split('|')[1] === "" ? '0' : skill.split('|')[1];
                    }
                    else {
                        graphSkill.id_skill = skill;
                        graphSkill.num_ocurrencias = '0';
                    }
                    this.chatControl.allSoftSkillsSkills.forEach((digitalSkill) => {
                        if (digitalSkill.identifier == graphSkill.id_skill) {
                            graphSkill.nombre_skill = digitalSkill.name;
                            graphSkill.skill_sort = digitalSkill.skill_sort;
                        }
                    });
                    softMsg.skills.push(graphSkill);
                });
                msg = softMsg;
                break;
            case "start_digital_skills_test":
                msg = null;
                this.chatControl.startDigitalSkillsTest();
                break;
            case "grafica":
                const graphMsg = new GraphMsg("GRAPH_MSG");
                graphMsg.skills = payload.responses[0].elements;
                msg = graphMsg;
                break;
            case "card_cursos":
                const cardCourseMsg = new CardCuourseMsg("CARD_COURSE_MSG");
                const response = payload.responses[0].elements[0];
                cardCourseMsg.nombre_curso = response.nombre_curso;
                cardCourseMsg.descripcion_curso = response.descripcion_curso;
                cardCourseMsg.nivel_curso = response.nivel_curso;
                cardCourseMsg.id_curso = response.id_curso;
                cardCourseMsg.certificado_disponible_curso = (response.certificado_disponible_curso === '1');
                cardCourseMsg.duracion_curso = response.duracion_curso;
                cardCourseMsg.esfuerzo_maximo_curso = response.esfuerzo_maximo_curso;
                cardCourseMsg.precio_curso = response.precio_curso;
                cardCourseMsg.puntuacion_curso = parseInt(response.puntuacion_curso);
                cardCourseMsg.plataforma_curso = response.plataforma_curso;
                cardCourseMsg.url_curso = response.url_curso;
                cardCourseMsg.image_url = response.image_url;
                cardCourseMsg.skills = response.skills;
                cardCourseMsg.flujo = response.flujo;
                cardCourseMsg.index = response.index;
                this.chatControl.showRating = payload.responses[0].feedback ? this.chatControl.showRating : false;
                msg = cardCourseMsg;
                break;
            case "null_message":
                msg = null;
                break;
            case "rating":
                const ratingMsg = new RatingMsg("RATING_MSG");
                msg = ratingMsg;
                break;
            default:
                msg = new BotMsg("BOT_MSG");
                msg.text = payload.responses[0].text;
                break;
        }
        return msg;
    }
}
