import { EnrolmentComponent } from "./EnrolmentComponent";
export class EnrolmentMotivation {
    constructor(sentence, parent, enrolmentCtrl) {
        this.sentence = sentence;
        this.parent = parent;
        this.enrolmentCtrl = enrolmentCtrl;
        this.template = () => {
            return `
      <span>
      ${this.sentence}
      </span>
   `;
        };
        this.container = new EnrolmentComponent(this.enrolmentCtrl, 'enrolmentMotivation', 'ftcb__enrolment__motivation');
        this.start = () => {
            this.container.setContent(this.template());
            this.container.inject(this.parent);
            this.enrolmentCtrl.scroll();
        };
        this.stop = () => {
            $(this.container.component).find('span').fadeOut(500, () => {
                this.container.destroy();
            });
        };
    }
    ;
}
