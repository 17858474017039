export class ButtonsControl {
    constructor() {
        const bodyNode = document.body;
        const config = { childList: true, subtree: true };
        const bodyCallback = function (mutationsList, observer) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === "childList") {
                    const targetNode = document.getElementById("chatbotContainer");
                    if (targetNode) {
                        const chatObserver = new MutationObserver(chatCallback);
                        chatObserver.observe(targetNode, {
                            childList: true,
                            subtree: true,
                        });
                        observer.disconnect();
                    }
                }
            });
        };
        const observer = new MutationObserver(bodyCallback);
        observer.observe(bodyNode, config);
        const chatCallback = function (mutationsList, chatObserver) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === "childList") {
                    const buttons = document.querySelectorAll(".ftcb__msg__btn-content");
                    buttons.forEach((button) => {
                        button.removeEventListener("click", handleClick);
                        button.addEventListener("click", handleClick);
                    });
                }
            });
        };
        const handleClick = function () {
            this.classList.add("ftcb__msg__btn-clicked");
            this.addEventListener("transitionend", handleTransitionEnd);
            this.addEventListener("webkitTransitionEnd", handleTransitionEnd);
            this.addEventListener("oTransitionEnd", handleTransitionEnd);
        };
        const handleTransitionEnd = function () {
            this.classList.remove("ftcb__msg__btn-clicked");
            this.removeEventListener("transitionend", handleTransitionEnd);
            this.removeEventListener("webkitTransitionEnd", handleTransitionEnd);
            this.removeEventListener("oTransitionEnd", handleTransitionEnd);
        };
    }
}
