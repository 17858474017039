import { ProfileChPwd } from "./ProfileChPwd";
import { ModalScreen } from "../ModalScreen";
import { textTag } from "../TextTag";
import { CountrySelector } from "../CountrySelector";
export class ProfilePDForm {
    constructor(profilePersonalData) {
        this.profilePersonalData = profilePersonalData;
        this.countrySelector = new CountrySelector(this.profilePersonalData.chatCtrl);
        this.profileChPwd = new ProfileChPwd(this.profilePersonalData);
        this.texts = this.profilePersonalData.chatCtrl.dictionary.texts.profile;
        this.template = `
      <div class="ftcb__profile__form">
        <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="username">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data3')}</div>
          <div class="ftcb__profile__form-input">
            <input type="name" maxlength="20" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(this.texts, 'Data3')}" value="${this.profilePersonalData.userInfo.username}" onclick="$(this).focus();">
          </div>
          <div class="ftcb__profile__form-errTxt"><span>${textTag(this.texts, 'ErrorCharacters')}</span></div>
        </div>

        <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="surname">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data14')}</div>
          <div class="ftcb__profile__form-input">
            <input type="name" maxlength="40" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(this.texts, 'Data14')}" value="${this.profilePersonalData.userInfo.surname}" onclick="$(this).focus();">
          </div>
          <div class="ftcb__profile__form-errTxt"><span>${textTag(this.texts, 'ErrorCharacters')}</span></div>
        </div>

        <div class ="ftcb__profile__form-short ftcb__profile__form-field" id="countryDiv">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data5')}</div>
          ${this.countrySelector.template}
        </div>

        
        <div class ="ftcb__profile__form-short ftcb__profile__form-field" id="genero">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data7')}</div>
          <div class="ftcb__profile__form_select">
            <div class="ftcb__profile__form_select_img"></div>
            <select id="genero" name="genero" class="ftcb__profile__form-select">
              <option id="genero_1" value="1">${textTag(this.texts, 'Genero1')}</option>
              <option id="genero_2" value="2">${textTag(this.texts, 'Genero2')}</option>
              <option id="genero_3" value="3">${textTag(this.texts, 'Genero3')}</option>
              <option id="genero_4" value="4">${textTag(this.texts, 'Genero4')}</option>
            </select>
          </div>
        </div>

        <div class ="ftcb__profile__form-short ftcb__profile__form-field" id="nacimiento">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data4')}</div>
          <div class="ftcb__profile__form-input">
            <input type="number" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(this.texts, 'Data4')}" value="${this.profilePersonalData.userInfo.age}" onclick="$(this).focus();">
          </div>
          <div class="ftcb__profile__form-errTxt"><span>${textTag(this.texts, 'Error')}</span></div>
        </div>

        <div class ="ftcb__profile__form-short ftcb__profile__form-field" id="phone">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data6')}</div>
          <div class="ftcb__profile__form-input">
            <input type="phone" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(this.texts, 'Data6')}" value="${this.profilePersonalData.userInfo.phone || ''}" onclick="$(this).focus();">
          </div>
          <div class="ftcb__profile__form-errTxt"><span>${textTag(this.texts, 'Error')}</span></div>
        </div>


        <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="situacion">
        <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data8')}</div>
        <div class="ftcb__profile__form_select">
          <div class="ftcb__profile__form_select_img"></div>
          <select id="situacion" name="situacion" class="ftcb__profile__form-select">
            <option id="situacion_1" value="1">${textTag(this.texts, 'Situacion1')}</option>
            <option id="situacion_2" value="2">${textTag(this.texts, 'Situacion2')}</option>
            <option id="situacion_3" value="3">${textTag(this.texts, 'Situacion3')}</option>
            <option id="situacion_4" value="4">${textTag(this.texts, 'Situacion4')}</option>
          </select>
        </div>
      </div>
        <div class ="ftcb__profile__form-long ftcb__profile__form-field">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data9')}</div>
          <div class="ftcb__profile__form-input">
            <input type="email" class="ftcb__inputcontainer__textfield__input" value="${this.profilePersonalData.userInfo.email}" onclick="$(this).focus();" readonly>
          </div>
        </div>
        <div class ="ftcb__profile__form-short ftcb__profile__form-field">
          <div class="ftcb__profile__form-title">${textTag(this.texts, 'Data10')}</div>
          <div class="ftcb__profile__form-input">
            <input type="password" class="ftcb__inputcontainer__textfield__input" value="${this.profilePersonalData.userInfo.password}" onclick="$(this).focus();" readonly>
          </div>
        </div>
        <div class ="ftcb__profile__form-short">
          <div class="ftcb__profile__btn-change" id="openChPwd">
            <div class="ftcb__profile__btn-titlenoimage">${textTag(this.texts, 'Data11')}</div>
          </div>
        </div>
        <div class="ftcb__profile__changepwd-container">
          ${this.profileChPwd.template}
        </div>
        <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer" id="changeData">
          <div class="ftcb__msg__btn-content ftcb__profile__btn-title">
            <div class="ftcb__msg__btn-titlenoimage">${textTag(this.texts, 'Guardar')}</div>
          </div>
        </div>
      </div>
    `;
    }
    bind() {
        var _a, _b;
        if (this.profilePersonalData.userInfo.idgender) {
            $(`#genero_${this.profilePersonalData.userInfo.idgender.toString()}`).attr("selected", "selected");
        }
        if (this.profilePersonalData.userInfo.idcurrent_situation) {
            $(`#situacion_${this.profilePersonalData.userInfo.idcurrent_situation.toString()}`).attr("selected", "selected");
        }
        if ((_b = (_a = this.profilePersonalData) === null || _a === void 0 ? void 0 : _a.userInfo) === null || _b === void 0 ? void 0 : _b.idcountry) {
            let _idCountry = this.profilePersonalData.userInfo.idcountry;
            let _val = Object.keys(COUNTRIES_ID)[Object.values(COUNTRIES_ID).indexOf(_idCountry)];
            $(`#Country${_val}`).attr("selected", "selected");
        }
        this.profileChPwd.bind();
        this.setNumberValidation();
        $("#changeData .ftcb__msg__btn-content").click((event) => {
            $(".ftcb__profile__form-field").removeClass('ftcb__profile__form-field-err');
            this.profilePersonalData.userInfo.username = $("#username input").val() + '';
            this.profilePersonalData.userInfo.surname = $("#surname input").val() + '';
            this.profilePersonalData.userInfo.phone = $("#phone input").val() + '';
            this.profilePersonalData.userInfo.idcountry = Number($('select[name ="country"]').val());
            this.profilePersonalData.userInfo.age = Number($("#nacimiento input").val());
            this.profilePersonalData.userInfo.idgender = Number($("#genero select").val());
            this.profilePersonalData.userInfo.idcurrent_situation = Number($("#situacion select").val());
            let invalid = false;
            if (!this.profilePersonalData.userInfo.username
                || !this.profilePersonalData.chatCtrl.validator.isValid(this.profilePersonalData.userInfo.username, 'name')) {
                $("#username").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!this.profilePersonalData.chatCtrl.validator.isValid(this.profilePersonalData.userInfo.surname, 'surname')) {
                $("#surname").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (this.profilePersonalData.userInfo.phone && !this.profilePersonalData.chatCtrl.validator.isValid(this.profilePersonalData.userInfo.phone, 'phone')) {
                $("#phone").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!this.profilePersonalData.userInfo.idcountry) {
                $("#countrDiv").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!this.profilePersonalData.userInfo.age || !this.profilePersonalData.chatCtrl.validator.isValid(this.profilePersonalData.userInfo.age, 'year')
                || (!this.profilePersonalData.chatCtrl.validator.setMinYearValidate(this.profilePersonalData.userInfo.age) || !this.profilePersonalData.chatCtrl.validator.setMaxYearValidate(Number(this.profilePersonalData.userInfo.age)))) {
                $("#nacimiento").addClass("ftcb__profile__form-field-err");
                invalid = true;
            }
            if (!invalid && this.profilePersonalData.userInfo.username &&
                this.profilePersonalData.userInfo.idgender && this.profilePersonalData.userInfo.idcountry && this.profilePersonalData.userInfo.age) {
                const avatar = `avatar${this.profilePersonalData.userInfo.idgender}`;
                this.profilePersonalData.userInfo.avatar = this.profilePersonalData.chatCtrl.userAvatar = this.profilePersonalData.icons[avatar];
                this.profilePersonalData.chatCtrl.menuUserAvatar();
                this.profilePersonalData.profileServices.updateUser(this.profilePersonalData.userInfo, (err, response) => {
                    if (err)
                        return this.profilePersonalData.chatCtrl.errorModal();
                    this.profilePersonalData.chatCtrl.gaConnection.sendGAEvent('perfil', 'ev_perfil', 'Datos_personales');
                    new ModalScreen({
                        title: textTag(this.profilePersonalData.chatCtrl.dictionary.texts.modals, 'UpdatedUser_title'),
                        subtitle: textTag(this.profilePersonalData.chatCtrl.dictionary.texts.modals, 'UpdatedUser_subtitle'),
                        primaryButton: { text: textTag(this.profilePersonalData.chatCtrl.dictionary.texts.modals, 'Aceptar'), callback: () => { this.profilePersonalData.popUp.close(); } },
                    });
                });
            }
        });
        $("#openChPwd").click(() => {
            $(".ftcb__profile__change-pwd").removeClass('ftcb__profile__change-pwd--closed');
        });
    }
    setNumberValidation() {
        this.profilePersonalData.chatCtrl.validator.setNumberDimension("#nacimiento .ftcb__inputcontainer__textfield__input", 4);
        this.profilePersonalData.chatCtrl.validator.setNumberDimension("#phone .ftcb__inputcontainer__textfield__input", 12);
    }
}
