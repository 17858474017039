var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ProfileServices {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.getUserSkills = (callback) => {
            const uri = `${HOST_URL}/mw/api/user_skills/${this.chatCtrl.iduser}?locale=${this.chatCtrl.language}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        const data = JSON.parse(req.response).data;
                        const skills = {
                            hard_skills: data.hard_skills,
                            soft_skills: data.soft_skills,
                            field_skills: data.field_skills,
                            digital_skills: data.digital_skills
                        };
                        callback(null, skills);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getUserSkills(callback); });
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send();
        };
        this.getAllSkills = (type, callback) => {
            const uri = `${HOST_URL}/mw/api/cms/skills?locale=${this.chatCtrl.language}&type=${type}`;
            if (!this.chatCtrl.token)
                return this.chatCtrl.brConnection.getToken(() => { this.getAllSkills(type, callback); });
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getAllSkills(type, callback); });
                    }
                    else {
                        this.chatCtrl.errorModal();
                    }
                }
            };
            req.send();
        };
        this.getUser = (callback, id) => {
            const uri = `${HOST_URL}/mw/api/user/${this.chatCtrl.iduser || id}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getUser(callback); });
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send();
        };
        this.getCareersMedia = (careers, callback) => {
            const careersQuery = `?identifier=${careers}&locale=${this.chatCtrl.language}`;
            const uri = `${HOST_URL}/mw/api/cms/multimedia${careersQuery}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getUserCareers(careers, callback); });
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send();
        };
        this.getContents = (callback) => {
            const uri = `${HOST_URL}/mw/api/cms/contents?locale=${this.chatCtrl.language}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => __awaiter(this, void 0, void 0, function* () {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getContents(callback); });
                    }
                    else {
                        callback(500);
                    }
                }
            });
            req.send();
        };
        this.getUserCareers = (skills, callback) => {
            const skillsQuery = `?field_skills=${skills.field_skills}&soft_skills=${skills.soft_skills}&hard_skills=${skills.hard_skills}&locale=${this.chatCtrl.language}&sessionId=${this.chatCtrl.sessionId}`;
            const uri = `${HOST_URL}/mw/api/enrolment/career_enrolment${skillsQuery}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => __awaiter(this, void 0, void 0, function* () {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data.careers) {
                        const careers = JSON.parse(req.response).data.careers;
                        callback(null, careers);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.getUserCareers(skills, callback); });
                    }
                    else {
                        callback(500);
                    }
                }
            });
            req.send();
        };
        this.updateUserSkills = (skills, callback) => {
            const userId = this.chatCtrl.iduser;
            const body = {
                soft_skills: skills.soft_skills,
                hard_skills: skills.hard_skills,
                field_skills: skills.field_skills,
                digital_skills: skills.digital_skills,
                type: skills.type
            };
            const uri = `${HOST_URL}/mw/api/user_skills/${userId}`;
            const req = new XMLHttpRequest();
            req.open("PUT", uri, true);
            req.setRequestHeader('Content-type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 204) {
                        callback(null);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else {
                        callback(500);
                    }
                }
            };
            req.send(JSON.stringify(body));
        };
        this.updateUser = (inputData, callback) => {
            const user = {
                iduser: inputData.iduser,
                username: inputData.username,
                surname: inputData.surname,
                phone: inputData.phone,
                age: inputData.age,
                legal: inputData.legal,
                idcountry: inputData.idcountry,
                idgender: inputData.idgender,
                idcurrent_situation: inputData.idcurrent_situation
            };
            const uri = `${HOST_URL}/mw/api/user/${user.iduser}`;
            const req = new XMLHttpRequest();
            req.open("PUT", uri, true);
            req.setRequestHeader('Content-type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 204) {
                        callback(null, req.response);
                    }
                    else if (req.status == 404) {
                        callback(404);
                    }
                    else {
                        this.chatCtrl.errorModal();
                    }
                }
            };
            req.send(JSON.stringify(user));
        };
        this.chpass = (data, callback) => {
            const uri = `${HOST_URL}/mw/chpass`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.chatCtrl.errorModal();
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
        this.sendContact = (user, mail, callback) => {
            const data = {
                mailFrom: user.email,
                mailBody: mail.body,
                mailSubject: mail.subject,
                username: user.username,
                captcha: mail.captcha
            };
            const uri = `${HOST_URL}/mw/api/mail/get_contact`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        this.chatCtrl.messageSentModal();
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.chatCtrl.errorModal();
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.sendContact(user, mail, callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
        this.deleteCourses = (course, callback) => {
            const data = {
                iduser: this.chatCtrl.iduser,
                idcourse: course
            };
            const uri = `${HOST_URL}/mw/api/user_courses/${data.iduser}`;
            const req = new XMLHttpRequest();
            req.open("DELETE", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.chatCtrl.errorModal();
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.deleteCourses(course, callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
        this.findCourses = (callback) => {
            const data = {
                iduser: this.chatCtrl.iduser
            };
            const uri = `${HOST_URL}/mw/api/user_courses/${data.iduser}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.chatCtrl.errorModal();
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.findCourses(callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send();
        };
        this.addCourses = (courseId, state, callback) => {
            const data = {
                iduser: this.chatCtrl.iduser,
                idcourse: courseId,
                state: state
            };
            const uri = `${HOST_URL}/mw/api/user_courses/${data.iduser}`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.addCourses(courseId, state, callback); });
                    }
                    else if (req.status == 409) {
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
        this.updateCourses = (course, state, callback) => {
            const data = {
                iduser: this.chatCtrl.iduser,
                idcourse: course,
                state: state
            };
            const uri = `${HOST_URL}/mw/api/user_courses/${data.iduser}`;
            const req = new XMLHttpRequest();
            req.open("PUT", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.updateCourses(course, state, callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
        this.findCoursesByIds = (idCourse, callback) => {
            const uri = `${HOST_URL}/mw/api/user_courses/${this.chatCtrl.iduser}/${idCourse}`;
            const req = new XMLHttpRequest();
            req.open("GET", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 200 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.chatCtrl.errorModal();
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.findCoursesByIds(idCourse, callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send();
        };
        this.addSkillsCourse = (skills, callback) => {
            const data = {
                iduser: this.chatCtrl.iduser,
                skills: skills
            };
            const uri = `${HOST_URL}/mw/api/user_skills/${this.chatCtrl.iduser}`;
            const req = new XMLHttpRequest();
            req.open("POST", uri, true);
            req.setRequestHeader('Content-Type', 'application/json');
            req.setRequestHeader('Authorization', this.chatCtrl.token);
            req.onreadystatechange = () => {
                if (req.readyState == 4) {
                    if (req.status == 201 && JSON.parse(req.response).data) {
                        callback(null, JSON.parse(req.response).data);
                    }
                    else if (req.status == 500) {
                        this.chatCtrl.errorModal();
                    }
                    else if (req.status == 401) {
                        this.chatCtrl.brConnection.getToken(() => { this.findCourses(callback); });
                    }
                    else {
                        callback(req.status);
                    }
                }
            };
            req.send(JSON.stringify(data));
        };
    }
    ;
}
