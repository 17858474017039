import { textTag } from "../TextTag";
export class ProfileContentsContainer {
    constructor(profileContents) {
        this.profileContents = profileContents;
        this.contentTemplate = (content) => {
            return `
    <div class="ftcb__content__course ftcb__cardcourse__container ftcb__content__course--closed">
      <div class="ftcb__cardcontent__text">
        ${content.title}
      </div>
      <div class="ftcb__cardcontent__image" style="background-image:url(${content.image})"></div>
      <a href="${content.url}" target="_blank" class="ftcb__cardcontent__link" data-url="${content.url}" data-contenttitle="${content.title}">
      ${textTag(this.profileContents.chatCtrl.dictionary.texts.profile, 'ContentsLink')}
      </a>    
    </div>  
    `;
        };
        this.templateGenerator = (contents) => {
            const contentsDivs = contents.map((content) => this.contentTemplate(content));
            return `
      <div class="ftcb__profile__careers">
        <div class="ftcb__profile__careers-container">     
          ${contentsDivs.join('')} 
        </div>
      </div>
    `;
        };
        this.template = this.templateGenerator(this.profileContents.contents);
    }
    bind() {
        $(".ftcb__cardcontent__link").click((evt) => {
            const contentTitle = $(evt.currentTarget).data('contenttitle');
            this.profileContents.chatCtrl.gaConnection.sendGAEvent('perfil', 'ev_content', contentTitle);
        });
    }
}
