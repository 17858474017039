import { StaticsControl } from "./staticsControl";
class ModalButton {
}
class ModalObj {
}
export class ModalScreen {
    constructor(modalObj) {
        this.modalObj = modalObj;
        this.parentContainer = document.getElementById("chatbotContainer");
        this.templateConstructor = (modalObj) => {
            const title = `
      <div class ="ftcb__modal__title">
        ${modalObj.title}
      </div>
    `;
            const subtitle = `
      <div class ="ftcb__modal__subtitle">
        ${modalObj.subtitle}
      </div>
    `;
            const secondaryButton = modalObj.secondaryButton ? `
      <div class="ftcb__msg__btn ftcb__modal__button ftcb__modal__button-secondary">
        <div class="ftcb__msg__btn-content ftcb__msg__btn-content__secondary">
          <div class="ftcb__msg__btn-titlenoimage">${modalObj.secondaryButton.text}</div>
        </div>
      </div> 
    ` : '';
            const primareButton = `
      <div class="ftcb__msg__btn ftcb__modal__button ftcb__modal__button-primary">
        <div class="ftcb__msg__btn-content">
          <div class="ftcb__msg__btn-titlenoimage">${modalObj.primaryButton.text}</div>
        </div>
      </div> 
    `;
            const footer = `
     <div class="ftcb__modal__footer ${modalObj.buttonType == 1 ? 'ftcb__modal__footer-secondary' : ''}">
       ${secondaryButton}
       ${primareButton}
     </div>
    `;
            const html = `
      ${title}
      ${subtitle}
      ${modalObj.customContent || ''}
      ${footer}
    `;
            return html;
        };
        this.close = () => {
            this.component.classList.remove("ftcb__modal__open");
            this.component.classList.add("ftcb__modal__close");
            this.component.remove();
        };
        this.bindButtons = (modalObj) => {
            if (modalObj.secondaryButton) {
                $('.ftcb__msg__btn.ftcb__modal__button-secondary').click((event) => {
                    $(event.currentTarget).on("transitionend webkitTransitionEnd oTransitionEnd", () => {
                        if (modalObj.secondaryButton.callback) {
                            modalObj.secondaryButton.callback();
                        }
                        this.close();
                    });
                });
            }
            $('.ftcb__msg__btn.ftcb__modal__button-primary').click((event) => {
                $(event.currentTarget).on("transitionend webkitTransitionEnd oTransitionEnd", () => {
                    if (modalObj.primaryButton.callback) {
                        modalObj.primaryButton.callback();
                    }
                    this.close();
                });
            });
        };
        this.template = this.templateConstructor(this.modalObj);
        this.component = document.createElement("div");
        this.component.className = "ftcb__modal";
        this.component.classList.add('ftcb__modal__open');
        this.component.classList.add('ftcb__enrolment__container');
        this.component.classList.add('ftcb__msgwrapper--menu-close');
        this.component.classList.add('ftcb__msgwrapper');
        this.container = document.createElement("div");
        this.container.className = "ftcb__modal__container";
        this.container.innerHTML = this.template;
        this.closeButton = document.createElement("div");
        this.closeButton.id = 'modalCloseButton';
        this.closeButton.className = "ftcb__enrolment_close";
        this.closeButton.innerHTML = `<img src="${StaticsControl.statics.closeIcon}">`;
        this.container.appendChild(this.closeButton);
        this.component.appendChild(this.container);
        this.parentContainer.appendChild(this.component);
        $(this.component).innerHeight($("#chatBotMessageWrapper").innerHeight() + $(".ftcb__inputcontainer").innerHeight());
        this.closeButton.onclick = this.close;
        this.bindButtons(this.modalObj);
    }
}
