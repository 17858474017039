import { EnrolmentController } from "./EnrolmentController";
import { EnrolmentComponent } from "./EnrolmentComponent";
import { textTag } from "../TextTag";
import { CountrySelector } from "../CountrySelector";
import { CaptchaComponent } from "./CaptchaComponent";
import { StaticsControl } from "../staticsControl";
export class EnrolmentExpres extends EnrolmentController {
    constructor() {
        super(...arguments);
        this.countrySelector = new CountrySelector(this.chatControl);
        this.template = () => {
            let icons = StaticsControl.statics;
            const texts = this.enrolmentTexts;
            return `
    <div class="ftcb__profile__popUp__header">
    <div class="ftcb__profile__popUp__header-image">  
      <img src="${this.icons.profileOptionIcon1}">
    </div>
    <div class="ftcb__profile__popUp__header-title">${textTag(texts, 'SignupTitle')}</div>
  </div>
    <div class="ftcb__profile__form">
      <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="name">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Intro2')}</div>
        <div class="ftcb__profile__form-input">
          <input name="name" size="3" type="name" maxlength="20" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Intro2')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'ErrorDato')}</span></div>
      </div>

      <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="apellidos">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Intro12')}</div>
        <div class="ftcb__profile__form-input">
          <input name="apellidos" size="3" type="name" maxlength="40" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Intro12')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'ErrorDato')}</span></div>
      </div>


      <div class ="ftcb__profile__form-short ftcb__profile__form-field">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Situation')}</div>
        <div class="ftcb__profile__form_select">
          <div class="ftcb__profile__form_select_img"></div>
          <select id="situacion" name="situacion" class="ftcb__profile__form-select">
            <option id="situacion_1" value="1">${textTag(texts, 'Situation1')}</option>
            <option id="situacion_2" value="2">${textTag(texts, 'Situation2')}</option>
            <option id="situacion_3" value="3">${textTag(texts, 'Situation3')}</option>
            <option id="situacion_4" value="4">${textTag(texts, 'Situation4')}</option>
          </select>
        </div>
      </div>


      <div class ="ftcb__profile__form-short ftcb__profile__form-field" >
        <div class="ftcb__profile__form-title">${textTag(texts, 'Pais')}</div>
        ${this.countrySelector.template}
      </div>

      <div class ="ftcb__profile__form-short ftcb__profile__form-field" id="year">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Intro4')}</div>
        <div class="ftcb__profile__form-input">
          <input name="year" type="number" maxlength="4" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Intro4')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'ErrorDato')}</span></div>
      </div>
      


      <!--<div class ="ftcb__profile__form-short ftcb__profile__form-field" id="phone">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Registry4')}</div>
        <div class="ftcb__profile__form-input">
          <input name="phone" type="tel" maxlength="12" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry4')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'ErrorDato')}</span></div>
      </div>-->

      <div class ="ftcb__profile__form-short ftcb__profile__form-field">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Genero')}</div>
        <div class="ftcb__profile__form_select">
          <div class="ftcb__profile__form_select_img"></div>
          <select id="genero" name="genero" class="ftcb__profile__form-select">
          <option id="genero_1" value="1">${textTag(texts, 'Intro8')}</option>
          <option id="genero_2" value="2">${textTag(texts, 'Intro9')}</option>
          <option id="genero_3" value="3">${textTag(texts, 'Intro10')}</option>
          <option id="genero_4" value="4">${textTag(texts, 'Intro11')}</option>
          </select>
        </div>
      </div>

      <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="email">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Registry2')}</div>
        <div class="ftcb__profile__form-input">
          <input name="email" type="email" class="ftcb__inputcontainer__textfield__input" placeholder="${textTag(texts, 'Registry2')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'ErrorDato')}</span></div>
      </div>

      <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="repeatEmail">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Registry11')}</div>
        <div class="ftcb__profile__form-input">
          <input name="repeatEmail" type="email" class="ftcb__inputcontainer__textfield__input" autocomplete="off" placeholder="${textTag(texts, 'Registry11')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'ErrorDato')}</span></div>
      </div>

      <div class ="ftcb__profile__form-long ftcb__profile__form-field" id="password">
        <div class="ftcb__profile__form-title">${textTag(texts, 'Registry3')}</div>
        <div class="ftcb__profile__form-input">
          <input name="password" type="password" class="ftcb__inputcontainer__textfield__input" autocomplete="off" placeholder="${textTag(texts, 'Registry3')}" onclick="$(this).focus();">
        </div>
        <div class="ftcb__expres__form-errTxt"><span>${textTag(texts, 'Registry24')}</span></div>
      </div>

      <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom__check">
        <div class="ftcb__msg__btn ftcb__enrolment__input__class_check ftcb__check__button">
          <input type="checkbox" name="legal" class="ftcb__inputcontainer__textfield__input ftcb__inputcontainer__textfield__input__custom">
            <p>
              ${textTag(texts, 'Registry5')}<a href= ${textTag(texts, 'LegalUri')} target="_blank" rel="noopener noreferrer"> ${textTag(texts, 'Registry6')}</a>
            </p>
        </div>
      </div>
      <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom__check">
        <div class="ftcb__msg__btn ftcb__enrolment__input__class_check ftcb__check__button">
          <input type="checkbox" name="legal-country" class="ftcb__inputcontainer__textfield__input ftcb__inputcontainer__textfield__input__custom">
            <p>
              ${textTag(texts, 'Registry5')}<a href=${textTag(texts, 'LegalCountryUri')} target="_blank" rel="noopener noreferrer"> ${textTag(texts, 'Registry16')}</a>
            </p>
        </div>
      </div>
      <div class="ftcb__enrolment__login-errror">
      <span id="span6">${textTag(texts, 'Registry20')}<br></span>
      <span id="span7">${textTag(texts, 'Registry23')}<br></span>
        <span id="span5"></span>
        <span id="span4">${textTag(texts, 'Registry15')}<br></span>
        <span id="span2">${textTag(texts, 'Registry12')}<br></span>
        <span id="span1">${textTag(texts, 'Registry13')}<br></span>
        <span id="span3">${textTag(texts, 'Registry14')}<br></span>
        
      </div>
      <div id="captchaContainer" style="margin-bottom: 2rem"></div>
      <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer ftcb__enrolment__button " id="endRegistryExpress">
        <div class="ftcb__msg__btn-content ftcb__profile__btn-title">
          <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, 'Registry7')}</div>
        </div>
      </div>
    </div>
  `;
        };
        this.formContainer = new EnrolmentComponent(this, 'enrolmentExpresForm', 'ftcb__enrolment_expres');
        this.startExpres = (callback) => {
            this.user.express = 1;
            this.formContainer.setContent(this.template());
            this.formContainer.inject(this.container.component, false);
            this.setValidation();
            $('input[name ="repeatEmail"]').bind("paste", function (e) {
                e.preventDefault();
            });
            let recaptcha = new CaptchaComponent();
            recaptcha.render('#captchaContainer');
            $("#endRegistryExpress").click(() => {
                $('#enrolmentExpresForm  .ftcb__enrolment__login-errror span').hide();
                this.user.username = $('input[name ="name"]').val() + '';
                this.user.surname = $('input[name ="apellidos"]').val() + '';
                this.user.age = Number($('input[name ="year"]').val());
                this.user.country = Number($('select[name ="country"]').val());
                this.user.gender = Number($('select[name ="genero"]').val());
                this.user.situation = Number($('select[name="situacion"]').val());
                this.user.mail = $('input[name ="email"]').val() + '';
                const repeatEmail = $('input[name ="repeatEmail"]').val() + '';
                this.user.password = $('input[name ="password"]').val() + '';
                this.user.phone = '';
                this.user.legal = $('input[name ="legal"]').is(":checked") &&
                    $('input[name ="legal-country"]').is(":checked");
                let invalid = false;
                if (!this.user.username || !this.chatControl.validator.isValid(this.user.username, 'name')) {
                    $('#name').addClass("ftcb__expres__form-field-err");
                    $('#span6').show();
                    invalid = true;
                }
                else {
                    $('#name').removeClass("ftcb__expres__form-field-err");
                }
                if (!this.chatControl.validator.isValid(this.user.surname, 'surname')) {
                    $('#apellidos').addClass("ftcb__expres__form-field-err");
                    $('#span7').show();
                    invalid = true;
                }
                else {
                    $('#apellidos').removeClass("ftcb__expres__form-field-err");
                }
                if (!this.user.mail) {
                    $('#email').addClass("ftcb__expres__form-field-err");
                    invalid = true;
                }
                else {
                    $('#email').removeClass("ftcb__expres__form-field-err");
                }
                if (!this.chatControl.validator.isValid(this.user.mail, 'email')) {
                    $('#email').addClass("ftcb__expres__form-field-err");
                    $('#span4').show();
                    invalid = true;
                }
                else {
                    $('#span4').hide();
                    $('#email').removeClass("ftcb__expres__form-field-err");
                }
                if (!repeatEmail || this.user.mail != repeatEmail) {
                    $('#repeatEmail').addClass('ftcb__expres__form-field-err');
                    $('#span2').show();
                    invalid = true;
                }
                else {
                    $('#repeatEmail').removeClass("ftcb__expres__form-field-err");
                    $('#span2').hide();
                }
                if (!this.user.password || !this.chatControl.validator.isValid(this.user.password, 'password')) {
                    $('#password').addClass("ftcb__expres__form-field-err");
                    $('#password .ftcb__expres__form-errTxt span').show();
                    invalid = true;
                }
                else {
                    $('#password').removeClass("ftcb__expres__form-field-err");
                    $('#password .ftcb__expres__form-errTxt span').hide();
                }
                if (!this.user.legal) {
                    $('#span3').show();
                    invalid = true;
                }
                else {
                    $('#span3').hide();
                }
                if (!this.user.age || !this.chatControl.validator.isValid(this.user.age, 'year') || !this.chatControl.validator.setMinYearValidate(this.user.age) || !this.chatControl.validator.setMaxYearValidate(this.user.age)) {
                    $('#year').addClass("ftcb__expres__form-field-err");
                    !this.chatControl.validator.setMinYearValidate(Number(this.user.age)) ? $("#span5").html(textTag(this.enrolmentTexts, 'Registry18') + '<br>').show() :
                        $("#span5").html(textTag(this.enrolmentTexts, 'Registry19') + '<br>').show();
                    invalid = true;
                }
                else {
                    $('#span5').hide();
                    $('#year').removeClass("ftcb__expres__form-field-err");
                }
                if (!recaptcha.isValid())
                    return recaptcha.warning(true);
                recaptcha.warning(false);
                this.user.captcha = recaptcha.getToken();
                this.user.express = 1;
                if (!invalid && this.user.username && this.user.mail && this.user.country
                    && this.user.password && this.user.legal && this.user.age) {
                    this.services.registry((err, user) => {
                        if (err === 409) {
                            console.log(err);
                            $('#span1').show();
                            $('#email').addClass("ftcb__expres__form-field-err");
                            $('#repeatEmail').addClass("ftcb__expres__form-field-err");
                            recaptcha = new CaptchaComponent();
                            recaptcha.render('#captchaContainer');
                        }
                        else if (err == 500) {
                            this.chatControl.errorModal();
                        }
                        else {
                            this.user.iduser = user.id;
                            this.user.avatar = this.icons[`avatar${this.user.gender}`];
                            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'año:' + this.user.age);
                            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'pais:' + textTag(this.chatControl.dictionary.texts.countries, `Country${Object.keys(COUNTRIES_ID)[Object.values(COUNTRIES_ID).indexOf(this.user.country)]}`));
                            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'genero:' + textTag(this.enrolmentTexts, `Intro${this.user.gender + 7}`));
                            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'Fin Registro');
                            callback();
                        }
                    });
                }
            });
        };
        this.functionWaterfall = [
            this.startExpres,
            this.setUserData,
            this.end
        ];
    }
    setValidation() {
        this.chatControl.validator.setNumberDimension("#year .ftcb__inputcontainer__textfield__input", 4);
        this.chatControl.validator.setNumberDimension("#phone .ftcb__inputcontainer__textfield__input", 12);
    }
}
