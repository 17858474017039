var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { textTag } from "../TextTag";
import { CaptchaComponent } from "../enrolment/CaptchaComponent";
export class ProfileChPwd {
    constructor(personalData) {
        this.personalData = personalData;
        this.template = `
    <div class ="ftcb__profile__change-pwd ftcb__profile__change-pwd--closed">
      <div class =" ftcb__profile__form-field" id="currentPwd">
        <div class="ftcb__profile__form-title">${textTag(this.personalData.chatCtrl.dictionary.texts.profile, 'ChPass1')}</div>
        <div class="ftcb__profile__form-input">
          <input type="password" name="currentPwd" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">
        </div>
        <div class="ftcb__profile__form-errTxt"><span>${textTag(this.personalData.chatCtrl.dictionary.texts.profile, 'Error')}</span></div>
      </div>
      <div class =" ftcb__profile__form-field" id="newPwd">
        <div class="ftcb__profile__form-title">${textTag(this.personalData.chatCtrl.dictionary.texts.profile, 'ChPass2')}</div>
        <div class="ftcb__profile__form-input">
          <input type="password" name="newPwd" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">         
        </div>
        <div class="ftcb__profile__form-errTxt"><span>La contraseña tiene que tener al menos 8 caracteres, una mayúscula, una minúscula, un número y algún caracter espcial (!@#$%^&*_=+-)</span></div>
      </div>
      <div class =" ftcb__profile__form-field" id="repeatPwd">
        <div class="ftcb__profile__form-title">${textTag(this.personalData.chatCtrl.dictionary.texts.profile, 'ChPass3')}</div>
        <div class="ftcb__profile__form-input">
          <input type="password" name="repeatPwd" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">
        </div>
        <div class="ftcb__profile__form-errTxt"><span>${textTag(this.personalData.chatCtrl.dictionary.texts.profile, 'Error')}</span></div>
      </div>
      <div id="captchaContainer" class="ftcb__captcha__container"></div>
      <div class ="ftcb__profile__form">
        <div class="ftcb__profile__btn-change">
          <div class="ftcb__profile__btn-titlenoimage">${textTag(this.personalData.chatCtrl.dictionary.texts.profile, 'ChPass4')}</div>
        </div>
      </div>
    </div>  

  `;
        this.services = this.personalData.profileServices;
    }
    bind() {
        let recaptcha = new CaptchaComponent();
        recaptcha.render('#captchaContainer');
        $(".ftcb__profile__change-pwd .ftcb__profile__btn-change").click(() => __awaiter(this, void 0, void 0, function* () {
            $(".ftcb__profile__change-pwd .ftcb__profile__form-field").removeClass('ftcb__profile__form-field-err');
            const currentPwd = $('.ftcb__profile__change-pwd input[name ="currentPwd"]').val() + '';
            const newPwd = $('.ftcb__profile__change-pwd input[name ="newPwd"]').val() + '';
            const repeatPwd = $('.ftcb__profile__change-pwd input[name ="repeatPwd"]').val() + '';
            let validated = true;
            if (!currentPwd) {
                validated = false;
                $('#currentPwd').addClass("ftcb__profile__form-field-err");
            }
            if (!newPwd) {
                validated = false;
                $('#newPwd').addClass("ftcb__profile__form-field-err");
            }
            if (!repeatPwd) {
                validated = false;
                $('#repeatPwd').addClass("ftcb__profile__form-field-err");
            }
            if (newPwd !== repeatPwd) {
                validated = false;
                $('#repeatPwd').addClass("ftcb__profile__form-field-err");
                $('#newPwd').addClass("ftcb__profile__form-field-err");
            }
            const re = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,100}$/);
            if (!re.test(repeatPwd)) {
                validated = false;
                $('#newPwd').addClass("ftcb__profile__form-field-err");
            }
            if (!validated) {
                return;
            }
            if (!recaptcha.isValid())
                return recaptcha.warning(true);
            recaptcha.warning(false);
            const data = {
                mail: this.personalData.chatCtrl.userEmail,
                password: currentPwd,
                newPassword: newPwd,
                captcha: recaptcha.getToken()
            };
            this.services.chpass(data, (err, user) => {
                if (err == 400) {
                    $('#currentPwd').addClass("ftcb__profile__form-field-err");
                    recaptcha = new CaptchaComponent();
                    recaptcha.render('#captchaContainer');
                }
                else if (err == 409) {
                    $('#newPwd').addClass("ftcb__profile__form-field-err");
                    recaptcha = new CaptchaComponent();
                    recaptcha.render('#captchaContainer');
                }
                else {
                    $('.ftcb__profile__change-pwd input[name ="currentPwd"]').val('');
                    $('.ftcb__profile__change-pwd input[name ="newPwd"]').val('');
                    $('.ftcb__profile__change-pwd input[name ="repeatPwd"]').val('');
                    $(".ftcb__profile__change-pwd").addClass('ftcb__profile__change-pwd--closed');
                }
            });
        }));
    }
}
