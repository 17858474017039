var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EnrolmentComponent } from "./EnrolmentComponent";
import { textTag } from "../TextTag";
import { CaptchaComponent } from "./CaptchaComponent";
export class LoginForm {
    constructor(loginCtrl) {
        this.loginCtrl = loginCtrl;
        this.template = () => {
            const texts = this.loginCtrl.enrolmentTexts;
            return `
    <div class="ftcb__msg ftcb__msg--is-bot">
      <div class="bot-icon"></div>
        <span class="ftcb__msg__text">
        ${textTag(texts, "Login1")}
        </span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn" id="formLogin">
      <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="2000">
        <div class="ftcb__msg__cards">
          <div class="ftcb__msg__buttoncontainer">
            
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <div class="ftcb__profile__form-title">${textTag(texts, "Registry2")}</div>
              <input type="email" name="email"  class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">             
            </div>   
            <div class="ftcb__msg__btn ftcb__enrolment__input ftcb__enrolment__input__custom">
              <div class="ftcb__profile__form-title">${textTag(texts, "Registry3")}</div>
              <input type="password" name="password" class="ftcb__inputcontainer__textfield__input" onclick="$(this).focus();">             
            </div> 
            <div id="captchaContainer" class="ftcb__captcha__container">
            </div>
            <div class="ftcb__enrolment__login-errror"><span>${textTag(texts, "Login3")}</span></div>
          </div>
        </div>

      </div>
      <div class="ftcb__msg--is-user">
        <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"></div>
        <span class="ftcb__msg__text">${textTag(texts, "Registry8")}</span>
      </div>
    </div>
    <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" id="endLogin">
    <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="4000">
      <div class="ftcb__msg__cards">
        <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">          
          <div class="ftcb__msg__btn">
            <div class="ftcb__msg__btn-content">
              <div class="ftcb__msg__btn-titlenoimage">${textTag(texts, "Registry7")}</div>
            </div>
          </div>   
          <div class="ftcb__enrolment__warning ftcb__enrolment__warning-clickable"><span>${textTag(texts, "Login4")}</span></div>  
        </div>
      </div>
    </div>
    `;
        };
        this.container = new EnrolmentComponent(this.loginCtrl, "enrolmentLogin", "ftcb__enrolment__screen");
        this.start = (callback) => {
            this.container.setContent(this.template());
            this.container.inject(this.loginCtrl.container.component, true);
            let recaptcha = new CaptchaComponent();
            recaptcha.render('#captchaContainer');
            $("#endLogin .ftcb__msg__btn").click((ev) => __awaiter(this, void 0, void 0, function* () {
                if (!recaptcha.isValid())
                    return recaptcha.warning(true);
                recaptcha.warning(false);
                this.loginCtrl.user.captcha = recaptcha.getToken();
                $("input, #formLogin div").removeClass("ftcb__enrolment__btn_err");
                $(".ftcb__enrolment__login-errror span").hide();
                this.loginCtrl.user.mail = $('input[name ="email"]').val() + "";
                this.loginCtrl.user.password = $('input[name ="password"]').val() + "";
                let invalid = false;
                if (!this.loginCtrl.user.mail) {
                    $('input[name ="email"]').addClass("ftcb__enrolment__btn_err");
                    invalid = true;
                }
                if (!this.loginCtrl.user.password) {
                    $('input[name ="password"]').addClass("ftcb__enrolment__btn_err");
                    invalid = true;
                }
                if (!invalid) {
                    this.loginCtrl.services.login((err, user) => {
                        if (err === 404) {
                            $(".ftcb__enrolment__login-errror span").show();
                            $('input[name ="email"]').addClass("ftcb__enrolment__btn_err");
                            $('input[name ="password"]').addClass("ftcb__enrolment__btn_err");
                            recaptcha = new CaptchaComponent();
                            recaptcha.render('#captchaContainer');
                        }
                        else if (err === 500) {
                            callback(err);
                        }
                        else {
                            this.loginCtrl.user.iduser = user.id;
                            this.loginCtrl.user.express = user.express;
                            this.loginCtrl.user.avatar = this.loginCtrl.icons[`avatar${this.loginCtrl.user.gender}`];
                            this.loginCtrl.chatControl.profileServices.getUser((err, res) => {
                                this.loginCtrl.user.avatar = this.loginCtrl.icons[`avatar${res.idgender}`];
                                $('#ftcb__avatar__menu').attr('src', this.loginCtrl.user.avatar);
                            }, this.loginCtrl.user.iduser);
                            $(`#formLogin, #endLogin`).addClass("ftcb__msg--answered");
                            $("#enrolmentLogin div").unbind();
                            callback(null);
                        }
                    });
                }
            }));
            $("#endLogin .ftcb__enrolment__warning-clickable").click(() => {
                this.loginCtrl.startRecover(callback);
            });
        };
    }
}
