const moment = require("moment");
export class Validator {
    constructor() {
        this.regexList = {
            email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            age: /^[1-9][0-9]?$/,
            year: /^\d{1,4}$/,
            postcode: /^\d{1,10}$/,
            phone: /^\d{1,12}$/,
            name: /[!0123456789€@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
            surname: /[!0123456789€@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
            country: /[A-z]{3,}/,
            subject: /[A-z]{3,}/,
            password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,100}$/,
        };
    }
    setNumberDimension(element, dimension) {
        $(element).on("keydown", function (e) {
            if (e.which >= 48 && $(this).val().toString().length > dimension - 1) {
                return false;
            }
        });
    }
    setMinYearValidate(element) {
        let year = moment(element, "YYYY");
        if (moment().diff(year, "year") < 14) {
            return false;
        }
        else {
            return true;
        }
    }
    setMaxYearValidate(element) {
        let year = moment(element, "YYYY");
        if (moment().diff(year, "year") >= 100) {
            return false;
        }
        else {
            return true;
        }
    }
    isValid(value, type) {
        const regex = this.regexList[type];
        const re = new RegExp(regex);
        if (type === 'name' || type === 'surname') {
            return !re.test(value);
        }
        if (re.test(value)) {
            return true;
        }
        else {
            return false;
        }
    }
}
