import { PopUp } from "../PopUpScreen";
import { StaticsControl } from "../staticsControl";
import { ProfileTestButton } from "./ProfileTestButton";
import { textTag } from "../TextTag";
import { ProfileDigitalButton } from "./ProfileDigitalButton";
import { ProfileCareersContainer } from "./ProfileCareersContainer";
export class ProfileCareers {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.icons = StaticsControl.statics;
        this.userSkills = {
            field_skills: [],
            soft_skills: [],
            hard_skills: []
        };
        this.userSkillsList = [];
        this.template = `
    <div class="ftcb__profile__popUp__header">
        <div class="ftcb__profile__popUp__header-image">  
          <img src="${this.icons.profileOptionIcon3}">
        </div>
      <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Careers')}</div>
    </div>
  `;
        this.testCallback = () => {
            this.chatCtrl.sendSkillsTestEnd(this.chatCtrl.iduser);
            new ProfileCareers(this.chatCtrl);
        };
        this.profileServices = this.chatCtrl.profileServices;
        this.profileServices.getUser((err, response) => {
            if (err)
                return this.chatCtrl.errorModal();
            this.userSkills.field_skills = response.field_skills || '';
            this.userSkills.soft_skills = response.soft_skills || '';
            this.userSkills.hard_skills = response.hard_skills || '';
            this.userSkillsList = this.userSkills.soft_skills.split(',').concat(this.userSkills.hard_skills.split(','));
            if (this.userSkills.field_skills.length === 0 || this.userSkills.soft_skills.length === 0) {
                this.profileTestButton = new ProfileTestButton(this, this.testCallback);
                this.testButton = `
          <div class="ftcb__profile__test-container" >
            ${this.profileTestButton.template}
          </div>`;
                this.template += this.testButton;
                this.popUp = new PopUp('profileCareers', this.template);
                this.bindSkillsTestButton();
            }
            else if (this.userSkills.hard_skills.length === 0) {
                this.profileDigitalButton = new ProfileDigitalButton(this, this.testCallback);
                this.digitalButton = `
          <div class="ftcb__profile__test-container" >
            ${this.profileDigitalButton.template}
          </div>`;
                this.template += this.digitalButton;
                this.popUp = new PopUp('profileCareers', this.template);
                this.bindSkillsDigitalButton();
            }
            else {
                this.profileServices.getUserCareers(this.userSkills, (err, response) => {
                    if (err)
                        return this.chatCtrl.errorModal();
                    this.userCareers = response;
                    this.careersContainer = new ProfileCareersContainer(this);
                    this.template = this.template + this.careersContainer.template;
                    this.popUp = new PopUp('profileCareers', this.template);
                    this.bind();
                });
            }
            ;
        });
    }
    bindSkillsTestButton() {
        this.profileTestButton.bind();
    }
    bindSkillsDigitalButton() {
        this.profileDigitalButton.bind();
    }
    bind() {
        $(`.ftcb__profile__careers .ftcb__cardcourse__header`).click((event) => {
            console.log();
            const courseDiv = $(event.currentTarget).parent();
            if (courseDiv.hasClass('ftcb__profile__course--closed')) {
                const profileName = $(event.currentTarget).data('profilename');
                this.chatCtrl.gaConnection.sendGAEvent('perfil', 'ev_careers', profileName);
                courseDiv.removeClass('ftcb__profile__course--closed');
            }
            else {
                courseDiv.addClass('ftcb__profile__course--closed');
            }
        });
        this.careersContainer.bind();
    }
}
