import { StaticsControl } from "../staticsControl";
import { textTag } from "../TextTag";
export class ProfileSkillsSearch {
    constructor(profileSkills) {
        this.profileSkills = profileSkills;
        this.icons = StaticsControl.statics;
        this.skillsList = [];
        this.isOver = false;
        this.template = `
    <div class="ftcb__profile__search">
      <div class="ftcb__profile__search-searchBar">
        <input type="text" id="profileSearchInput" placeholder=${textTag(this.profileSkills.chatCtrl.dictionary.texts.profile, 'SkillsSearchPlaceholder')}>
        <img src="${this.icons.profileSearchIcon}">
      </div>
      <div class="ftcb__profile__search-optionList">
        <ul></ul> 
      </div>
    </div>
    <div class="ftcb__profile__search-text">
      ${textTag(this.profileSkills.chatCtrl.dictionary.texts.profile, 'SkillsSearchText')}
    </div>
  `;
    }
    bind() {
        $('.ftcb__profile__switch-button').click(() => {
            this.cleanSearcher();
        });
        $('.ftcb__profile__search input').keyup(() => {
            let userSkills;
            if (this.profileSkills.currentSwitchOption === "hard_skills") {
                this.skillsList = this.profileSkills.chatCtrl.allHardSkills;
                userSkills = this.profileSkills.userInfo.hard_skills;
            }
            else {
                this.skillsList = this.profileSkills.chatCtrl.allSoftSkillsSkills;
                userSkills = this.profileSkills.userInfo.soft_skills;
            }
            this.skillsList = this.skillsList.filter((item) => {
                const value = $('.ftcb__profile__search input').val();
                const alreadyExists = userSkills.filter((item2) => item2.identifier == item.identifier).length;
                const control = item.name.toLowerCase().includes(value.toLowerCase());
                return control && !alreadyExists;
            });
            this.skillsList = this.skillsList.sort(function (a, b) {
                if (a.name.length > b.name.length) {
                    return 1;
                }
                if (a.name.length < b.name.length) {
                    return -1;
                }
                return 0;
            });
            this.printSelectables(this.skillsList.slice(0, 5));
        });
        $('.ftcb__profile__search-optionList').mouseover(() => {
            this.isOver = true;
        }).mouseleave(() => {
            this.isOver = false;
        });
        $('.ftcb__profile__search input').focusout(() => {
            if (!this.isOver) {
                $(".ftcb__profile__search-optionList").hide();
            }
        });
        $('.ftcb__profile__search input').focusin(() => {
            $(".ftcb__profile__search-optionList").show();
        });
    }
    printSelectables(skillsList) {
        $(".ftcb__profile__search-optionList ul").html("");
        skillsList.forEach(item => {
            const option = `
        <li data-identifier="${item.identifier}">${item.name}</li>
      `;
            $(".ftcb__profile__search-optionList ul").append(option);
        });
        this.bindListItems();
    }
    updateSkillsList(targetSkillId) {
        const targetSkill = this.profileSkills.addSkillToList(targetSkillId);
        this.profileSkills.profileSkillsContainer.appendSkill(targetSkill);
    }
    bindListItems() {
        $(".ftcb__profile__search-optionList li").click((event) => {
            $(".ftcb__profile__search-optionList").hide();
            const targetSkillId = $(event.currentTarget).data('identifier');
            this.updateSkillsList(targetSkillId);
            this.cleanSearcher();
        });
    }
    cleanSearcher() {
        this.skillsList = [];
        $('.ftcb__profile__search input').val('');
        this.printSelectables(this.skillsList.slice(0, 3));
    }
}
