import { EnrolmentTagCloud } from "./EnrolmentTagCloud";
import { textTag } from "../TextTag";
export class EnrolmentFieldSkills extends EnrolmentTagCloud {
    constructor(enrolmentCtrl) {
        super(enrolmentCtrl, "enrolmentFieldSkills", (skills) => {
            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_eleccion', 'Ambitos:' + skills);
            this.enrolmentCtrl.chatControl.gaConnection.sendGAEvent('enrolment', 'ev_completado', 'Ambitos');
        });
        this.enrolmentCtrl = enrolmentCtrl;
        this.start = (callback) => {
            this.skillsArray = this.enrolmentCtrl.user.fieldSkills;
            this.optionTags = this.enrolmentCtrl.fieldSkillsOptions;
            this.questionTxt = this.enrolmentCtrl.fieldSkillsQuestion[0].question.replace("[name]", this.enrolmentCtrl.user.username);
            this.buttonTxt = textTag(this.enrolmentCtrl.enrolmentTexts, 'SoftSkills3');
            this.motivationTxt = textTag(this.enrolmentCtrl.enrolmentTexts, 'Motivation5');
            this.create(() => {
                if (!this.skillsArray.length) {
                    this.enrolmentCtrl.noFieldsEnd(callback);
                }
                else {
                    callback();
                }
            });
        };
    }
}
