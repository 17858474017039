import { EnrolmentComponent } from "./EnrolmentComponent";
import { EnrolmentMotivation } from "./EnrolmentMotivation";
export class EnrolmentTagCloud {
    constructor(enrolmentCtrl, elementName, analytics) {
        this.enrolmentCtrl = enrolmentCtrl;
        this.elementName = elementName;
        this.analytics = analytics;
        this.questionTemplate = () => {
            return `
      <div class="ftcb__msg ftcb__msg--is-bot">
        <div class="bot-icon"></div>
        <span class="ftcb__msg__text">${this.questionTxt}</span>
      </div>
      <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__tags-container">
        <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="2000">
          <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__custom ftcb__enrolment__buttoncontainer ftcb__profile__skills-cloud ftcb__enrolment__optioncontainer">
              <div></div>
            </div>
          </div>
        </div>
        <div class="ftcb__msg--is-user">
          <div class="ftcb__msg--is-user ftcb__avatar-icon ftcb__avatar__custom"   style="background-image:url('${this.enrolmentCtrl.user.avatar}');"></div>
          <span class="ftcb__msg__text"></span>
        </div>
      </div>
      <div class="ftcb__msg ftcb__msg--has-btn ftcb__enrolment__send" id="end${this.elementName}">
        <div class="ftcb__msg--is-bot ftcb__msg--delay" data-delay="4000">
          <div class="ftcb__msg__cards">
            <div class="ftcb__msg__buttoncontainer ftcb__enrolment__buttoncontainer">
              
              <div class="ftcb__msg__btn">
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-titlenoimage">${this.buttonTxt}</div>
                </div>
              </div>   
                  
            </div>
          </div>
        </div>
      </div>
    `;
        };
        this.optionTemplate = (option) => {
            return `
      <div class="ftcb__msg__btn ftcb__profile__skill--unselected"  >
        <div class="ftcb__msg__btn-content">
          <div class="ftcb__msg__btn-titlenoimage" data-option="${option.identifier}">${option.name}</div>
        </div>
      </div> 
    `;
        };
        this.bindUnselected = () => {
            $(`#${this.elementName}  .ftcb__profile__skill--unselected`).unbind();
            $(`#${this.elementName}  .ftcb__profile__skill--unselected`).click((event) => {
                if (!this.motivation) {
                    this.motivation = new EnrolmentMotivation(this.motivationTxt, this.container.component, this.enrolmentCtrl);
                    this.motivation.start();
                }
                $(event.currentTarget).addClass("ftcb__profile__skill--selected");
                $(event.currentTarget).removeClass("ftcb__profile__skill--unselected");
                this.skillsArray.push(this.optionTags.find((item => item.identifier == $(event.currentTarget).find('.ftcb__msg__btn-titlenoimage').data('option'))));
                this.bindSelected();
                this.bindUnselected();
            });
        };
        this.bindSelected = () => {
            $(`#${this.elementName}  .ftcb__profile__skill--selected`).unbind();
            $(`#${this.elementName}  .ftcb__profile__skill--selected`).click((event) => {
                $(event.currentTarget).addClass("ftcb__profile__skill--unselected");
                $(event.currentTarget).removeClass("ftcb__profile__skill--selected");
                this.skillsArray = this.skillsArray.filter(item => item.identifier != $(event.currentTarget).find('.ftcb__msg__btn-titlenoimage').data('option'));
                this.bindSelected();
                this.bindUnselected();
            });
        };
        this.container = new EnrolmentComponent(this.enrolmentCtrl, this.elementName, 'ftcb__enrolment__screen');
    }
    ;
    create(callback) {
        this.container.setContent(this.questionTemplate());
        this.container.inject(this.enrolmentCtrl.container.component);
        this.optionTags.forEach(option => {
            const htmlCode = this.optionTemplate(option);
            $(`#${this.elementName}  .ftcb__enrolment__optioncontainer > div`).append(htmlCode);
        });
        this.enrolmentCtrl.scroll();
        this.bindUnselected();
        $(`#end${this.elementName}`).click(() => {
            if (this.motivation) {
                this.motivation.stop();
            }
            $(`#end${this.elementName}`).addClass("ftcb__msg--answered");
            $(`#${this.elementName} div`).unbind();
            $(`#${this.elementName} .ftcb__enrolment__tags-container`).addClass("ftcb__msg--answered");
            const skills = this.skillsArray.map((item) => item.name).join(', ');
            if (skills) {
                $(`#${this.elementName} .ftcb__msg--is-user span`).html(skills);
            }
            else {
                $(`#${this.elementName} .ftcb__msg--is-user`).remove();
            }
            this.enrolmentCtrl.scroll();
            this.enrolmentCtrl.progress.advance();
            this.analytics(skills);
            callback(null);
        });
    }
    ;
}
