import { PopUp } from "../PopUpScreen";
import { StaticsControl } from "../staticsControl";
import { ProfileSwitch } from "./ProfileSwitch";
import { ProfileCoursesContainer } from "./ProfileCoursesContainer";
import { ModalScreen } from "../ModalScreen";
import { textTag } from "../TextTag";
export class ProfileCourses {
    constructor(chatCtrl) {
        this.chatCtrl = chatCtrl;
        this.icons = StaticsControl.statics;
        this.currentSwitchOption = "saved";
        this.coursesObject = {
            viewed: [],
            saved: [],
            done: []
        };
        this.skillSitchOptions = [
            {
                option: 'saved',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'CoursesSwitchOpt1')
            },
            {
                option: 'viewed',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'CoursesSwitchOpt2')
            },
            {
                option: 'done',
                text: textTag(this.chatCtrl.dictionary.texts.profile, 'CoursesSwitchOpt3')
            }
        ];
        this.viewedToSavedFunction = (course, callback) => {
            this.profileServices.updateCourses(course.id_curso, 2, callback);
            new ModalScreen({
                title: textTag(this.modalTexts, 'CourseSaved_title'),
                subtitle: textTag(this.modalTexts, 'CourseSaved_subtitle_2'),
                primaryButton: { text: textTag(this.modalTexts, 'Aceptar') }
            });
        };
        this.savedToViewedFunction = (course, callback) => {
            this.profileServices.updateCourses(course.id_curso, 1, callback);
            new ModalScreen({
                title: textTag(this.modalTexts, 'CourseDeleted_title'),
                subtitle: textTag(this.modalTexts, 'CourseDeleted_subtitle'),
                primaryButton: { text: textTag(this.modalTexts, 'Aceptar') }
            });
        };
        this.viewedtodeletedFunction = (course, callback) => {
            new ModalScreen({
                title: textTag(this.modalTexts, 'DeleteCourse_title'),
                subtitle: textTag(this.modalTexts, 'DeleteCourse_subtitle'),
                secondaryButton: { text: textTag(this.modalTexts, 'Cancelar') },
                primaryButton: { text: textTag(this.modalTexts, 'Aceptar'), callback: () => { this.profileServices.deleteCourses(course.id_curso, callback); } }
            });
        };
        this.savedToDoneFunction = (course, callback) => {
            const courseSkills = course.skills.map((skill) => this.doneContainer.skillTemplate(skill)).join('');
            new ModalScreen({
                title: textTag(this.modalTexts, 'CourseDone_title'),
                subtitle: textTag(this.modalTexts, 'CourseDone_subtitle'),
                secondaryButton: { text: textTag(this.modalTexts, 'Cancelar') },
                primaryButton: { text: textTag(this.modalTexts, 'Aceptar'), callback: () => {
                        this.profileServices.updateCourses(course.id_curso, 3, () => {
                            this.profileServices.addSkillsCourse(course.skills, callback);
                        });
                    } },
                customContent: `
      <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud">
        <div>
          ${courseSkills}
        </div>     
      </div>
      `
            });
        };
        this.doneToSavedFunction = (course, callback) => {
            const courseSkills = course.skills.map((skill) => this.doneContainer.skillTemplate(skill)).join('');
            new ModalScreen({
                title: textTag(this.modalTexts, 'CourseSaved_title'),
                subtitle: textTag(this.modalTexts, 'CourseSaved_subtitle'),
                secondaryButton: { text: textTag(this.modalTexts, 'Cancelar') },
                primaryButton: { text: textTag(this.modalTexts, 'Aceptar'), callback: () => { this.profileServices.updateCourses(course.id_curso, 2, callback); } },
                customContent: `
      <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud">
        <div>
          ${courseSkills}
        </div>     
      </div>
      `
            });
        };
        this.doneToViewedFunction = (course, callback) => {
            const courseSkills = course.skills.map((skill) => this.doneContainer.skillTemplate(skill)).join('');
            new ModalScreen({
                title: textTag(this.modalTexts, 'CourseViewed_title'),
                subtitle: textTag(this.modalTexts, 'CourseViewed_subtitle'),
                secondaryButton: { text: textTag(this.modalTexts, 'Cancelar') },
                primaryButton: { text: textTag(this.modalTexts, 'Aceptar'), callback: () => { this.profileServices.updateCourses(course.id_curso, 1, callback); } },
                customContent: `
      <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud">
        <div>
          ${courseSkills}
        </div>     
      </div>
      `
            });
        };
        this.modalTexts = this.chatCtrl.dictionary.texts.modals;
        this.profileServices = this.chatCtrl.profileServices;
        this.profileServices.findCourses((err, userCourses) => {
            if (err)
                return console.log("courses error");
            this.coursesObject = userCourses;
            this.profileSwitch = new ProfileSwitch(this, 'profileCoursesSwitch', this.skillSitchOptions, null);
            this.viewedContainer = new ProfileCoursesContainer(this, { current: 'viewed', next: 'saved', previous: 'deleted' });
            this.savedContainer = new ProfileCoursesContainer(this, { current: 'saved', next: 'done', previous: 'viewed' });
            this.doneContainer = new ProfileCoursesContainer(this, { current: 'done', next: 'saved', previous: 'viewed' });
            this.template = `
        <div class="ftcb__profile__popUp__header">
          <div class="ftcb__profile__popUp__header-image">  
          <img src="${this.icons.profileOptionIcon4}">
          </div>
          <div class="ftcb__profile__popUp__header-title">${textTag(this.chatCtrl.dictionary.texts.profile, 'Header Courses')}</div>
        </div>
        <div class="ftcb__profile__switch-container">
          ${this.profileSwitch.template}
        </div>
        ${this.savedContainer.template}
        ${this.viewedContainer.template}
        ${this.doneContainer.template}

      `;
            this.popUp = new PopUp('profileCourses', this.template);
            this.bind();
        });
    }
    bind() {
        this.profileSwitch.bind();
        this.savedContainer.bind();
        this.viewedContainer.bind();
        this.doneContainer.bind();
    }
    coursesServices(course, state, callback) {
        const coursesServicesObj = {
            viewedsaved: () => this.viewedToSavedFunction(course, callback),
            savedviewed: () => this.savedToViewedFunction(course, callback),
            saveddone: () => this.savedToDoneFunction(course, callback),
            donesaved: () => this.doneToSavedFunction(course, callback),
            doneviewed: () => this.doneToViewedFunction(course, callback),
            vieweddeleted: () => this.viewedtodeletedFunction(course, callback),
        };
        coursesServicesObj[state]();
    }
}
