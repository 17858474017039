import { PopUp } from "./PopUpScreen";
import { ModalScreen } from "./ModalScreen";
import { textTag } from "./TextTag";
export class CourseCard {
    constructor(chatControl, courseMsg) {
        this.chatControl = chatControl;
        this.courseMsg = courseMsg;
        this.saveCourse = () => {
            this.chatControl.profileServices.findCoursesByIds(this.courseMsg.id_curso, (err, resp) => {
                if (!resp.state) {
                    this.chatControl.profileServices.addCourses(this.courseMsg.id_curso, 2, (err) => {
                        if (err)
                            return this.chatControl.errorModal();
                        this.showConfirmationModal();
                    });
                }
                else if (resp.state == 3) {
                    new ModalScreen({
                        title: textTag(this.chatControl.dictionary.texts.modals, 'CourseAlreadyDone_title'),
                        subtitle: textTag(this.chatControl.dictionary.texts.modals, 'CourseAlreadyDone_subtitle'),
                        secondaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Cancelar') },
                        primaryButton: { text: textTag(this.chatControl.dictionary.texts.bot, 'CardCourseSaveButton'), callback: () => { this.updateCourse(); } }
                    });
                }
                else {
                    this.updateCourse();
                }
            });
        };
        this.updateCourse = () => {
            this.chatControl.gaConnection.sendGAEvent('card_cursos', 'ev_curso_estado', 'saved', this.courseMsg.index);
            this.chatControl.profileServices.updateCourses(this.courseMsg.id_curso, 2, (err) => {
                if (err)
                    return this.chatControl.errorModal();
                this.showConfirmationModal();
            });
        };
        this.showConfirmationModal = () => {
            new ModalScreen({
                title: textTag(this.chatControl.dictionary.texts.modals, 'CourseSaved_title'),
                subtitle: textTag(this.chatControl.dictionary.texts.modals, 'CourseSaved_subtitle'),
                primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => {
                        if (this.chatControl.showRating) {
                            this.chatControl.showRating = false;
                            this.chatControl.sendCloseCardCursos();
                        }
                        this.popUp.close();
                    } }
            });
        };
        const skillTemplate = (skill) => {
            return `
        <div class="ftcb__msg__btn ftcb__profile__skill ftcb__profile__skill--selected ${skill.tipo_skill === '0' ? 'ftcb__profile__skill-soft_skills' : ''}">
          <div class="ftcb__msg__btn-content">
            <div class="ftcb__msg__btn-titlenoimage">${skill.nombre_skill}</div>
          </div>
        </div>  
        `;
        };
        const courseSkills = this.courseMsg.skills.map((skill) => skillTemplate(skill)).join('');
        const template = `
        <div class="ftcb__cardcourse__container">
          <div class="ftcb__cardcourse__header">
            <div class="ftcb__cardcourse__header__title-image"> 
              <div class="ftcb__cardcourse__tittle">
                ${this.courseMsg.nombre_curso}
              </div>
              <div class="ftcb__cardcourse__image__img" style="background-image:url(${this.courseMsg.image_url})">
              </div>
            </div>
          </div>
          <div class="ftcb__cardcourse__body">
            <div class="ftcb__cardcourse__description">
              <p>
              ${textTag(this.chatControl.dictionary.texts.course_card, 'CardCourseSaveDes')}
              <p>
            </div>
            <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud">
              <div>
                ${courseSkills}
              </div>     
            </div>
            <div class="ftcb__cardcourse__divisoryLine"></div>
            <div class="ftcb__cardcourse__info">
              <div class="ftcb__cardcourse__info__price ftcb__cardcourse__info__element">
                <div class="ftcb__cardcourse__info__price__img"></div>
                <div class="ftcb__cardcourse__info__price__information">
                  <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.chatControl.dictionary.texts.course_card, 'CardCursosPrecio')}: </div>
                  <div class="ftcb__cardcourse__info__price__information__number">
                    ${this.setPrice(this.courseMsg)}
                  </div>
                </div>
              </div>
              <div class="ftcb__cardcourse__info__time ftcb__cardcourse__info__element">
                <div class="ftcb__cardcourse__info__time__img"></div>
                <div class="ftcb__cardcourse__info__time__information">
                  <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.chatControl.dictionary.texts.course_card, 'CardCursosDuracion')}: </div>
                  <div class="ftcb__cardcourse__info__price__information__number">
                    ${this.setTime(this.courseMsg)}
                  </div>
                </div>
              </div>
              <div class="ftcb__cardcourse__info__cert ftcb__cardcourse__info__element">
                <div class="ftcb__cardcourse__info__cert__img"></div>
                <div class="ftcb__cardcourse__info__cert__information">
                  <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.chatControl.dictionary.texts.course_card, 'CardCursosCertificado')}: </div>
                  <div class="ftcb__cardcourse__info__price__information__number">
                    ${this.courseMsg.certificado_disponible_curso ? textTag(this.chatControl.dictionary.texts.bot, 'Si') : textTag(this.chatControl.dictionary.texts.bot, 'No')} 
                  </div>
                </div>
              </div>
              <div class="ftcb__cardcourse__info__level ftcb__cardcourse__info__element">
                <div class="ftcb__cardcourse__info__level__img"></div>
                <div class="ftcb__cardcourse__info__level__information">
                  <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.chatControl.dictionary.texts.course_card, 'CardCursosNivel')}: </div>
                  <div class="ftcb__cardcourse__info__price__information__number">${this.courseMsg.nivel_curso || textTag(this.chatControl.dictionary.texts.bot, 'None')} </div>
                </div>
              </div>        
            </div>
            <div class="ftcb__cardcourse__divisoryLine"></div> 
            <div class="ftcb__cardcourse__footer ">
              <div class="ftcb__msg__btn ftcb__cardcourse__button--exit">
                <div class="ftcb__msg__btn-content">
                  <div class="">${textTag(this.chatControl.dictionary.texts.bot, 'Save')}</div>
                </div>
              </div> 
              <div class="ftcb__msg__btn ftcb__cardcourse__button--moreinfo">
                <div class="ftcb__msg__btn-content">
                  <div class="ftcb__msg__btn-titlenoimage">${textTag(this.chatControl.dictionary.texts.course_card, 'CardCursosMasInformacion')}</div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      `;
        this.popUp = new PopUp('cardCourse', template);
        $('.ftcb__profile__skill .ftcb__msg__btn-content').unbind();
        if (this.chatControl.iduser) {
            this.chatControl.profileServices.addCourses(this.courseMsg.id_curso, 1, (err) => {
                if (err)
                    return this.chatControl.errorModal();
                this.chatControl.gaConnection.sendGAEvent('card_cursos', 'ev_curso_estado', 'viewed', this.courseMsg.index, false);
            });
        }
        $('.ftcb__cardcourse__button--moreinfo').click((evt) => {
            this.chatControl.gaConnection.sendGAEvent(this.courseMsg.flujo, 'ev_cursos_ver_detalle', this.courseMsg.nombre_curso, this.courseMsg.index);
            window.open(this.courseMsg.url_curso, '_blank');
        });
        $('.ftcb__cardcourse__footer .ftcb__cardcourse__button--exit').click(() => {
            if (!this.chatControl.iduser) {
                new ModalScreen({
                    buttonType: 1,
                    title: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_title_1'),
                    subtitle: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_subtitle'),
                    secondaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_secondary'), callback: () => {
                            this.chatControl.gaConnection.sendGAEvent('registro_express', 'ev_registro', 'card_curso');
                            this.chatControl.startEnrolmentExpres((err) => {
                                if (err)
                                    return;
                                this.chatControl.sendEndEnrolment(this.chatControl.iduser, true);
                                new ModalScreen({
                                    title: textTag(this.chatControl.dictionary.texts.modals, 'CreatedExpress_title'),
                                    subtitle: textTag(this.chatControl.dictionary.texts.modals, 'CreatedExpress_subtitle'),
                                    primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => this.saveCourse() }
                                });
                            });
                        } },
                    primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'LoginExpress_primary'), callback: () => {
                            this.chatControl.startLoginExpres((err) => {
                                if (err)
                                    return;
                                this.chatControl.sendEndLogin(this.chatControl.iduser, true);
                                new ModalScreen({
                                    title: textTag(this.chatControl.dictionary.texts.modals, 'LoggedExpress_title'),
                                    subtitle: textTag(this.chatControl.dictionary.texts.modals, 'LoggedExpress_subtitle'),
                                    primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => this.saveCourse() }
                                });
                            });
                        }
                    },
                });
            }
            else {
                this.saveCourse();
            }
        });
        $('.ftcb__cardcourse__button--moreinfo, #cardCourse .ftcb__enrolment_close').click((evt) => {
            if (this.chatControl.showRating) {
                this.chatControl.showRating = false;
                this.chatControl.sendCloseCardCursos();
            }
            this.popUp.close();
        });
    }
    setTime(course) {
        if (Number(course.duracion_curso) > 0 && Number(course.esfuerzo_maximo_curso) > 0) {
            const hours = Number(course.duracion_curso) * Number(course.esfuerzo_maximo_curso);
            return `${hours} ${textTag(this.chatControl.dictionary.texts.course_card, 'Hora')}`;
        }
        else if (Number(course.duracion_curso) > 0) {
            return `${course.duracion_curso} ${textTag(this.chatControl.dictionary.texts.course_card, 'Semana')}`;
        }
        else {
            return '-';
        }
    }
    setPrice(course) {
        if (Number(course.precio_curso) === 0)
            return textTag(this.chatControl.dictionary.texts.course_card, 'CardCursosPrecioGratuito');
        else if (course.plataforma_curso === 'Udemy' && Number(course.precio_curso) > 0)
            return '-';
        else if (Number(course.precio_curso) > 0)
            return course.precio_curso + ` ${textTag(this.chatControl.dictionary.texts.course_card, 'Moneda')}`;
        else
            return '-';
    }
}
