import { ModalScreen } from "./ModalScreen";
import { textTag } from "./TextTag";
import { BotMsg } from "./Messages";
export class ErrorModal {
    constructor(chatCtrl, callback) {
        this.chatCtrl = chatCtrl;
        this.callback = callback;
        new ModalScreen({
            title: textTag(this.chatCtrl.dictionary.texts.modals, 'ErrorModal_title'),
            subtitle: textTag(this.chatCtrl.dictionary.texts.modals, 'ErrorModal_subtitle'),
            primaryButton: { text: textTag(this.chatCtrl.dictionary.texts.modals, 'Aceptar'), callback: this.callback },
        });
    }
    ;
}
;
export class ErrorFlowMsg {
    constructor(chatCtrl, callback) {
        this.chatCtrl = chatCtrl;
        this.callback = callback;
        let messages = [];
        messages[0] = new BotMsg("BOT_MSG");
        messages[0].text = textTag(this.chatCtrl.dictionary.texts.bot, 'FlowErrorMessage');
        this.callback(messages);
    }
}
export class ErrorConnectionMsg {
    constructor() {
        console.log("Sorry, we have technical problems and at the moment I cannot help you. Try again later.");
    }
}
