import { textTag } from "../TextTag";
export class ProfileCoursesContainer {
    constructor(profileCourses, states) {
        this.profileCourses = profileCourses;
        this.states = states;
        this.skillTemplate = (skill) => {
            return `
    <div class="ftcb__msg__btn ftcb__profile__skill ftcb__profile__skill--selected ${skill.tipo_skill === '0' ? 'ftcb__profile__skill-soft_skills' : ''}">
      <div class="ftcb__msg__btn-content">
        <div class="ftcb__msg__btn-titlenoimage">${skill.nombre_skill}</div>
      </div>
    </div>  
    `;
        };
        this.courseTemplate = (course) => {
            const courseSkills = course.skills.map((skill) => this.skillTemplate(skill)).join('');
            const descriptions = {
                viewed: textTag(this.profileCourses.chatCtrl.dictionary.texts.profile, 'ViewedDescroption'),
                saved: textTag(this.profileCourses.chatCtrl.dictionary.texts.profile, 'SavedDescription'),
                done: textTag(this.profileCourses.chatCtrl.dictionary.texts.profile, 'DoneDescription')
            };
            return `
      <div class="ftcb__profile__course ftcb__profile__course--closed ftcb__cardcourse__container" data-courseId="${course.id_curso}">
        <div class="ftcb__cardcourse__header">
          <div class="ftcb__cardcourse__header__title-image"> 
            <div class="ftcb__cardcourse__tittle">
              ${course.nombre_curso}
            </div>
            <div class="ftcb__cardcourse__image__img"  style="background-image:url(${course.image_url})"></div>
          </div>
        </div>
        <div class="ftcb__cardcourse__body">
          <div class="ftcb__cardcourse__description">
            <p>${descriptions[this.states.current]}</p>
          </div>
          <div class="ftcb__cardcourse__skills ftcb__profile__skills-cloud">
            <div>
              ${courseSkills}
            </div>     
          </div>
          <div class="ftcb__cardcourse__divisoryLine"></div>
          <div class="ftcb__cardcourse__stateSwitch">
            <div class="ftcb__cardcourse__stateSwitch-text"> 
              ${this.states.current == 'viewed' ?
                textTag(this.profileCourses.chatCtrl.dictionary.texts.profile, 'SaveCourse') :
                textTag(this.profileCourses.chatCtrl.dictionary.texts.profile, 'CourseDone')} 
            </div>
            <div class="ftcb__cardcourse__stateSwitch-switch">
              <div class="ftcb__cardcourse__stateSwitch-bar"></div>
              <div class="ftcb__cardcourse__stateSwitch-circle"></div>
            </div>
          </div>
          <div class="ftcb__cardcourse__divisoryLine"></div>
          <div class="ftcb__cardcourse__info">
            <div class="ftcb__cardcourse__info__price  ftcb__cardcourse__info__element">
              <div class="ftcb__cardcourse__info__price__img"></div>
              <div class="ftcb__cardcourse__info__price__information">
                <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'CardCursosPrecio')}: </div>
                <div class="ftcb__cardcourse__info__price__information__number">
                  ${this.setPrice(course)}
                </div>
              </div>
            </div>
            <div class="ftcb__cardcourse__info__time ftcb__cardcourse__info__element">
              <div class="ftcb__cardcourse__info__time__img"></div>
              <div class="ftcb__cardcourse__info__time__information">
                <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'CardCursosDuracion')}: </div>
                <div class="ftcb__cardcourse__info__price__information__number">
                  ${this.setTime(course)}
                </div>
              </div>
            </div>
            <div class="ftcb__cardcourse__info__cert ftcb__cardcourse__info__element">
              <div class="ftcb__cardcourse__info__cert__img"></div>
              <div class="ftcb__cardcourse__info__cert__information">
                <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'CardCursosCertificado')}:</div>
                <div class="ftcb__cardcourse__info__price__information__number">
                  ${course.certificado_disponible_curso ? textTag(this.profileCourses.chatCtrl.dictionary.texts.bot, 'Si') : textTag(this.profileCourses.chatCtrl.dictionary.texts.bot, 'No')} 
                </div>
              </div>
            </div>
            <div class="ftcb__cardcourse__info__level ftcb__cardcourse__info__element">
              <div class="ftcb__cardcourse__info__level__img"></div>
              <div class="ftcb__cardcourse__info__level__information">
                <div class="ftcb__cardcourse__info__price__information__text">${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'CardCursosNivel')}:</div>
                <div class="ftcb__cardcourse__info__price__information__number">${course.nivel_curso || textTag(this.profileCourses.chatCtrl.dictionary.texts.bot, 'None')} </div>
              </div>
            </div>          
          </div>
          <div class="ftcb__cardcourse__divisoryLine"></div>
          <div class="ftcb__cardcourse__footer">
            <div class="ftcb__cardcourse__footer-delete">
              <div class="ftcb__cardcourse__footer-delete-text">${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'Delete')}:</div>
              <div class="ftcb__cardcourse__footer-delete-img"></div>
            </div>
            <div class="ftcb__cardcourse__footer-link">
            <div class="ftcb__cardcourse__footer-link-a" >
              <a href="${course.url_curso.substring(0, 4) == ('http') ? course.url_curso : 'http://' + course.url_curso}"  target="_blank">${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'CardCursosMasInformacion')}</a>
            </div>
            <div class="ftcb__cardcourse__footer-link-img"></div>
            </div>
          </div>
        </div>
      </div>  
    `;
        };
        this.courseList = this.profileCourses.coursesObject[states.current];
        this.template = `
      <div class="ftcb_profile-switchPage_${this.states.current} ftcb_profile-switchPage ftcb__profile__courses-container">
      </div>
    `;
    }
    addCourseToContainer(course) {
        const template = this.courseTemplate(course);
        $(`.ftcb_profile-switchPage_${this.states.current}`).append(template);
        this.bindCourses();
    }
    bind() {
        this.courseList.forEach((item) => {
            this.addCourseToContainer(item);
        });
        $('.ftcb__profile__skill .ftcb__msg__btn-content').unbind();
    }
    bindCourses() {
        $(`.ftcb_profile-switchPage_${this.states.current} .ftcb__cardcourse__header`).unbind();
        $(`.ftcb_profile-switchPage_${this.states.current} .ftcb__cardcourse__header`).click((event) => {
            const courseDiv = $(event.currentTarget).parent();
            if (courseDiv.hasClass('ftcb__profile__course--closed')) {
                courseDiv.removeClass('ftcb__profile__course--closed');
            }
            else {
                courseDiv.addClass('ftcb__profile__course--closed');
            }
        });
        $(`.ftcb_profile-switchPage_${this.states.current} .ftcb__cardcourse__stateSwitch-switch`).unbind();
        $(`.ftcb_profile-switchPage_${this.states.current} .ftcb__cardcourse__stateSwitch-switch`).click((event) => {
            this.updateState(event, this.states.current, this.states.next);
        });
        $(`.ftcb_profile-switchPage_${this.states.current} .ftcb__cardcourse__footer-delete`).unbind();
        $(`.ftcb_profile-switchPage_${this.states.current} .ftcb__cardcourse__footer-delete`).click((event) => {
            event.preventDefault();
            this.updateState(event, this.states.current, this.states.previous);
        });
    }
    updateState(event, from, to) {
        const courseDiv = $(event.currentTarget).parent().parent().parent();
        const courseId = courseDiv.data('courseid');
        const course = this.profileCourses.coursesObject[from].find((item) => item.id_curso == courseId);
        this.profileCourses.coursesServices(course, from + to, (err) => {
            this.profileCourses.chatCtrl.gaConnection.sendGAEvent('courses', 'ev_curso_estado', `pSt:${from}|cSt:${to}|`);
            if (err)
                return this.profileCourses.chatCtrl.errorModal();
            if (to !== 'deleted')
                this.profileCourses.coursesObject[to].push(course);
            this.profileCourses.coursesObject[from] = this.profileCourses.coursesObject[from].filter((item) => item.id_curso != courseId);
            courseDiv.remove();
            if (to !== 'deleted')
                this.profileCourses[`${to}Container`].addCourseToContainer(course);
        });
    }
    setTime(course) {
        if (Number(course.duracion_curso) > 0 && Number(course.esfuerzo_maximo_curso) > 0) {
            const hours = Number(course.duracion_curso) * Number(course.esfuerzo_maximo_curso);
            return `${hours} ${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'Hora')}`;
        }
        else if (Number(course.duracion_curso) > 0) {
            return `${course.duracion_curso} ${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'Semana')}`;
        }
        else {
            return '-';
        }
    }
    setPrice(course) {
        if (Number(course.precio_curso) === 0)
            return textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'CardCursosPrecioGratuito');
        else if (course.plataforma_curso === 'Udemy' && Number(course.precio_curso) > 0)
            return '-';
        else if (Number(course.precio_curso) > 0)
            return course.precio_curso + ` ${textTag(this.profileCourses.chatCtrl.dictionary.texts.course_card, 'Moneda')}`;
        else
            return '-';
    }
}
