const config = require("./../../../config.js");
export class CaptchaComponent {
    constructor() {
        $('#recaptchaScript').remove();
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.id = 'recaptchaScript';
        document.body.append(script);
    }
    isValid() {
        var _a;
        let token = (_a = $('.g-recaptcha-response').val()) === null || _a === void 0 ? void 0 : _a.toString();
        return token && token !== '';
    }
    getToken() {
        return $('.g-recaptcha-response').val().toString();
    }
    warning(warning) {
        warning
            ? $('#g-recaptcha').css({
                'border-color': 'red',
                'border': 'solid red',
                'border-width': '2px 0px 0px 2px',
                'display': 'inline-block',
                'background': 'red',
                'border-radius': '5px'
            })
            : $('#g-recaptcha').css({
                'border': 'none',
                'background': 'transparent'
            });
    }
    render(el) {
        this.element = el;
        $('#g-recaptcha').remove();
        const templ = `
    <div class="g-recaptcha" id="g-recaptcha" 
                data-sitekey="${RECAPTCHA_PUBLIC_KEY}"></div>
    `;
        $(this.element).html(templ);
    }
}
