export class JSonResponse {
}
export class PayloadType {
}
export class ChatResponse {
}
export class ChatElement {
}
export class GraphSkill extends ChatElement {
}
export class CourseInfo extends ChatElement {
}
export class CardTranslations extends ChatElement {
}
export class CardButton {
}
export class RatingData {
}
