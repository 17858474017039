import { ModalScreen } from "../ModalScreen";
import { EnrolmentController } from "./EnrolmentController";
import { textTag } from "../TextTag";
export class DigitalSkillsTestController extends EnrolmentController {
    constructor() {
        super(...arguments);
        this.profileServices = this.chatControl.profileServices;
        this.setPayloadResponse = (callback) => {
            this.chatControl.currentPayloadResponse = 'start_digital_skills_test';
            callback(null);
        };
        this.calculateResults = (callback) => {
            const answers = this.user.digitalSkills.answers;
            new Promise((resolve, reject) => {
                this.profileServices.getAllSkills("digital_skills", (err, resp) => {
                    this.user.digitalSkills.skills = resp;
                    resolve('OK');
                });
            }).then(() => {
                var score = 0;
                var index = 0;
                for (let i = 0; i < answers.length; i++) {
                    if (answers[i].answerType && answers[i].answerType == "a") {
                        score += 1.25;
                    }
                    if ((i + 1) % 8 === 0) {
                        this.user.digitalSkills.skills[index].identifier = this.user.digitalSkills.skills[index].identifier + '|' + (score * 10).toString();
                        index += 1;
                        score = 0;
                    }
                }
                callback(null);
            });
        };
        this.endTest = () => {
            const skills = {
                digital_skills: this.user.digitalSkills.skills
            };
            this.chatControl.profileServices.updateUserSkills(skills, (err) => {
                setTimeout(() => {
                    if (err) {
                        this.chatControl.errorModal(() => this.end());
                    }
                    else {
                        new ModalScreen({
                            title: textTag(this.chatControl.dictionary.texts.modals, 'SkillsTestEnd_title'),
                            subtitle: textTag(this.chatControl.dictionary.texts.modals, 'SkillsTestEnd_subtitle'),
                            primaryButton: { text: textTag(this.chatControl.dictionary.texts.modals, 'Aceptar'), callback: () => { this.end(); } },
                        });
                    }
                }, 2000);
            });
        };
        this.functionWaterfall = [
            this.setPayloadResponse,
            this.progress.start,
            this.digitalSkills.start,
            this.calculateResults,
            this.endTest
        ];
    }
}
