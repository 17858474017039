import { EnrolmentComponent } from "./EnrolmentComponent";
import { EnrolmentIntro } from "./EnrolmentIntro";
import { EnrolmentSoftSkills } from "./EnrolmentSoftSkills";
import { EnrolmentFieldSkills } from "./EnrolmentFieldSkills";
import { EnrolmentHardSkills } from "./EnrolmentHardSkills";
import { EnrolmentDigitalSkills } from "./EnrolmentDigitalSkills";
import { EnrolmentServices } from "./EnrolmentServices";
import { EnrolmentProgress } from "./EnrolmentProgress";
import { StaticsControl } from "../staticsControl";
import { EnrolmentError } from "./EnrolmentError";
import { EnrolmentRegistry } from "./EnrolmentRegistry";
import { PopUp } from "./../PopUpScreen";
export class Answer {
}
export class DigitalSkillsClass {
}
export class EnrolmentController {
    constructor(chatControl, finalCallback) {
        this.chatControl = chatControl;
        this.finalCallback = finalCallback;
        this.services = new EnrolmentServices(this);
        this.container = new EnrolmentComponent(this, 'enrolmentContainerDiv', 'ftcb__enrolment__container__div');
        this.intro = new EnrolmentIntro(this);
        this.softSkills = new EnrolmentSoftSkills(this);
        this.fieldSkills = new EnrolmentFieldSkills(this);
        this.hardSkills = new EnrolmentHardSkills(this);
        this.digitalSkills = new EnrolmentDigitalSkills(this);
        this.registry = new EnrolmentRegistry(this);
        this.error = new EnrolmentError(this);
        this.progress = new EnrolmentProgress(this);
        this.public = new EnrolmentProgress(this);
        this.icons = StaticsControl.statics;
        this.user = {
            origin: this.chatControl.originHost,
            express: 0,
            iduser: '',
            mail: '',
            password: '',
            phone: '',
            legal: false,
            username: '',
            surname: '',
            age: 0,
            captcha: '',
            country: 1,
            gender: 4,
            situation: 4,
            avatar: this.chatControl.userAvatar || '',
            softSkills: {
                answers: [],
                skills: []
            },
            fieldSkills: [],
            hardSkills: [],
            digitalSkills: {
                answers: [],
                skills: []
            }
        };
        this.enrolmentTexts = this.chatControl.dictionary.texts.registry;
        this.softSkillsQuestions = this.chatControl.dictionary.texts.enrolment_questions.soft_skills;
        this.digitalSkillsQuestions = this.chatControl.dictionary.texts.enrolment_questions.digital_skills_questions;
        this.digitalSkillsOptions = this.chatControl.dictionary.texts.enrolment_questions.digital_skills_options;
        this.fieldSkillsQuestion = this.chatControl.dictionary.texts.enrolment_questions.field_skills;
        this.fieldSkillsOptions = this.chatControl.dictionary.texts.skills.field_skills;
        this.hardSkillsQuestion = this.chatControl.dictionary.texts.enrolment_questions.hard_skills;
        this.hardSkillsOptions = new Array;
        this.start = () => {
            this.startProcess(() => { this.finalCallback('closed'); });
        };
        this.startProcess = (closingCallback) => {
            this.container.component.addEventListener("keydown", (event) => this.pressEnter(event));
            this.popUp = new PopUp('enrolmentContainer', '');
            this.popUp.component.appendChild(this.container.component);
            if (closingCallback)
                this.popUp.setExtraCloseBehaviour(closingCallback);
            this.enrolmentParent = this.popUp.component;
            this.executeNext(null);
        };
        this.noFieldEnd = () => {
            this.chatControl.sendNoFieldsEnrolment(this.user.iduser);
            this.popUp.close();
        };
        this.end = () => {
            this.stop();
        };
        this.setUserData = (callback) => {
            if (this.user.avatar)
                this.chatControl.userAvatar = this.user.avatar;
            if (this.user.iduser)
                this.chatControl.iduser = this.user.iduser;
            if (this.user.mail)
                this.chatControl.userEmail = this.user.mail;
            this.chatControl.isExpressUser = this.user.express;
            this.chatControl.menuUserAvatar();
            callback(null);
        };
        this.executionIndex = -1;
        this.executeNext = (err) => {
            if (err)
                return this.executeError();
            this.executionIndex++;
            this.functionWaterfall[this.executionIndex](this.executeNext);
        };
        this.executeError = () => {
            this.error.start();
        };
        this.pressEnter = (event) => {
            if (event.keyCode === 13) {
                $(':focus').parent().find('button').click();
            }
            else if (event.keyCode == 9) {
                event.preventDefault();
            }
        };
        this.functionWaterfall = [
            this.progress.start,
            this.intro.start,
            this.softSkills.start,
            this.services.getSoftSkills,
            this.fieldSkills.start,
            this.services.getHardSkills,
            this.hardSkills.start,
            this.registry.start,
            this.setUserData,
            this.end
        ];
    }
    scroll() {
        const msgWrapper = this.container.component;
        msgWrapper.scroll({ top: msgWrapper.scrollHeight, left: 0, behavior: 'smooth' });
        setTimeout(function () {
            msgWrapper.scroll({ top: msgWrapper.scrollHeight, left: 0, behavior: 'smooth' });
        }, 1700);
    }
    stop() {
        this.finalCallback(null);
        this.popUp.close();
    }
    noFieldsEnd(callback) {
        this.user.softSkills = null;
        this.executionIndex = -1;
        this.functionWaterfall = [
            this.progress.forcedFilling,
            this.registry.start,
            this.setUserData,
            this.noFieldEnd
        ];
        callback();
    }
}
